import React from "react";
import { Page, Button, ActionsButton } from "framework7-react";
import DeliverTo from "../deliver";
import OrderSummary from "../order-summary";
import PaymentDetails from "../payment-details";
import styles from "./styles.module.less";
import { ORDER_WA, GET_CART } from "../../../../../js/global";
import {
  UPDATE_CART,
  REMOVE_CART,
  // INFO_CART_PO,
  // REMOVE_CART_PO,
  // UPDATE_CART_PO,
  GET_TABLE,
  REMOVE_TABLE,
} from "../../../../../js/cart2";
import { API_URL, GET_TOKEN, FormatImage } from "../../../../../js/global";
import DineinTime from "../dine-time";
import { IcoArrow } from "../../../../../assets/merchant";
import moment from "moment";

const display = {
  display: "block",
};
const hide = {
  display: "none",
};
export default class index extends React.Component {
  constructor() {
    super();
    const idMerchant = GET_CART() ? GET_CART().merchant_id : "";
    this.state = {
      fullName: "",
      phone: "",
      pin_enabled: 0,
      isLoading: true,
      cart: {
        item: [],
      },
      cart_PO: {
        item: [],
      },
      pickup_time: {
        date: "",
        time: moment().format("HH:mm"),
      },
      payment_methods: [],
      banks: [],
      trial: 0,
      data: {
        note: "",
        payment_method: 1,
        before_payment: 1,
        merchant_id: idMerchant,
        table_id: 0,
        guest: 1,
        name: "",
        photo: "",
        item: [],
        item_fee: [],
        toggle: false,
      },
      tables: [],
      size: "",
      visibility: true,
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  }

  async getpaymentUrl(id) {
    const app = this.$f7;
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/get",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: id,
        },
      })
      .then((res) => {
        window.location.href = res.data.data.url.WebUrl;
      })
      .catch((err) => {
        if (this.state.trial >= 3) {
          throw err;
        }
        this.setState({
          trial: this.state.trial + 1,
        });
        this.getpaymentUrl(id);
      });
  }

  CreatepaymentUrl(id) {
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/create",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: id,
        },
      })
      .then(async (res) => {
        this.getpaymentUrl(id);
      })
      .catch((err) => {
        // preloader.hide();
        // alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  //  getValidatePreOrder(){
  //   console.log("Get Validate Pre Order Function")
  //   const cart = this.state.cart_PO;
  //   let currItem = [];
  //   for (var key in cart.item) {
  //     if (cart.item[key].qty !== 0) {
  //       currItem.push({
  //         pre_order_id: cart.item[key].pre_order_id,
  //         quantity: cart.item[key].qty,
  //         food_notes: cart.item[key].notes,
  //       });
  //     }
  //   }

  //   this.setState({
  //     ...this.state,
  //     data: {
  //       ...this.state.data,
  //       items: [...currItem],
  //       // payment_method: this.state.banks[0].id,
  //       type_transaction: "po",
  //       merchant_id: this.state.cart_PO.merchant_id,
  //       shipment_method: null,
  //       note:this.state.data.note,
  //       payment_method:this.state.data.payment_method,
  //       pickup_time:this.state.pickup_time
  //     },
  //   });

  //   const app = this.$f7;
  //   app.preloader.show();
  //   app.request
  //     .promise({
  //       url: API_URL + "/v2/take-away/order/validate",
  //       method: "POST",
  //       contentType: "application/json",
  //       dataType: "json",
  //       headers: {
  //         Authorization: GET_TOKEN(),
  //       },
  //       data: this.state.data,
  //       pickup_time: this.state.pickup_time,
  //     })
  //     .then(async (res) => {
  //       console.log("Res",res)

  //       this.setState({
  //         ...this.state,
  //         data: {
  //           ...this.state.data,
  //           item_fee: [res.data.data.fee]
  //         },
  //       });

  //       app.preloader.hide();
  //     })
  //     .catch((err) => {
  //       app.preloader.hide();
  //       app.dialog.alert(JSON.parse(err.xhr.responseText).message);
  //     });
  // }

  getValidateOrder() {
    const cart = this.state.cart;
    let currItem = [];
    for (var key in cart.item) {
      if (cart.item[key].qty !== 0) {
        currItem.push({
          product_id: cart.item[key].id,
          qty: cart.item[key].qty,
          notes: cart.item[key].notes,
          type: cart.item[key].type,
          serve_as: cart.item[key].serve_as,
        });
      }
    }
    const currItemConstruct = _.chain(currItem)
      .groupBy("type")
      .map((val, key) => ({
        type: key,
        data: val,
      }))
      .value();

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        item: [...currItemConstruct],
        // payment_method: this.state.banks[0].id,
      },
    });
    const app = this.$f7;
    this.$request
      .promise({
        url: API_URL + "/v1/dine-in/order/validate",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: this.state.data,
      })
      .then((res) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            item_fee: [res.data.data.fee],
          },
        });
      })
      .catch((err) => {
        console.log("err", err);
        // alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  getPaymetMethod() {
    this.$f7ready((f7) => {
      // f7.preloader.show();

      //Load Products API
      f7.request.promise
        .get(API_URL + "/v1/payment-method?limit=5", null, "json")
        .then(async (res) => {
          let data = res.data.data;
          if (data) this.setState({ ...this.state, payment_methods: data });
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }
  onAddMore() {
    this.props.router.navigate(
      "/merchants/" + this.state.cart.merchant_id + "/"
    );
  }
  onClickOrderNow() {
    const f7 = this;
    if (
      this.state.pin_enabled == 0 &&
      this.state.data.before_payment.key == "frodowallet"
    ) {
      f7.$f7.dialog.alert("You must activate ewallet first!", () =>
        this.props.router.navigate("/change-pin/")
      );
    } else {
      const cart = this.state.cart;
      let currItem = [];
      for (var key in cart.item) {
        if (cart.item[key].qty !== 0) {
          currItem.push({
            product_id: cart.item[key].id,
            qty: cart.item[key].qty,
            notes: cart.item[key].notes,
            type: cart.item[key].type,
            serve_as: cart.item[key].serve_as,
          });
        }
      }
      const currItemConstruct = _.chain(currItem)
        .groupBy("type")
        .map((val, key) => ({
          type: key,
          data: val,
        }))
        .value();

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          item: [...currItemConstruct],
          table_id: this.state.data.table_id,
          total_guest: this.state.data.guest,
          // payment_method: this.state.payment_methods.key,
        },
      });

      const app = this.$f7;
      // app.preloader.show();
      app.request
        .promise({
          url: API_URL + "/v1/dine-in/order",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data: this.state.data,
        })

        .then(async (res) => {
          if (this.state.data.before_payment.key == "qoin") {
            this.getpaymentUrl(res.data.data.transaction.id);
            this.props.router.navigate(
              "/transactions/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
            2;
          }

          if (this.state.data.before_payment.key == "va_bca") {
            this.props.router.navigate(
              "/bank-info/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
          }
          if (this.state.data.before_payment.key == "frodowallet") {
            this.props.router.navigate(
              "/password/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
          }
          REMOVE_CART();
          REMOVE_TABLE();
        })
        .catch((err) => {
          app.dialog.alert(JSON.parse(err.xhr.responseText).message);
        });
      // app.preloader.hide()
    }
  }

  // onClickBookNow() {
  //   const cart = this.state.cart_PO;
  //   let currItem = [];
  //   for (var key in cart.item) {
  //     if (cart.item[key].qty !== 0) {
  //       currItem.push({
  //         pre_order_id: cart.item[key].pre_order_id,
  //         quantity: cart.item[key].qty,
  //         food_notes: cart.item[key].notes,
  //       });
  //     }
  //   }

  //   this.setState({
  //     ...this.state,
  //     data: {
  //       ...this.state.data,
  //       items: [...currItem],
  //       // payment_method: this.state.payment_methods[2].id,
  //       type_transaction: "po",
  //       merchant_id: this.state.cart_PO.merchant_id,
  //       shipment_method: null,
  //       pickup_time: this.state.pickup_time,
  //     },
  //   });

  //   const app = this.$f7;
  //   app.preloader.show();
  //   app.request
  //     .promise({
  //       url: API_URL + "/v2/take-away/order",
  //       method: "POST",
  //       contentType: "application/json",
  //       dataType: "json",
  //       headers: {
  //         Authorization: GET_TOKEN(),
  //       },
  //       data: this.state.data,
  //       pickup_time: this.state.pickup_time,
  //     })
  //     .then(async(res) => {

  //       if(this.state.data.before_payment.key=="qoin")
  //        { this.getpaymentUrl(res.data.data.transaction.id )
  //         this.props.router.navigate("/transactions/"+ res.data.data.transaction_progress.transaction_id + "/");  2}
  //        if(this.state.data.before_payment.key=="va_bca"){
  //         this.props.router.navigate("/bank-info/"+ res.data.data.transaction_progress.transaction_id+"/")
  //        }
  //        if(this.state.data.before_payment.key=="frodowallet"){
  //         this.props.router.navigate("/password/"+ res.data.data.transaction_progress.transaction_id+"/")
  //        }
  //       REMOVE_CART_PO();

  //       app.preloader.hide();
  //     })
  //     .catch((err) => {
  //       app.preloader.hide();
  //       // app.dialog.alert(JSON.parse(err.xhr.responseText).message);
  //     });
  // }
  getTable() {
    const app = this.$f7;
    this.$request
      .promise({
        url:
          API_URL +
          "/v1/table/available?merchant_id=" +
          this.state.data.merchant_id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",

        data: this.state.data,
      })
      .then((res) => {
        this.setState({
          ...this.state,
          tables: res.data.data,
        });
      });
  }
  componentDidMount() {
    if (GET_CART()) {
      this.setState({ ...this.state, cart: GET_CART() });
    }
    this.$f7ready((f7) => {
      // f7.preloader.show();

      //Load Products API
      f7.request.promise
        .get(
          API_URL +
            "/v1/merchant/" +
            this.state.data.merchant_id +
            "?include=bank_informations",
          null,
          "json"
        )

        .then(async (res) => {
          this.$f7ready((f7) => {
            // f7.preloader.show();

            if (!GET_TOKEN()) {
              this.$f7router.navigate("/onboarding/");
            }

            //Load Merchants API
            f7.request
              .promise({
                url: API_URL + "/v1/profile",
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  Authorization: GET_TOKEN(),
                },
              })
              .then((res) => {
                this.setState({
                  ...this.state,
                  fullName: res.data.data.full_name,
                  phone: res.data.data.phone,
                  pin_enabled: res.data.data.pin_enabled,
                  isLoading: false,
                });
              })
              .catch((err) => {
                console.log("err", err);
                f7.preloader.hide();
              });
          });
          if (this.props.router.url == "/checkout/dinein/") {
            let cart_PO;
            // await INFO_CART_PO()
            //   .then((res) => (cart_PO = res))
            //   .catch((err) => console.log("cart_err", err));
            // this.setState({ ...this.state, cart_PO: cart_PO });
            this.state.cart_PO.quantity == 0
              ? await this.getValidateOrder()
              : await this.getValidateOrder();
            this.getPaymetMethod();
            let table;
            await GET_TABLE()
              .then((res) => (table = res))
              .catch((err) => console.log("err", err));
            if (table !== null) {
              this.setState({
                ...this.state,
                data: {
                  ...this.state.data,
                  table_id: parseInt(table),
                },
              });
            }

            this.getTable();
            let data = res.data.data;
            if (data)
              this.setState({
                ...this.state,
                name: res.data.data.name,
                photo: res.data.data.photo,
              });
            this.tableVisibility();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }
  tableVisibility() {
    if (this.state.data.table_id == NaN || this.state.data.table_id === 0) {
      this.setState({ ...this.state, visibility: true });
    } else {
      this.setState({ ...this.state, visibility: false });
    }
  }
  onCartUpdate(item, value, notes) {
    if (this.state.cart_PO.quantity != 0) {
      // UPDATE_CART_PO(item, value, notes)
      //   .then((res) => {
      //     this.setState({ ...this.state, cart_PO: res });
      //   })
      //   .catch((err) => console.log("err", err));
    } else {
      UPDATE_CART(item, value, notes)
        .then((res) => {
          this.setState({ ...this.state, cart: res });
        })
        .catch((err) => console.log("err", err));
    }
  }

  AddMethod(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        before_payment: val,
        payment_method: val.id,
      },
    });

    this.toggle();
  }

  onChangeNote(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        note: val,
      },
    });
  }
  onChangeTable(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        table_id: val,
      },
    });
  }

  onChangeTime(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state,
        pickup_time: { ...val },
      },
    });
  }

  onChangeNotes(item, notes) {
    UPDATE_CART(item, notes).then((res) => {
      this.setState({ ...this.state, cart: res });
    });
  }

  render() {
    var modal = [];
    modal.push(
      <div className="modal" style={this.state.toggle ? display : hide}>
        <div className={styles["payment-items"]}>
          {this.state.payment_methods.map((item, index) => {
            return (
              // <div style={{ display: "flex", marginLeft: "20px" }}>
              <ActionsButton
                className={styles["input-btn"]}
                onClick={() => this.AddMethod(item)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img className={styles["input-photo"]} src={item.photo}></img>
                  {item.value_string}
                </div>
              </ActionsButton>
            );
          })}
        </div>
      </div>
    );
    return (
      <Page>
        {this.state.tables.length == 0 ? (
          <div>
            <div style={{ marginTop: "80px" }} />
            {modal}
            <div className={styles["txt-closed"]}>
              Maaf, tidak ada meja yang tersedia
            </div>
            <div style={{ marginTop: "80px" }} />
          </div>
        ) : (
          <div>
            <div style={{ marginTop: "80px" }} />
            {modal}
            <div style={{ display: "flex", margin: "20px" }}>
              <img
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "5px",
                  backgroundColor: "gray",
                }}
                src={FormatImage(this.state.photo, "thumb")}
              />
              <div
                style={{
                  marginLeft: "20px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={styles["txt-restaurant-location"]}>
                  Pickup location
                </div>
                <div className={styles["txt-restaurant-name"]}>
                  {this.state.name}
                </div>
                <div className={styles["txt-restaurant-details"]}></div>
              </div>
            </div>
            <div className="mk-line-break medium" />
            <OrderSummary
              data={this.state.cart ? this.state.cart : []}
              preOrder={this.state.cart_PO ? this.state.cart_PO : []}
              onAddMore={() => this.onAddMore()}
              onUpdateCart={(item, val, notes) =>
                this.onCartUpdate(item, val, notes)
              }
              onChangeNotes={(item, notes) => this.onChangeNotes(item, notes)}
            />
            <div className="mk-line-break medium" />
            <DineinTime
              app={this.$f7}
              data={this.state ? this.state : []}
              onChangeTable={(val) => this.onChangeTable(val)}
            />
            <div className="mk-line-break medium" />
            <PaymentDetails data={this.state ? this.state : []} />
            <div className={styles["button-wrap"]}>
              <div>
                <Button
                  className={styles["method-btn"]}
                  onClick={(event) => this.toggle(event)}
                >
                  {this.state.data.payment_method == 1
                    ? "Choose Your Payment Method"
                    : this.state.data.before_payment.value_string}
                </Button>
                <div className={styles["notes"]}>
                  {this.state.data.payment_method == 1
                    ? "please choose your payment method first"
                    : ""}
                </div>
              </div>
              <img className={styles["img-ico"]} src={IcoArrow} />
            </div>
            <div style={{ marginTop: "30px" }} />
            <div className={styles["container-btn-buy"]}>
              {this.state.data.payment_method == 1 ? (
                ""
              ) : (
                <Button
                  onClick={() =>
                    this.state.cart_PO.quantity == 0
                      ? this.onClickOrderNow()
                      : this.onClickOrderNow()
                  }
                  className={styles["btn-order"]}
                  text={this.state.cart_PO.quantity != 0 ? "PAY" : "PAY"}
                />
              )}
            </div>
            <div style={{ marginTop: "80px" }} />
          </div>
        )}
      </Page>
    );
  }
}
