import React from "react";
import styles from "./styles.module.less";
import { API_URL, FormatImage } from "../../../../../js/global";

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      // Load Foods API
      f7.request.promise
        .get(
          API_URL +
            "/v1/product?page=1&limit=10&coordinates=&s=&m=&sort_by=&category=16",
          null,
          "json"
        )
        .then((res) => {
          const data = res.data.data;
          this.setState({ data: data, isLoading: false });
          this.props.onLoaded(true);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.onLoaded(false);
        });
    });
  }

  render() {
    return (
      <div style={this.state.data.length == 0 ? hide : display}>
        <div className={styles["container-title-section"]}>
          <div className={styles["txt-title-section-1"]}>Ready To Cook</div>
          <a href="/ready-to-cook/" className={styles["txt-more-section"]}>
            See all
          </a>
        </div>

        <div className={styles["mk-horizontal-scroll"]}>
          {this.state.data.map((item, index) => {
            return (
              <div
                style={{
                  display: item.stock != 0 ? "block" : "none",
                }}
              >
                <a href={"/foods/" + item.type + "/" + item.id + "/"}>
                  <div className={styles["container"]}>
                    <div className={styles["img-product"]}>
                      <img
                        className={styles["img-product"]}
                        src={FormatImage(item.photo, "thumb")}
                      />
                      <div className={styles["txt-merchant-name"]}>
                        {item.merchant}
                      </div>
                    </div>
                    <div className={styles["container-detail"]}>
                      <div className={styles["txt-product-name"]}>
                        {item.name}
                      </div>
                      <div width className={styles["txt-product-price"]}>
                        {item.price_before == 0 || item.price_before == null ? (
                          <div width className={styles["txt-product-price"]}>
                            {Intl.NumberFormat().format(item.price)}
                          </div>
                        ) : (
                          <div>
                            <div
                              width
                              className={styles["txt-product-price-promo"]}
                            >
                              {Intl.NumberFormat().format(item.price_before)}
                            </div>
                            <div width className={styles["txt-product-price"]}>
                              {Intl.NumberFormat().format(item.price)}{" "}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
