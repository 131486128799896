import React, { useState } from "react";
import { IcoMinusOval, IcoPlusOval, IcoPencil } from "../../../assets/home";
import styles from "./styles.module.less";

export default function index(props) {
  const [value, setValue] = useState(
    props.defaultValue ? props.defaultValue : 0
  );
  const onClickIncreaseValue = () => {
    if (props.disable) {
      return;
    }
    var currVal = value + 1;
    setValue(currVal);
    props.onChange(currVal);
  };

  const onClickDecreaseValue = () => {
    if (props.disable) {
      return;
    }
    var currVal = value - 1;
    if (currVal < 0) return;
    setValue(currVal);
    props.onChange(currVal);
  };

  const style = props.style ? props.style : {};

  return (
    <div style={{ display: "flex", ...style }}>
      <a onClick={() => onClickDecreaseValue()}>
        <img src={IcoMinusOval} />
      </a>
      <div className={styles["txt-qty-1"]}>{value}</div>
      <a onClick={() => onClickIncreaseValue()}>
        <img className="mk-icon-base" src={IcoPlusOval} />
      </a>
    </div>
  );
}
