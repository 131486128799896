import React from "react";
import { IcoFrodoWelcome, IcoMamaKitchen } from "../../assets/onboarding";
import { Page, Row, Col, Button } from "framework7-react";
import styles from "./styles.module.less";

export default () => {
  return (
    <Page name="onboarding" className={styles["onboarding"]}>
      <div style={{ margin: "30px 25px 0px 22px" }}>
        <Row>
          <Col width="70">
            <img style={{ height: "60px" }} src={IcoMamaKitchen} />
          </Col>
          <Col width="30">
            <div style={{ display: "none" }} className={styles["oval"]}>
              <div className={styles["txt_en"]}>EN</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles["txt_tip"]}>
        Unauthorized Access This Page. Please Try Again.
      </div>
      {/* <img className={styles["img_background"]} src={IcoFrodoWelcome} /> */}
      {/* <Button href="/home/" style={{ margin: "0px 20px 0px 20px" }}>
        SKIP
      </Button> */}
    </Page>
  );
};
