import React from "react";
import { Button, Searchbar, Swiper, SwiperSlide,Navbar,NavLeft,NavRight,NavTitle,Link} from "framework7-react";
import { API_URL } from "../../../../../js/global";

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      data: [],
      search: "",
    };
  }

  componentDidMount() {   
    this.$f7ready((f7) => {
      // Load Banners API
      f7.request.promise
        .get(API_URL + "/v1/banner?limit=20", null, "json")
        .then((res) => {
          const data = res.data.data;
          this.setState({ ...this.state, data: data, isLoading: false });
          
        })
          .catch((err) => {
            console.log("err", err);
            // this.props.onLoaded(false);
          });
    });
  }

  async onSubmitSearch() {
    this.props.router.navigate(
      "/search/" + "near_by/" + this.state.search + "/"
    );
  }

  render() {
    return (
      <div>

      <div
        style={{
          height: 330,
          position: "relative",
          display: "flex",
        }}
      >
        <Swiper
          pagination
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 0,
          }}
        >
          {this.state.data.map((item, index) => (
            <SwiperSlide>
              <img style={{ objectFit: "fill",width:"100%" }} src={item.image_url} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
   
      </div>
    );
  }
}
