import { API_URL } from "./global";

export const INDEX_CART = (merchantId) =>
  new Promise((result, error) => {
    fetch(API_URL + "/v1/product?limit=100&m=" + merchantId, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res.data;
        // Validate Cart
        let cart = JSON.parse(window.localStorage.getItem("user_cart"));

        
        if (cart) {
          if (cart.merchant_id != merchantId || cart.is_preorder === true) {
            REMOVE_CART();
            let newCart = {
              merchant_id: merchantId,
              total: 0,
              quantity: 0,
              item: [],
            };
            for (var key in data) {
              newCart.item.push({ ...data[key], qty: 0,notes: ''});
            }
            cart = newCart;
          }
        } else {
          let newCart = {
            merchant_id: merchantId,
            total: 0,
            quantity: 0,
            item: [],
          };
          for (var key in data) {
            newCart.item.push({ ...data[key], qty: 0,notes: ''});
          }
          cart = newCart;
        }

        var saveData = JSON.stringify(cart);
        window.localStorage.setItem("user_cart", saveData);
        result(cart);
      })
      .catch((err) => error(err));
  });

export const SHOW_CART = (product) =>
  new Promise((result, error) => {
    INDEX_CART(product.merchant_id).then((res) => {
      try {
        let qty = 0;
        let price = 0;
        let cart = res;
        let item = {};
        let itemId = product.id;

        if (cart) {
          for (var key in cart.item) {
            let currItem = cart.item[key];
            if (currItem.id === itemId) {
              item = currItem;
              qty = currItem.qty;
              price = currItem.price * currItem.qty;
            }
          }
        }
        const data = {
          item: item,
          qty: qty,
          price: price,
        };

        result(data);
      } catch (err) {
        error(err);
      }
    });
  });

export const UPDATE_CART = (product, value, notes) =>
  new Promise((result, error) => {
    try {
      let cart = JSON.parse(window.localStorage.getItem("user_cart"));

      const itemId = product.id;
      let total = 0;
      let quantity = 0;
      for (var key in cart.item) {
        let currItem = cart.item[key];
        console.log("currItem", itemId);
        if (currItem.id === itemId) {
          currItem.notes= notes ?? currItem.notes;
          currItem.serve_as= serve_as ?? currItem.serve_as;
          currItem.qty = value;
          
        }
        total += currItem.qty * currItem.price;
        quantity += currItem.qty;
      }

      cart.total = total;
      cart.quantity = quantity;
      cart.notes = notes;

      var saveData = JSON.stringify(cart);
      window.localStorage.setItem("user_cart", saveData);

      result(cart);
    } catch (err) {
      error(err);
    }
  });

export const REMOVE_CART = () => {
  window.localStorage.removeItem("user_cart");
};

export const GET_CART = () => {
  const cart = window.localStorage.getItem("user_cart");
  if (cart) {
    JSON.parse(cart);
  }
};

export const SET_CART = (cart) => {
  const cartJSON = JSON.stringify(cart);
  window.localStorage.setItem("user_cart", cartJSON);
};
