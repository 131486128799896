import React from "react";
import { Button, Page, Navbar, Searchbar,Block } from "framework7-react";
import CategoryList from "./components/category-list";
import styles from "./styles.module.less";
import { API_URL, GET_USER_POSITION } from "../../../js/global";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: this.props.s,
      sortByNearest: 0,
      sortByRating: 0,
      sortByPrice: 0,
      sortBystartdate: 0,
      sortBydeliverydate: 0,
      item: [],
      merchant_id: 0,
      quantity: 0,
      total: 0,
      current_page: 1,
      data_length: 0,
      total_pages: 0,
      limit: 10,
      has_more: false,
      isLoading: false,
      title: " ",
    };
    this.fetchData = this.fetchData.bind(this);
  }

  onClickByNearest() {
    if (this.props.sort_by == "near_by") {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/ready-stock/",
        {
          animate: false,
        }
      );
    } else {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 1,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/ready-stock/" +
          "near_by/" +
          s +
          "/",
        {
          animate: false,
        }
      );
    }
  }
  onClickByPrice() {
    if (this.props.sort_by == "cheapest") {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/ready-stock/",
        {
          animate: false,
        }
      );
    } else {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 1,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/ready-stock/" +
          "cheapest/" +
          s +
          "/",
        {
          animate: false,
        }
      );
    }
  }

  onClickByRating() {
    if (this.props.sort_by == "rating") {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/ready-stock/",
        {
          animate: false,
        }
      );
    } else {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 1,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/ready-stock/" +
          "rating/" +
          s +
          "/",
        {
          animate: false,
        }
      );
    }
  }

  onClickstartdate() {
    if (this.props.sort_by == "start_date") {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/pre-order/",
        {
          animate: false,
        }
      );
    } else {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 1,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/pre-order/" +
          "start_date/" +
          s +
          "/",
        {
          animate: false,
        }
      );
    }
  }

  onClickdeliverydate() {
    if (this.props.sort_by == "delivery_date") {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 0,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/pre-order/",
        {
          animate: false,
        }
      );
    } else {
      const s = this.props.s ? this.props.s : "";
      this.setState({
        ...this.state,
        sortByNearest: 0,
        sortByPrice: 0,
        sortByRating: 0,
        sortBydeliverydate: 1,
        sortBystartdate: 0,
      });

      this.$f7router.navigate(
        "/categories/" +
          this.props.id +
          "/" +
          this.props.category +
          "/pre-order/" +
          "delivery_date/" +
          s +
          "/",
        {
          animate: false,
        }
      );
    }
  }
  onClickReadyStock() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 0,
      sortByPrice: 0,
      sortByRating: 0,
      sortBydeliverydate: 0,
      sortBystartdate: 0,
    });
    this.$f7router.navigate(
      "/categories/" +
        this.props.id +
        "/" +
        this.props.category +
        "/ready-stock/",
      {
        animate: false,
      }
    );
  }

  onClickPreOrder() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 0,
      sortByPrice: 0,
      sortByRating: 0,
      sortBydeliverydate: 0,
      sortBystartdate: 0,
    });
    this.$f7router.navigate(
      "/categories/" +
        this.props.id +
        "/" +
        this.props.category +
        "/pre-order/",
      {
        animate: false,
      }
    );
  }

  componentDidMount() {
    if (this.props.status == "ready-stock") {
      const categoryId = this.props.id;
      const sortBy = this.props.sort_by ? this.props.sort_by : "";
      const s = this.props.s ? this.props.s : "";

      switch (sortBy) {
        case "near_by":
          this.setState({ ...this.state, sortByNearest: 1 });
          break;
        case "rating":
          this.setState({ ...this.state, sortByRating: 1 });
          break;
        case "cheapest":
          this.setState({ ...this.state, sortByPrice: 1 });
          break;
        case "start_date":
          this.setState({ ...this.state, sortBystartdate: 1 });
          break;
        case "delivery_date":
          this.setState({ ...this.state, sortBydeliverydate: 1 });
          break;
      }

      this.$f7ready((f7) => {
        f7.preloader.show();
        let position;
        if (GET_USER_POSITION()) {
          position =
            GET_USER_POSITION().latitude + "," + GET_USER_POSITION().longitude;
        }

        //Load Merchants API
        f7.request.promise
          .get(
            API_URL +
              "/v1/product?limit=" +
              this.state.limit +
              "&" +
              "&page=" +
              this.state.current_page +
              "&" +
              (position ? `coordinates=${position}&` : "") +
              "s=" +
              s +
              "&sort_by=" +
              sortBy +
              "&category=" +
              categoryId,
            null,
            "json"
          )
          .then((res) => {
            this.setState({
              ...this.state,
              data: res.data.data,
              total_pages: res.data.paginator.total_pages,
              has_more:
                this.state.current_page < res.data.paginator.total_pages
                  ? true
                  : false,
              data_length: res.data.paginator.total_items,
              category: this.props.id,
              title: res.data.data[0].food_category[0].value_string,
            });
            console.log("props", this.props);
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
      });
    }
    if (this.props.status == "pre-order") {
      const categoryId = this.props.id;
      const sortBy = this.props.sort_by ? this.props.sort_by : "";
      const s = this.props.s ? this.props.s : "";

      switch (sortBy) {
        case "near_by":
          this.setState({ ...this.state, sortByNearest: 1 });
          break;
        case "rating":
          this.setState({ ...this.state, sortByRating: 1 });
          break;
        case "cheapest":
          this.setState({ ...this.state, sortByPrice: 1 });
          break;
        case "start_date":
          this.setState({ ...this.state, sortBystartdate: 1 });
          break;
        case "delivery_date":
          this.setState({ ...this.state, sortBydeliverydate: 1 });
          break;
      }

      this.$f7ready((f7) => {
        f7.preloader.show();
        let position;
        if (GET_USER_POSITION()) {
          position =
            GET_USER_POSITION().latitude + "," + GET_USER_POSITION().longitude;
        }

        //Load Merchants API
        f7.request.promise
          .get(
            API_URL +
              "/v1/pre-order/product?limit=" +
              this.state.limit +
              "&" +
              "&page=" +
              this.state.current_page +
              "&" +
              (position ? `coordinates=${position}&` : "") +
              "s=" +
              s +
              "&sort_by=" +
              sortBy +
              "&category=" +
              categoryId,
            null,
            "json"
          )
          .then((res) => {
            this.setState({
              ...this.state,
              data: res.data.data,
              total_pages: res.data.paginator.total_pages,
              has_more:
                this.state.current_page < res.data.paginator.total_pages
                  ? true
                  : false,
              data_length: res.data.paginator.total_items,
              category: this.props.id,
            });
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
      });
    }
  }

  onSubmitSearch() {
    const sort = this.props.sort_by ? this.props.sort_by : " ";
    this.$f7router.navigate(
      "/categories/" +
        this.props.id +
        "/" +
        this.props.category +
        "/" +
        this.props.status +
        "/" +
        sort +
        "/" +
        this.state.search +
        "/",
      {
        animate: false,
      }
    );
  }

  fetchMoreData() {
    const page = this.state.current_page + 1;

    if (this.state.has_more) {
      this.fetchData(page);
      this.setState({ current_page: page });
    }
    this.setState({
      has_more: this.state.current_page < this.state.total_pages,
    });
  }

  fetchData(page) {
    if (this.props.status == "ready-stock") {
      this.setState({ isLoading: true });
      const sortBy = this.props.sort_by ? this.props.sort_by : "";
      const s = this.props.s ? this.props.s : "";
      const categoryId = this.props.id;
      switch (sortBy) {
        case "near_by":
          this.setState({ ...this.state, sortByNearest: 1 });
          break;
        case "rating":
          this.setState({ ...this.state, sortByRating: 1 });
          break;
        case "cheapest":
          this.setState({ ...this.state, sortByPrice: 1 });
          break;
        case "start_date":
          this.setState({ ...this.state, sortBystartdate: 1 });
          break;
        case "delivery_date":
          this.setState({ ...this.state, sortBydeliverydate: 1 });
          break;
      }

      let position;
      if (GET_USER_POSITION()) {
        position =
          GET_USER_POSITION().latitude + "," + GET_USER_POSITION().longitude;
      }

      this.$f7ready((f7) => {
        // f7.preloader.show();

        // Load Search API
        f7.request.promise
          .get(
            API_URL +
              "/v1/product?limit=" +
              this.state.limit +
              "&" +
              "&page=" +
              page +
              "&" +
              (position ? `coordinates=${position}&` : "") +
              "s=" +
              s +
              "&sort_by=" +
              sortBy +
              "&category=" +
              categoryId,
            null,
            "json"
          )
          .then((res) => {
            this.setState({
              ...this.state,
              has_more: page < res.data.paginator.total_pages ? true : false,
              data: this.state.data.concat(res.data.data),
              isLoading: false,
            });
          })
          .catch((err) => {
            console.log("err", err);
            this.setState({ has_more: false, isLoading: false });
          });
      });
    }
    if (this.props.status == "pre-order") {
      this.setState({ isLoading: true });
      const sortBy = this.props.sort_by ? this.props.sort_by : "";
      const s = this.props.s ? this.props.s : "";
      const categoryId = this.props.id;
      switch (sortBy) {
        case "near_by":
          this.setState({ ...this.state, sortByNearest: 1 });
          break;
        case "rating":
          this.setState({ ...this.state, sortByRating: 1 });
          break;
        case "cheapest":
          this.setState({ ...this.state, sortByPrice: 1 });
          break;
        case "start_date":
          this.setState({ ...this.state, sortBystartdate: 1 });
          break;
        case "delivery_date":
          this.setState({ ...this.state, sortBydeliverydate: 1 });
          break;
      }

      let position;
      if (GET_USER_POSITION()) {
        position =
          GET_USER_POSITION().latitude + "," + GET_USER_POSITION().longitude;
      }

      this.$f7ready((f7) => {
        // f7.preloader.show();

        // Load Search API
        f7.request.promise
          .get(
            API_URL +
              "/v1/pre-order/product?limit=" +
              this.state.limit +
              "&" +
              "&page=" +
              page +
              "&" +
              (position ? `coordinates=${position}&` : "") +
              "s=" +
              s +
              "&sort_by=" +
              sortBy +
              "&category=" +
              categoryId,
            null,
            "json"
          )
          .then((res) => {
            this.setState({
              ...this.state,
              has_more: page < res.data.paginator.total_pages ? true : false,
              data: this.state.data.concat(res.data.data),
              isLoading: false,
            });
          })
          .catch((err) => {
            console.log("err", err);
            this.setState({ has_more: false, isLoading: false });
          });
      });
    }
  }

  render() {
    return (
      <Page
        name="search-page"
        infinite
        infiniteDistance={100}
        infinitePreloader={this.state.isLoading}
        onInfinite={() => this.fetchMoreData()}
      >
        <Navbar
          // large
          backLink
          // backLinkForce
          title={this.props.category != " " ? this.props.category : " "}
        />
        {/* <Navbar
          backLink
          backLinkUrl="/home/"
          title={this.state.data.title}
          title={
            this.state.title != " "
              ? this.state.title
              : " "
          }
        /> */}
        <Block>
          <Searchbar
          style={{backgroundColor:"white",height:"40px"}}
            value={this.state.search}
            onChange={(val) =>
              this.setState({ ...this.state, search: val.target.value })
            }
            onSubmit={() => this.onSubmitSearch()}
          />
        </Block>
        <div>
          {/* <div className="mk-line-break"></div>
          <div className={styles["container-header"]}></div> */}
          {/* <div className={styles["btn-wrapper"]}>
            <Button
              onClick={() => this.onClickReadyStock()}
              className={
                this.props.status == "pre-order"
                  ? styles["tab-btn"]
                  : styles["tab-active"]
              }
            >
              Ready Stock
            </Button>
            <Button
              onClick={() => this.onClickPreOrder()}
              className={
                this.props.status == "pre-order"
                  ? styles["tab-active"]
                  : styles["tab-btn"]
              }
            >
              Pre Order
            </Button>
          </div> */}
          {this.state.data_length == 0 ? (
            <div>
              <div className={styles["container-header"]}>
                <div className={styles["btn-notfound"]}>
                  <div style={{ marginLeft: "2px" }}>
                    Mohon Maaf Item Tidak Tersedia Saat ini
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* {this.props.status !== "ready-stock" ? (
                <div className={styles["container-header"]}>
                  <Button
                    iconMd="material:place"
                    iconSize="medium"
                    className={
                      this.state.sortBystartdate == 1
                        ? styles["btn-preorder-active"]
                        : styles["btn-preorder-inactive"]
                    }
                    fill={this.state.sortBystartdate == 1}
                    outline={this.state.sortBystartdate == 0}
                    onClick={() => this.onClickstartdate()}
                  >
                    <div style={{ marginLeft: "2px" }}>Start Date</div>
                  </Button>
                  <Button
                    iconMd="material:star"
                    iconSize="medium"
                    className={
                      this.state.sortBydeliverydate == 1
                        ? styles["btn-preorder-active"]
                        : styles["btn-preorder-inactive"]
                    }
                    fill={this.state.sortBydeliverydate == 1}
                    outline={this.state.sortBydeliverydate == 0}
                    onClick={() => this.onClickdeliverydate()}
                  >
                    <div style={{ marginLeft: "2px" }}>Delivery Date</div>
                  </Button>
                </div>
              ) : (
                <div className={styles["container-header"]}>
                  <Button
                    iconMd="material:place"
                    iconSize="medium"
                    className={
                      this.state.sortByNearest == 1
                        ? styles["btn-category-active"]
                        : styles["btn-category-inactive"]
                    }
                    fill={this.state.sortByNearest == 1}
                    outline={this.state.sortByNearest == 0}
                    onClick={() => this.onClickByNearest()}
                  >
                    <div style={{ marginLeft: "2px" }}>By Nearest Place</div>
                  </Button>
                  <Button
                    iconMd="material:star"
                    iconSize="medium"
                    className={
                      this.state.sortByRating == 1
                        ? styles["btn-category-active"]
                        : styles["btn-category-inactive"]
                    }
                    fill={this.state.sortByRating == 1}
                    outline={this.state.sortByRating == 0}
                    onClick={() => this.onClickByRating()}
                  >
                    <div style={{ marginLeft: "2px" }}>By Rating</div>
                  </Button>
                  <Button
                    iconMd="material:text_format"
                    iconSize="medium"
                    className={
                      this.state.sortByPrice == 1
                        ? styles["btn-category-active"]
                        : styles["btn-category-inactive"]
                    }
                    fill={this.state.sortByPrice == 1}
                    outline={this.state.sortByPrice == 0}
                    onClick={() => this.onClickByPrice()}
                  >
                    <div style={{ marginLeft: "2px" }}>Cheapest</div>
                  </Button>
                </div>
              )} */}
            </div>
          )}
        </div>
        <CategoryList
          page={this.state}
          data={this.state.data}
          props={this.props}
          fetchMoreData={() => this.fetchMoreData()}
        />
      </Page>
    );
  }
}
