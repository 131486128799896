import React from "react";
import { Button, Page, Navbar, Block } from "framework7-react";
import CategoryList from "./components/category-list";
import styles from "./styles.module.less";
import { API_URL } from "../../../js/global";

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      data: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      // Load Categories API
      f7.request.promise
        .get(API_URL + "/v1/master-data?limit=100"+"&type=food_category", null, "json")
        .then((res) => {
          const data = res.data.data;
          this.setState({ data: data, isLoading: false });
          this.props.onLoaded(true);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.onLoaded(false);
        });
    });
  }

  render() {
    return (
      <Page
        style={{ visibility: this.state.isLoading ? "hidden" :"visible" }}
        pageContent
        name="category"
      >
        <Navbar
          // large
          backLink
          // backLinkForce
          title="Category"
        />
        <Block>
            <CategoryList data={this.state.data} />
        </Block>
      </Page>
    );
  }
}
