
import React from "react";
import { ImgBackground } from "../../../assets/auth";
import { IcoProfile } from "../../../assets/profile";
import { Button, List, Input, Page, Icon } from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN } from "../../../js/global";

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_PIN: Array(6).fill(""),
      new_PIN : Array(6).fill(""),
      c_PIN: Array(6).fill(""),
      old_pin: "",
      new_pin : "",
      pin: "",
      c_pin: "",
      fullName: "",
      phone: "",
      photo: "",
      pin_enabled: 0,
      isLoading: true,
      status_code:null,
      data: {
        balance:0,
        full_name:"",
      },
    };

    this.old_inputRefs = Array(6).fill(React.createRef())
    this.new_inputRefs = Array(6).fill(React.createRef())
    this.c_inputRefs = Array(6).fill(React.createRef())
  }

  onChangeOldPIN  (value, index) {
    const oldPIN = this.state.old_PIN;
    oldPIN[index] = value;
    this.setState({old_PIN:oldPIN});
    if(index < oldPIN.length - 1 && value) {
      this.old_inputRefs[index + 1].focus();
    }
    console.log("pin", this.state);
    };

  onChangeNewPIN  (value, index) {
    const newPIN = this.state.new_PIN;
    newPIN[index] = value;
    this.setState({new_PIN:newPIN});
    if(index < newPIN.length - 1 && value) {
      this.new_inputRefs[index + 1].focus();
    }
    console.log("pin", this.state);
    };

    onChangeCPIN  (value, index) {
      const cPIN = this.state.c_PIN;
      cPIN[index] = value;
      this.setState({c_PIN:cPIN});
      if(index < cPIN.length - 1 && value) {
        this.c_inputRefs[index + 1].focus();
      }
      console.log("pin", this.state);
      };

      onPreviousOLD (key, index) {
        const oldPIN = this.state.old_PIN;
        console.log(key);
        if(key === 8 && index !== 0 && !oldPIN[index])  {
          this.old_inputRefs[index - 1].focus()
        }
      };

  onPreviousNEW (key, index) {
    const newPIN = this.state.new_PIN;
    console.log(key);
    if(key === 8 && index !== 0 && !newPIN[index])  {
      this.new_inputRefs[index - 1].focus()
    }
  };

  onPreviousC (key, index) {
    const cPIN = this.state.c_PIN;
    console.log(key);
    if(key === 8 && index !== 0 && !cPIN[index])  {
      this.c_inputRefs[index - 1].focus()
    }
  };


  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }

      //Load Merchants API
      f7.request
        .promise({
          url: API_URL + "/v1/profile",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          console.log("res coba", res.data.data);
          this.setState({
            ...this.state,
            fullName: res.data.data.full_name,
            phone: res.data.data.phone,
            photo: res.data.data.photo + "?t=" + new Date().getTime(),
            pin_enabled: res.data.data.pin_enabled,
            isLoading: false,
          });
          console.log("thisss", this.state);
          f7.preloader.hide();
        })
        .catch((err) => {
          // console.log("err", err);
          f7.preloader.hide();
        });
    });
  }


  onClickNewPIN() {

    const PIN = this.state.new_PIN;
    const pin = PIN.join("");
    this.setState({pin:pin});
    console.log("this.state", this.state);

    const cPIN = this.state.c_PIN;
    const c_pin = cPIN.join("");
    this.setState({c_pin:c_pin});
    console.log("this.state", this.state);




    const data = this.state;
    const app = this;
    app.$f7router.app.preloader.show();
    app.$request
      .promise({
        url: API_URL + "/v1/pin/create",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: { ...data },
      })
      .then((res) => {
        console.log("res", res);
        this.onCLickActivate();
        app.$f7router.app.preloader.hide();
        app.$f7.dialog.alert("PIN has been create!", () =>
          app.$f7router.navigate("/home/")
        );
      })
      .catch((err) => {
        // console.log("err", err);
        app.$f7router.app.preloader.hide();
        app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }


  onClickChangePIN() {
    const oldPIN = this.state.old_PIN;
    const old_pin = oldPIN.join("");
    this.setState({old_pin:old_pin});
    console.log("this.state", this.state);
/////use when change pin

    const newPIN = this.state.new_PIN;
    const new_pin = newPIN.join("");
    this.setState({new_pin:new_pin});

    const cPIN = this.state.c_PIN;
    const c_pin = cPIN.join("");
    this.setState({c_pin:c_pin});



    const data = this.state;
    const app = this;
    app.$f7router.app.preloader.show();
    app.$request
      .promise({
        url: API_URL + "/v1/pin/update",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: { ...data },
      })
      .then((res) => {
        console.log("res", res);
        app.$f7router.app.preloader.hide();
        app.$f7.dialog.alert("PIN updated!", () =>
          app.$f7router.navigate("/home/")
        );
      })
      .catch((err) => {
        // console.log("err", err);
        app.$f7router.app.preloader.hide();
        app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }


  GetAsset(){
    this.$f7ready(async (f7) => {
     f7.preloader.show();
     await f7.request
     .promise({
       url: API_URL + "/v1/asset",
       method: "GET",
       contentType: "application/json",
       dataType: "json",
       headers: {
         Authorization: GET_TOKEN(),
       },
     })
       .then(async (res) => {
         let data = res.data.data;
         this.setState({
           ...this.state,
           data: data,
           status_code:res.data.data.status_code,
           balance:res.data.data.balance,
           full_name:res.data.data.full_name,

         });
         console.log("asset", this.state);
         f7.preloader.hide();
         
       })
       .catch((err) => {
         console.log("err", err);
         f7.preloader.hide();
       });
   });
 }

 onCLickActivate(){
  this.$f7ready(async (f7) => {
    f7.preloader.show();
    await f7.request
    .promise({
      url: API_URL + "/v1/asset/activate",
      method: "GET",
      contentType: "application/json",
      dataType: "json",
      headers: {
        Authorization: GET_TOKEN(),
      },
    })  
      .then(async (res) => {
        console.log("actv",res);
        let data = res.data.data;
        this.setState({
          ...this.state,
          data: data,
          status_code:200
        });
        this.GetAsset()
        f7.preloader.hide();
        
      })
      .catch((err) => {
        console.log("err", err);
        f7.preloader.hide();
      });
  });
}


  render() {
    return (
      <Page name="login">
        <div className={styles["container"]}>
          <img className={styles["img-background"]} src={ImgBackground} />
          <Button
            back
            iconMd="material:arrow_back_ios"
            className={styles["btn-back"]}
          />
          <div>
            <div className={styles["oval"]}>
              <Icon
                size="70px"
                md="material:vpn_key"
                className={styles["ico"]}
              />
            </div>
            </div>
            <div className={styles["input-form"]}>
            {this.state.pin_enabled==0 ?<div className={styles["Change-PIN"]}>Create your ewallet PIN
            </div> : <div className={styles["Change-PIN"]}>Change PIN</div> }


            <div style={this.state.pin_enabled==0 ? hide : display}>
                <div className={styles["Confirm-your-new-PIN"]}>Input your old PIN</div>
              
                <div  className={styles["pin"]}>  
                {this.old_inputRefs.map((k, idx) => {
                    return (
                        <input className={styles["Input-PIN"]}     
                  style={{
            margin: "0px 4px 0px 4px",
            borderWidth: "0px 0px 3px 0px",
            borderStyle: "solid",
            textAlign: "center",
        }}  
                  type="password"
                  maxLength="1"
                  onChange={(e) => this.onChangeOldPIN(e.target.value, idx)}
                  ref={(r) => this.old_inputRefs[idx] = r}
                  onKeyDown={e => this.onPreviousOLD(e.keyCode, idx)}
                  
                ></input>
                    )
                })}
                </div>
                </div>
                <div className={styles["Confirm-your-new-PIN"]}>Input your new PIN</div>
              
                <div  className={styles["pin"]}>  
                {this.new_inputRefs.map((k, idx) => {
                    return (
                        <input className={styles["Input-PIN"]}     
                  style={{
            margin: "0px 4px 0px 4px",
            borderWidth: "0px 0px 3px 0px",
            borderStyle: "solid",
            textAlign: "center",
        }}  
                  type="password"
                  maxLength="1"
                  onChange={(e) => this.onChangeNewPIN(e.target.value, idx)}
                  ref={(r) => this.new_inputRefs[idx] = r}
                  onKeyDown={e => this.onPreviousNEW(e.keyCode, idx)}
                  
                ></input>
                    )
                })}
                </div>
                <div className={styles["Confirm-your-new-PIN"]}>Confirm your PIN</div>
                <div  className={styles["pin"]}>
                {this.c_inputRefs.map((k, idx) => {
                    return (
                        <input className={styles["Input-PIN"]}     
                  style={{
            margin: "0px 4px 0px 4px",
            borderWidth: "0px 0px 3px 0px",
            borderStyle: "solid",
            textAlign: "center",
        }}  
                  type="password"
                  maxLength="1"
                  onChange={(e) => this.onChangeCPIN(e.target.value, idx)}
                  ref={(r) => this.c_inputRefs[idx] = r}
                  onKeyDown={e => this.onPreviousC(e.keyCode, idx)}
                  
                ></input>
                    )
                })}
                </div>
            </div>
          <div className={styles["mk-footer"]}>
            <Button
              // /href="/client/"
              onClick={() =>
                this.state.pin_enabled==0 ? this.onClickNewPIN() : this.onClickChangePIN()}
              fill
              className={styles["btn-payment"]}
            >
              Change PIN
            </Button>
          </div>
          </div>
      </Page>
    );
  }
}
