import { WinPhoneView } from "react-device-detect";

export const COMING_SOON = false;
export const UNDER_MAINTENANCE = false;
export const API_URL = "https://apiclientsrv-shop.qoinpay.id"; //"https://apiclientsrv-shop.qoinpay.id"; 
export const CRYPTO_REDIRECT_URL = API_URL.includes('qoinpay.id')?"https://landing.qoinpay.id/backtoapps":"https://dev-landing.qoincrypto.id/backtoapps"
export const QOINPAY_ID = API_URL.includes('qoinpay.id')?164:164;
export const GOOGLE_API_KEY = "AIzaSyAsjlt67715gCmWurm0N9pko7Ja2O3SSqc";

export const SET_TOKEN = (token) => {
  window.localStorage.setItem("user_token", "bearer " + token);
};

export const GET_TOKEN = () => {
  return window.localStorage.getItem("user_token");
};

export const SET_TEMP_USER_DATA = (userData) => {
  const data = JSON.stringify(userData);
  window.sessionStorage.setItem("temp_user_data", data);
};

export const GET_TEMP_USER_DATA = () => {
  const data = window.sessionStorage.getItem("temp_user_data");
  if (!data) return false;
  return JSON.parse(data);
};

export const SIGN_OUT = () => {
  window.localStorage.clear();
};

export const UPDATE_CART = (data) => {
  var saveData = JSON.stringify(data);
  window.localStorage.setItem("user_cart", saveData);
};

export const REMOVE_CART = (cart) => {
  window.localStorage.removeItem("user_cart");
};

export const GET_CART = () => {
  return JSON.parse(window.localStorage.getItem("user_cart"));
};

export const CLEAR_CART = () => {
  window.localStorage.removeItem("user_cart");
};

export const ORDER_WA = (data) => {
  const foods = data.item;
  let foodsString = "New%20Order!%0A";
  let totalPrice = data.total;
  foods.forEach((item) => {
    if (item.qty != 0) foodsString += item.name + "%20x" + item.qty + "%0A";
  });

  foodsString += "Total:%20" + "Rp" + Intl.NumberFormat().format(totalPrice);
  return (
    "https://api.whatsapp.com/send?phone=6287778988998&text=" + foodsString
  );
};

export const FormatImage = (imageUrl,type) => {
    // var imgRegex = new RegExp("(https?:\/\/.*.\/image\/.*.\/product\/.*\.(?:png|jpg))");
    // if(imgRegex.test(imageUrl)){
    //   var urlBlock = imageUrl.split(".");
    //   return imageUrl.replace(urlBlock[urlBlock.length-2],urlBlock[urlBlock.length-2]+(type=="thumb"?"-1x":"-2x"));
    // }
    // else {
      return imageUrl;
    // }
  }

export function ArrayRemove(array, value) {
  return array.filter(function (element) {
    return element !== value;
  });
}


export const ChangePhoneAreaCodeEwallet = (phoneNumber) => {
  let _phoneNumber = phoneNumber.toString();
  const is62 = _phoneNumber[0] == "0";
  if (is62) {
    _phoneNumber = ReplaceStringAt(_phoneNumber, 0, "x");
    _phoneNumber = _phoneNumber.replace("x", "62");
  }
  return _phoneNumber;
  
};

export const ChangePhoneAreaCode = (phoneNumber) => {
  let _phoneNumber = phoneNumber.toString();
  const is62 = _phoneNumber[0] + _phoneNumber[1] === "62";
  if (is62) {
    _phoneNumber = ReplaceStringAt(_phoneNumber, 0, "x");
    _phoneNumber = ReplaceStringAt(_phoneNumber, 1, "y");
    _phoneNumber = _phoneNumber.replace("xy", "0");
  }
  return _phoneNumber;
};

export function ReplaceStringAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}

export const SetRedirectToCheckOut = (value) => {
  window.localStorage.setItem("redirect_to_checkout", value);
};

export const GetRedirectToCheckout = () => {
  return window.localStorage.getItem("redirect_to_checkout");
};

export const SET_USER_ADDRESS = (address) => {
  const addressJSON = JSON.stringify(address);
  window.localStorage.setItem("user_address", addressJSON);
};

export const GET_USER_ADDRESS = () => {
  const addressJSON = window.localStorage.getItem("user_address");
  let addressObject = null;
  if (addressJSON) addressObject = JSON.parse(addressJSON);
  return addressObject;
};

export const GET_USER_POSITION = () => {
  const positionJSON = window.localStorage.getItem("user_position");
  let positionObject = null;
  if (positionJSON) positionObject = JSON.parse(positionJSON);
  return positionObject;
};

export const SET_USER_POSITION = (position) => {
  const positionJSON = JSON.stringify(position);
  window.localStorage.setItem("user_position", positionJSON);
};

export const SET_USER_PROFILE=(user)=>{
  const userJSON =JSON.stringify(user);
  window.localStorage.setItem("user_profile",userJSON);
}

export const GET_USER_PROFILE = () => {
  const userJSON = window.localStorage.getItem("user_profile");
  let userObject = null;
  if (userJSON) userObject = JSON.parse(userJSON);
  return userObject;
};
export const SET_READYTODRINK_STATUS=(readytodrink)=>{
  const readytodrinkJSON =JSON.stringify(readytodrink);
  window.localStorage.setItem("readytodrink",readytodrinkJSON);
}

export const GET_READYTODRINK_STATUS = () => {
  const readytodrinkJSON = window.localStorage.getItem("readytodrink");
  let readytodrinkObject = null;
  if (readytodrinkJSON) readytodrinkObject = JSON.parse(readytodrinkJSON);
  return readytodrinkObject;
};
export const SET_MERCHANT_PROFILE=(merchant_profile)=>{
  const merchant_profileJSON =JSON.stringify(merchant_profile);
  window.localStorage.setItem("merchant_profile",merchant_profileJSON);
}

export const GET_MERCHANT_PROFILE = () => {
  const merchant_profileJSON = window.localStorage.getItem("merchant_profile");
  let merchant_profileObject = null;
  if (merchant_profileJSON) merchant_profileObject = JSON.parse(merchant_profileJSON);
  return merchant_profileObject;
};