import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  f7,
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
  Icon,
  List,
  Input,
  ListItem,
} from "framework7-react";
import styles from "./styles.module.less";
import GoogleSearchLocation from "../../../../../components/customs/google-search-location";
import {
  GET_USER_ADDRESS,
  SET_USER_ADDRESS,
  API_URL,
  GET_TOKEN,
  FormatImage,
} from "../../../../../js/global";
import { IcoArrow, IcoVoucher } from "../../../../../assets/merchant";
import moment from "moment";
import _ from "lodash";
import { func } from "prop-types";

export default (props) => {
  const [geoLocation, setGeoLocation] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const googleSearchRef = useRef(null);

  let data = props.data.data.Myvoucher;
  let voucher_codes = "";
  let merchant_id = 64

  // if(props.data.data.addresses.length != 0){
  // }
  useEffect(() => {
    setTimeout(() => {
      // getLocation();
      // loadvoucher();
    }, 100);
  }, []);

  function loadvoucher() {
    f7.request
      .promise({
        url: API_URL + "/v1/coupon/mine?m="+merchant_id+"",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
      .then((res) => {
        const data = res.data.data;
        console.log("voc", data);
        f7.preloader.hide();
      })
      .catch((err) => {
        // console.log("err", err);
        f7.preloader.hide();
      });
  }

  function redeem() {
    console.log("code", props.data.data.coupon_codes);
    const code = props.data.data.coupon_code;
    f7.request
      .promise({
        url: API_URL + "/v1/coupon/redeem",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          code: code,
        },
      })
      .then((res) => {
        const data = res.data.data;
        console.log("redeem", data);
        f7.preloader.hide();
      })
      .catch((err) => {
        // console.log("err", err);
        f7.preloader.hide();
      });
  }

  function onUpdateVoucher(voucher) {
    // props.onChangeVoucher(voucher);
    setTimeout(() => {
      googleSearchRef.current.close();
    }, 100);
  }
  function ApplyVoucher() {
    const a = props.data.methodFood;
    const b = props.data.methodDelivery;
    const c = [a, b];
    const d = c.toString();
    const e = d.replace(/^,/, "");
    voucher_codes = e.replace(/,$/, "");
    props.onClickApplyVoucher(voucher_codes);
    console.log("apply", voucher_codes);
    googleSearchRef.current.close();
  }

  const categoryGroup = _.groupBy(data[0], (o) => o.voucher_category.key);
  const deliveryGroup = categoryGroup.delivery;
  const foodGroup = categoryGroup.food;
  console.log("deliv", deliveryGroup);
  console.log("food", foodGroup);

  return (
    <div>
      <div
        onClick={() => googleSearchRef.current.open()}
        style={{ cursor: "pointer" }}
      >
        <Button
          className={styles["button-shipping-list"]}
          // onClick={(event) => this.toggleDelivery(event)}
        >
          <div className={styles["voucher-button-wrap"]}>
            <img className={styles["img-ico-voucher"]} src={IcoVoucher} />
            <div className={styles["voucher-btn"]}>Frodo Voucher </div>
          </div>
          <div className={styles["voucher-button-wrap"]}>
            <div className={styles["voucher-btn-2"]}>Use my voucher</div>
            <img className={styles["img-ico-arrow"]} src={IcoArrow} />
          </div>
        </Button>
      </div>

      <Actions ref={googleSearchRef}>
        <ActionsGroup
          style={{
            height: "500px",
            backgroundColor: "white",
            borderRadius: "0px 0px 0px 0px",
            display: "flex",
            overflowY: "scroll",
          }}
        >
          <div style={{ width: "100%", margin: "20px" }}>
            <div className={styles["rectangle"]} />
            <div className={styles["header-address"]}>
              <div className={styles["txt-deliver-to"]}>My Voucher</div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div className={styles["wrap-redeem"]}>
                <input
                  className={styles["input-redeem"]}
                  onChange={(val) => props.onChangeVoucher(val.target.value)}
                />
              </div>
              <Button className={styles["button-add"]} onClick={() => redeem()}>
                Redeem
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => onUpdateLocation({ details: geoLocation })}
            ></div>
            {deliveryGroup !== undefined ? (
              <div className={styles["txt-menu-name"]}>DELIVERY</div>
            ) : (
              ""
            )}
            {deliveryGroup !== undefined &&
              deliveryGroup.map((voucher) => {
                return (
                  <div
                    className={styles["address-wrapper"]}
                    onClick={
                      props.voucherDeliverySelected === voucher.coupon_code
                        ? () => props.handleChangeVoucherDelivery("")
                        : () =>
                            props.handleChangeVoucherDelivery(
                              voucher.coupon_code
                            )
                    }
                  >
                    <div className={styles["icon-background"]}>
                      <img
                        className={styles["img-menu-product"]}
                        src={FormatImage(
                          voucher.voucher_category.photo,
                          "thumb"
                        )}
                      />
                    </div>
                    <div className={styles["container-detail"]}>
                      <div className={styles["txt-menu-name"]}>
                        {voucher.name}
                      </div>
                      {voucher.end_period == null ? (
                        <div className={styles["txt-menu-date"]} />
                      ) : (
                        <div className={styles["txt-menu-date"]}>
                          Available{" "}
                          {moment(voucher.start_period).format("DD MMMM YYYY")}{" "}
                          s/d{" "}
                          {moment(voucher.end_period).format("DD MMMM YYYY")}
                        </div>
                      )}
                    </div>
                    <div className={styles["radio-btn"]}>
                      <input
                        checked={
                          props.voucherDeliverySelected === voucher.coupon_code
                        }
                        type="radio"
                      ></input>
                    </div>
                  </div>
                );
              })}
            {foodGroup !== undefined ? (
              <div className={styles["txt-menu-name"]}>FOOD</div>
            ) : (
              ""
            )}
            {foodGroup !== undefined &&
              foodGroup.map((voucher) => {
                return (
                  <div
                    className={styles["address-wrapper"]}
                    onClick={
                      props.voucherFoodSelected === voucher.coupon_code
                        ? () => props.handleChangeVoucherFood("")
                        : () =>
                            props.handleChangeVoucherFood(
                              voucher.coupon_code
                            )
                    }
                  >
                    <div className={styles["icon-background"]}>
                      <img
                        className={styles["img-menu-product"]}
                        src={FormatImage(
                          voucher.voucher_category.photo,
                          "thumb"
                        )}
                      />
                    </div>
                    <div className={styles["container-detail"]}>
                      <div className={styles["txt-menu-name"]}>
                        {voucher.name}
                      </div>
                      {voucher.end_period == null ? (
                        <div className={styles["txt-menu-date"]} />
                      ) : (
                        <div className={styles["txt-menu-date"]}>
                          Available{" "}
                          {moment(voucher.start_period).format("DD MMMM YYYY")}{" "}
                          s/d{" "}
                          {moment(voucher.end_period).format("DD MMMM YYYY")}
                        </div>
                      )}
                    </div>
                    <div className={styles["radio-btn"]}>
                      <input
                        checked={
                          props.voucherFoodSelected === voucher.coupon_code
                        }
                        type="radio"
                      ></input>
                    </div>
                  </div>
                );
              })}
            <div style={{ paddingBottom: "5px" }}>
              {/* {deliveryGroup == undefined || foodGroup == undefined ? (
                " "
              ) : ( */}
              <Button
                className={styles["btn-apply"]}
                onClick={() => ApplyVoucher()}
              >
                Apply Voucher
              </Button>
              {/* )} */}
            </div>
          </div>
        </ActionsGroup>
      </Actions>
    </div>
  );
};
