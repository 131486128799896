import React, { useState, useEffect, useRef } from "react";
import { GOOGLE_API_KEY, SET_USER_POSITION } from "../../../js/global";
import styles from "./styles.module.less";
let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, props) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      types: ["geocode"],
      componentRestrictions: { country: "id" },
    }
  );
  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "geometry",
  ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, props)
  );
}

async function handlePlaceSelect(updateQuery, props) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  console.log(addressObject);

  const address = {
    country: "",
    province: "",
    city: "",
    subdistrict: "",
    postal_code: null,
    details: addressObject.formatted_address,
    latitude: addressObject.geometry.location.lat(),
    longitude: addressObject.geometry.location.lng(),
  };

  for (var address_el of addressObject.address_components) {
    // Get Country
    if (address_el.types[0] === "country")
      address.country = address_el.long_name;
    // Get Province
    if (address_el.types[0] === "administrative_area_level_1")
      address.province = address_el.long_name;
    // Get City
    if (address_el.types[0] === "administrative_area_level_2")
      address.city = address_el.long_name;
    // Get Subdistrict
    if (address_el.types[0] === "administrative_area_level_3")
      address.subdistrict = address_el.long_name;
  }

  console.log("address", address);

  SET_USER_POSITION({
    latitude: address.latitude,
    longitude: address.longitude,
  });


  props.onPlaceSelect(address);
}

export default function index(props) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, props)
    );
  }, []);

  return (
    <div style={props.style}>
      <input
        className={styles["google-search"]}
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search Location"
        value={query}
      />
    </div>
  );
}
