import React from "react";
import { Page, Button, Input, List, f7 } from "framework7-react";
import { IcoLove, IcoPlusOval, IcoMinusOval } from "../../../assets/home";
import { API_URL,GET_TOKEN,FormatImage } from "../../../js/global";
import { IncreaseDecrease, ButtonCart, Share} from "../../../components/customs";
import styles from "./styles.module.less";
import { REMOVE_CART, INFO_CART } from "../../../js/cart2";
import { IcoWaiter } from "../../../assets/merchant";
import moment from "moment";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
 
export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      toggle: false,
      cart: {
        item: [],
      },
      data: {
        item: [],
        merchant_id: 0,
        quantity: 0,
        total: 0,
        notes:'',
      },
      cartItem:{
        item:[]
      },
      disable:false,
    };
  }

  onClickShare() {
    navigator
      .share({
        title: "Frodo",
        text:
          "Check out Frodo! " +
          this.state.data.item.name +
          " at " +
          this.state.data.item.merchant,
        url: window.location.href,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }
  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));
  }

  onChangeNotes(val) {
    const item = this.state.data.item;
    const value = this.state.data.quantity;
    const stateData = {
      ...this.state,
      data: {
        item: item,
        quantity: value,
        notes:val,
        total: value * item.price ,
      },
    };
    this.setState(stateData);
    UPDATE_CART_PO(stateData.data.item, value, val);
    
  }

  onChangeQty(value) {
    const item = this.state.data.item;
    const val = this.state.data.notes;
    const cart = this.state.cart.item

    const stateData = {
      ...this.state,
      data: {
        item:this.state.data.item,
        quantity: value,
        total: value * item.price ,
      },
    };
    this.setState(stateData)
    UPDATE_CART_PO(this.state.data.item, value, val);
    
   
  }

  onClickAddToCart() {
    
    if (this.state.data.quantity !== 0) {
        if (this.state.data.quantity >= this.state.data.item.limit)
      {
        this.$f7.dialog.alert("Product Quantity Has Reached limit stock");
      }
      else {
        // UPDATE_CART_PO(this.state.data.item, this.state.data.qty, this.state.data.notes);
          this.$f7router.navigate(
        "/merchants/" +this.props.merchantId+"/");
      }
    } 
    else {
    if (this.state.data.quantity == 0) {
      this.$f7.dialog.alert("Please add an item.");
    } else {
      // UPDATE_CART_PO(this.state.data.item, this.state.data.qty,this.state.data.notes);
      this.$f7router.navigate("/checkout" + "/");
    }
  }
  }
  componentDidMount() {
    const foodId = this.props.preorderId;
    const merchantId = this.props.merchantId;
    if (GET_CART()) {
      this.setState({ ...this.state, cart: GET_CART() });
    }
    this.$f7ready((f7) => {

      //Load Products API
      f7.request
        .promise({
          url: API_URL + "/v1/pre-order/product/" + merchantId + "/" + foodId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then(async (res) => {
          let item = res.data.data;
          let items;
          let data;
          let cart_ready;
        
          // await INDEX_CART_PO(merchantId)
          // .then((res) => (data = res))
          // .catch((err) => console.log("cart_err", err));
          

          // await SHOW_CART_PO(item)
          // .then((res) => (items = res))
          // .catch((err) => console.log("cart_err", err));
          

          await INFO_CART()
          .then((res) => (cart_ready = res))
          .catch((err) => console.log("cart_err", err));
          
          item["qty"] = 0;
          item["id"] = item.pre_order_id;
          const cart = {
            item: items.item,
            quantity: items.qty,
            notes:items.notes,
            total: items.price,
            merchant_id: merchantId,
            is_preorder: true,
            merchant_name: item.merchant_name,
          };
          
          this.setState({
            ...this.state,
            cartItem:cart_ready,
            cart:data,
            data: cart,
            is_wishlist: item.is_wishlist,
          });
         
          let cartItem=this.state.cart.item;
          let dataItem=this.state.data.item;
          let cart_item=this.state.cartItem;
          // cartItem.map(v => {
          //   if(v.qty!==0){
          //     if(moment(v.pre_order_end_date).format('LL') !== moment(dataItem.pre_order_end_date).format('LL')){
          //       this.setState({
          //         ...this.state,
          //         disable:true,
          //       });
          //       f7.dialog.alert("you can't order pre order product with different last order date.");
          //     }
          //   }
          // })
          if(cart_item.quantity!==0){
            this.setState({
              ...this.state,
              disable:true,
            });
            f7.dialog.alert("Please finish your another transaction first.");
          }
          
          // SET_CART_PO(cart);
         
          f7.preloader.hide();
          
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }



  render() {
    var modal = [];
    modal.push(
      <div className="modal" style={this.state.toggle ? display : hide}>
      <Share/>
      </div>
    );
    return (
      <Page>
        
          <>
            <div className={styles["header"]}>
              <img
                className={styles["header-img"]}
                src={FormatImage(this.state.data.item.photo,"detail")}
              />
              <Button
                href={"/merchants/" + this.props.merchantId + "/"}
                iconMd="material:arrow_back_ios"
                className={styles["header-btn"]}
              />
              <div style={{ flex: 1 }} />
              <BrowserView>
              <Button
                iconMd="material:share" 
                className={styles["share-button"]}
                onClick={(event) => this.toggle(event)}>
                  {modal}
              </Button>
            </BrowserView>
            <MobileView>
              <Button
                onClick={() => this.onClickShare()}
                iconMd="material:share"
                className={styles["header-btn"]}
              />
            </MobileView>
            </div>
            <div style={{ margin: "15px 20px 15px 20px" }}>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  height: "fit-content",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  padding:"10px",
                  
                }}
              >
                <div style={{ margin: "0px 15px 0px 15px" }}>
                  <img src={IcoWaiter} height="24px" width="24px" />
                </div>
                <div style={{
                  display: "grid"}}>                
                  <div className={styles["txt-available-food"]} style={{marginBottom: "10px"}}>
                    This food will delivered on{" "}
                    {moment(this.state.data.item.pre_order_delivery_date)
                      .format("DD MMMM YYYY")}
                    <br />
                    (Last order:{" "}
                    {moment(this.state.data.item.pre_order_end_date).format(
                      "DD MMMM YYYY"
                    )}
                    )
                  </div>
                  {/* <div className={styles["txt-available-food"]}> Hanya dapat memesan product pre-order dengan tgl last order yang sama per transaksi</div>
                  <div className={styles["txt-available-food"]}> Apabila Stock Preorder habis maka pembayaran akan di Refund</div> */}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "fit-content",
                margin: "0px 20px 0px 20px",
              }}
            >
              <div className={styles["food-col-1"]}>
                <div className={styles["txt-food-name"]}>
                  {this.state.data.item.name}
                </div>
                <div className={styles["txt-food-detail"]}>
                  {this.state.data.item.description}
                </div>
                <div style={{
                display: "flex",
                justifyContent:"space-between",
                width:"100%"
                  }}>
                  <div className={styles["txt-food-price"]}>
                    Rp. {this.state.data.item.price}
                  </div>
                  <IncreaseDecrease
                    defaultValue={this.state.data.quantity}
                    onChange={(value) => this.onChangeQty(value)}
                    disable={this.state.disable}
                  />
                 
                </div>
              </div>
              <div className={styles["food-col-2"]}>
                
              </div>
            </div>
            <div className="mk-line-break medium"></div>
            <List style={{ margin: "20px 20px 100px 20px" }}>
              <div className={styles["txt-label"]}>Note</div>
              <Input type="text" placeholder="Type your note here"
                
                defaultValue={this.state.data.item.notes}
                onChange={(value) => this.onChangeNotes(value.target.value)}>
              </Input>
            </List>
            <div className="mk-footer">
              <ButtonCart
                quantity={this.state.data.quantity}
                price={this.state.data.total}
                notes={this.state.data.notes}
                text="Book Now"
                onClick={() => this.onClickAddToCart()}
              />
            </div>
          </>
        
      </Page>
    );
  }
}
