import React from "react";
import { ImgBackground } from "../../../assets/auth";
import { IcoProfile } from "../../../assets/profile";
import { Button, List, Input, Page, Icon } from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN } from "../../../js/global";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      new_password: "",
      c_password: "",
    };
  }

  onClickChangePassword() {
    const data = this.state;
    const app = this;
    app.$f7router.app.preloader.show();
    app.$request
      .promise({
        url: API_URL + "/v1/profile/change-password",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: { ...data },
      })
      .then((res) => {
        console.log("res", res);
        app.$f7router.app.preloader.hide();
        app.$f7.dialog.alert("Password updated!", () =>
          app.$f7router.navigate("/home/")
        );
      })
      .catch((err) => {
        // console.log("err", err);
        app.$f7router.app.preloader.hide();
        // app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  render() {
    return (
      <Page name="login">
        <div className={styles["container"]}>
          <img className={styles["img-background"]} src={ImgBackground} />
          <Button
            back
            iconMd="material:arrow_back_ios"
            className={styles["btn-back"]}
          />
          <div>
            <div className={styles["oval"]}>
              <Icon
                size="70px"
                md="material:vpn_key"
                className={styles["ico"]}
              />
            </div>
            <div className={styles["input-form"]}>
              <List>
                <div className={styles["txt-label"]}>Old password</div>
                <Input
                  type="password"
                  placeholder="Input your old password"
                  value={this.state.old_password}
                  required
                  validate
                  validateOnBlur
                  onChange={(val) =>
                    this.setState({
                      ...this.state,
                      old_password: val.target.value,
                    })
                  }
                ></Input>
                <div
                  className={styles["txt-label"]}
                  style={{ marginTop: "25px" }}
                >
                  New Password
                </div>
                <Input
                  type="password"
                  placeholder="Input your new password"
                  value={this.state.phone}
                  required
                  validate
                  validateOnBlur
                  onChange={(val) =>
                    this.setState({
                      ...this.state,
                      new_password: val.target.value,
                    })
                  }
                ></Input>
                <div
                  className={styles["txt-label"]}
                  style={{ marginTop: "25px" }}
                >
                  Confirm New Password
                </div>
                <Input
                  type="password"
                  placeholder="Confirm your password"
                  value={this.state.email}
                  required
                  validate
                  validateOnBlur
                  onChange={(val) =>
                    this.setState({
                      ...this.state,
                      c_password: val.target.value,
                    })
                  }
                ></Input>
              </List>
            </div>
          </div>
          <div className={styles["mk-footer"]}>
            <Button
              // /href="/client/"
              onClick={() => this.onClickChangePassword()}
              fill
              className={styles["btn-next"]}
            >
              Save Password
            </Button>
          </div>
        </div>
      </Page>
    );
  }
}
