import React from "react";
import { Button } from "framework7-react";
import { IcoCart } from "../../../assets/merchant";
import styles from "./styles.module.less";

export default function index(props) {
  return (
    <Button
      fill
      onClick={() => props.onClick()}
      className={styles["btn-container"]}
    >
      <div className={styles["btn-col-1"]}>
        <div className={styles["txt-btn-inner-1"]}>
          {props.quantity ? props.quantity : 0} item
        </div>
        <div className={styles["txt-btn-inner-2"]}>
          {Intl.NumberFormat().format(props.price ? props.price : 0)}
        </div>
      </div>
      <div className={styles["btn-col-2"]}>
        <div className={styles["txt-btn-inner-3"]}>
          {props.text ? props.text : ""}
        </div>
        <img src={IcoCart} />
      </div>
    </Button>
  );
}
