import React from "react";
import { Page, Navbar } from "framework7-react";
import { SET_TABLE } from "../../../js/cart2";
// import QrReader from 'react-qr-reader';
import { API_URL, GET_TOKEN, FormatImage } from "../../../js/global";

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      search: "",
      loadedComponent: 0,
      wallet:true,
      result: "No result",
    };
        this.handleScan = this.handleScan.bind(this);

  }

  componentDidMount() {  
    this.setState({
      ...this.state
    });
  }

  getTable(id){
    const tableid = id;
      const app = this.$f7;
      app.preloader.show();
        app.request
        .promise({
          url:API_URL + "/v1/table/"+tableid,
          method:"GET",
          contentType: "application/json",
          dataType:"json",
          
        })
        .then(async(res) => {
          console.log("res", res);
          SET_TABLE(tableid);
          this.$f7router.navigate("/merchants/"+ res.data.data.merchant_id+"/ready-to-eat/");
          window.location.reload();
          app.preloader.hide();
        })
        .catch((error) =>{
          app.preloader.hide();
        });
    
  }
  


  handleScan(data){
    console.log("scanner", data)
    if(data !== null){
      if(data){
        this.setState({
          ...this.state,
          result: data
        });
      }
      const id = this.state.result.slice(-2);
      console.log("id", id); 
      this.getTable(id);
    
    }
    
1  }
  handleError (err){
    console.error(err)
  }

  render() {
    return (
    <Page name="category">
        <Navbar large backLink="back" title="Qr Scanner" />
        {/* <QrReader
          delay={1000}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}
        /> */}
        
        </Page>
    );
  }
}
