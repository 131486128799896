import React from "react";
import styles from "./styles.module.less";
import { API_URL, FormatImage } from "../../../../../js/global";
const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      // Load Categories API
      f7.request.promise
        .get(API_URL + "/v1/master-data?type=food_category", null, "json")
        .then((res) => {
          const data = res.data.data;
          this.setState({ data: data, isLoading: false });
          this.props.onLoaded(true);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.onLoaded(false);
        });
    });
  }

  render() {
    return (
      <div style={this.state.data.length == 0 ? hide : display}>
        <div className={styles["container-title-section"]}>
          <div className={styles["txt-title-section-1"]}>Category</div>
          <a href="/categories/" className={styles["txt-more-section"]}>
            See all
          </a>
        </div>
        <div  className="mk-horizontal-scroll">
        {this.state.data.map((item, index) => {
          return (
            <a
              href={
                "/categories/" +
                item.id +
                "/" +
                item.value_string +
                "/ready-stock/"
              }
            >
              <div className={styles["container"]}>
                <img
                  className={styles["img-category"]}
                  src={FormatImage(item.photo, "thumb")}
                />
                <div className={styles["txt-category-name"]}>
                  {item.value_string}
                </div>
              </div>
            </a>
          );
        })}
      </div>
      </div>
    );
  }
}
