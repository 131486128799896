import React from "react";
import { IcoAbout } from "../../../assets/profile";
import { ImgBackground, IcoLogin } from "../../../assets/auth";
import { Button, List, Input, Page, Icon } from "framework7-react";
import styles from "./styles.module.less";

export default class extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Page name="login">
        <div className={styles["container"]}>
          <img className={styles["img-background"]} src={ImgBackground} />
          <Button
            back
            iconMd="material:arrow_back_ios"
            className={styles["btn-back"]}
          />
          <div style={{ flex: 1 }}>
            <div className={styles["oval"]}>
              <Icon
                md="material:people"
                size="50px"
                className={styles["ico"]}
              />
            </div>
            <div className={styles["txt-title"]}>About Us</div>
            <div className={styles["input-form"]}>
            Frodo adalah Layanan Platform yang menjembatani antara UMKM Kuliner
dengan para merchant diberbagai kota di Indonesia, dengan memberikan persediaan makanan – makanan ready to cook yang sudah di package dengan higienis dan tahan untuk waktu yang cukup lama.
            </div>

            <div className={styles["input-form"]}>•Biaya Antar Yang Lebih Terjangkau
Outlet kami disetiap kota memudahkan customer menemukan produk anda dan tidak khawatir akan biaya ongkir yang mahal.
            </div>

            <div className={styles["input-form"]}>•Maksimalkan Potensi Bisnis Anda
Perluas potensi produk Anda ke Customer di kota-kota yang tidak terjangkau oleh anda sebelumnya. Dan tentunya memaksimalkan kualitas produk anda dengan packaging yang bersih dan rapih.
            </div>

            <div className={styles["input-form"]}>•Akses ke Pelanggan
Melalui Marketing Channel kami, Anda tidak perlu khawatir akan cost marketing campaign dan content marketing campaign
            </div>

            <div className={styles["input-form"]}>•Promosi Melalui Aplikasi
Tampilkan menu-menu terbaik Anda melalui promosi pada layanan Aplikasi FRODO agar customer mudah menemukan produk anda
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
