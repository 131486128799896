import React, { useState, useEffect, useRef,useLayoutEffect } from "react";
import styles from "./styles.module.less";
import {
  Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  f7,
  Input,
  List,
  Icon
} from "framework7-react";
import { API_URL, GET_TOKEN } from "../../../../../js/global";
import GoogleSearchLocation from "../../../../../components/customs/google-search-location";

export default (props) => {
  const [geoLocation, setGeoLocation] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [mainAddress, setMainAddress] = useState("");
 

  const actionRef = useRef(null);




 
if(props.data){
useEffect(() => {
  const data = props.data.id
  console.log("pllis",data)
    loadAddresses();
  }, []);  
}

  function loadAddresses() {
    const prodId = props.data.id
     f7.request
       .promise({
         url: API_URL + "/v2/profile/address/"+prodId,
         method: "GET",
         contentType: "application/json",
         dataType: "json",
         headers: {
           Authorization: GET_TOKEN(),
         },
       })
       .then((res) => {
        
         const data = res.data.data;
         setAddresses(data);
         for (var key in data) {
           if (data[key].default === props.data.id) onChangeMainAddress(data[key].address);
         }
         f7.preloader.hide();
       })
       .catch((err) => {
         // console.log("err", err);
         f7.preloader.hide();
       });
   }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      f7.dialog.alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    setGeoLocation(
      "Latitude: " +
        position.coords.latitude +
        " Longitude: " +
        position.coords.longitude
    );
  }

  function onUpdateAddress(address){
    onChangeMainAddress(address.details)
    props.onChangeAddress(address);
  }

  function onChangeMainAddress(address) {
    setMainAddress(address);
    // props.onChangeAddress(address);
  }

  return (
    <div className={styles["container"]} onClick={() => actionRef.current.open()}>
      <div className={styles["row-1"]}>
        <div className={styles["col-2"]}>
          <div className={styles["txt-deliver"]}>Address</div>
          <div className={styles["location-wrapper"]}>
            <div className={styles["txt-location"]}>
            {mainAddress ? mainAddress : "Please Choose Your Address"}
            </div>
            <div style={{ alignSelf: "center", flexShrink: 0 }}>
            <Icon md="material:location_on" color="black" />
            </div>
          </div>
        </div>
      </div>
      <Actions ref={actionRef}>
        <ActionsGroup style={{
            height: "279px",
            backgroundColor: "white",
            borderRadius: "50px 50px 0px 0px",
            display: "flex",
          }}
        >
          <div style={{ width: "100%", margin: "15px 30px 0px 30px" }}>
            <div className={styles["rectangle"]} />
            <div className={styles["txt-deliver-to"]}>Choose Your Address</div>
          <GoogleSearchLocation
              style={{ marginTop: "20px" }}
              onPlaceSelect={(address) => onUpdateAddress(address)}
            />
          </div>
        </ActionsGroup>
      </Actions>
    </div>
  );
};
