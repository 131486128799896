import React from "react";
import styles from "./styles.module.less";
import { Icon } from "framework7-react";
import moment from "moment";
// import TimePicker from "rc-time-picker";
// import 'rc-time-picker/assets/index.css';
import styled from 'styled-components';
import PropTypes from "prop-types";

export default (props) => {
  const app = props.app;
  const data= props.data;
  const PO_item=props.data.cart_PO.item;
  let pickup =0;

  const StyledTimePicker = styled(TimePicker)`
  .rc-time-picker-panel {
    z-index: 6000 !important;
  }
`;
  var today = new Date();
  if(data.cart_PO.quantity!==0){
    for (var key in PO_item) {
      if (PO_item[key].qty !==0 ) {
        pickup = moment(PO_item[key].pre_order_end_date).add(4, "days").format("DD-MM-YYYY") 
      }
    }
  }
  else{
    pickup =moment(today).format("DD-MM-YYYY");
  }
  data.pickup_time.date = props.data.cart_PO.quantity !== 0 ? pickup : moment(today).format("DD-MM-YYYY")
  
  const handleChangeTime = (value) => {
    data.pickup_time.time = value
    props.onPickupTime(data)
  }

  const handleValueChange = value => {
    data.pickup_time.time = value.format('HH:mm')
    props.onPickupTime(data)
  };
  const getDisabledHours = () => {
    var hours = [];
    for(var i =0; i < moment().hour(); i++){
        hours.push(i);
    }
    return hours;
}

const getDisabledMinutes = (selectedHour) => {
    var minutes= [];
    if (selectedHour === moment().hour()){
        for(var i =0; i < moment().minute(); i++){
            minutes.push(i);
        }
    }
    return minutes;
}

const DeliTimePicker = ({ className, ...others }) => {
  return (
    <TimePicker
       {...others}
       showSecond={false}
       disabledHours={getDisabledHours}
       disabledMinutes={getDisabledMinutes} 
       className={"rc-time-picker-panel"}
       popupClassName={"rc-time-picker-panel"}
    />
  );
};

DeliTimePicker.propTypes = {
  className: PropTypes.string.isRequired,
};


  return (
    <div style={{ margin: "20px" }}>
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "11px",
        }}
      >
        {/* Date Picker */}
        <div
          style={{
            borderWidth: "0px 0px 1px 0px",
            borderColor: "black",
            borderStyle: "solid",
            paddingBottom: "8px",
            display: "flex",
            width: "190px",
            borderColor: "gray",
            justifyContent:"space-between",
          }}
        >
          {(props.data.cart_PO.quantity !==0 ) ? <div>
            {pickup}
          </div> : 
          <div>{moment(today).format("DD-MM-YYYY")}</div>
            }
          
          <Icon md="material:date_range" style={{ color: "#e79d41" }} />
        </div>

        {/* Time Picker */}
        <div
          style={{
            borderWidth: "0px 0px 1px 0px",
            borderColor: "black",
            borderStyle: "solid",
            paddingBottom: "8px",
            display: "flex",
            width: "190px",
            justifyContent:"space-between",
            borderColor: "gray",
          }}
        >
          <div>
          <TimePicker defaultValue={moment()} onChange={handleValueChange}
          style ={{zIndex:"6000"}}
          showSecond={false}
          disabledHours={getDisabledHours}
          disabledMinutes={getDisabledMinutes} />
      </div>
          <Icon md="material:schedule" style={{ color: "#e79d41" }} />
        </div>
      </div>
    </div>
  );
};