import React from "react";
import { ImgBackground } from "../../../assets/auth";
import { IcoProfile } from "../../../assets/profile";
import { Button, List, Input, Page, Icon } from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN } from "../../../js/global";

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_PIN : Array(6).fill(""),     
      isLoading: true,
      status_code:null,
      data: {
        id:0,
        c_pin:"",
      },
    };
    this.new_inputRefs = Array(6).fill(React.createRef())
  }

  onChangeNewPIN  (value, index) {
    const newPIN = this.state.new_PIN;
    newPIN[index] = value;
    this.setState({new_PIN:newPIN});
    if(index < newPIN.length - 1 && value) {
      this.new_inputRefs[index + 1].focus();
    }
    console.log("pin", this.state);
    };

  onPreviousNEW (key, index) {
    const newPIN = this.state.new_PIN;
    console.log(key);
    if(key === 8 && index !== 0 && !newPIN[index])  {
      this.new_inputRefs[index - 1].focus()
    }
  };

  onClickPay() {

    const Id= this.props.id;
    const PIN = this.state.new_PIN;
    const pin = PIN.join("");
    this.setState({
      ...this.state,
      data: { ...this.state.data, 
      id:Id,
      c_pin:pin,
  }
});
    console.log("this.state", this.state);

    const app = this.$f7;
    app.preloader.show();
    app.request
      .promise({
        url: API_URL + "/v1/asset/pay",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data:{
            "c_pin":this.state.data.c_pin,
            "order_id":this.state.data.id,
        }
        
      })
      
      .then(async (res) => {
        // this.props.router.navigate("/ewallet/"); 
        this.$f7router.navigate("/home/");
          
        app.preloader.hide();
      })
      .catch((err) => {
        app.preloader.hide();
        app.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
    // app.preloader.hide()


  }


  render() {
    return (
      <Page>
          <div className={styles["page-wrap"]}>
              <Button
                    force
                    iconMd="material:close"
                    className={styles["header-btn"]}
                    href={"/transactions/"+this.props.id+"/"}
                    />
                    <div className={styles["header-txt"]}>
                        Confirmation
                    </div>
                    <div className={styles["main-content"]}>
                        <div className={styles["content-wrap"]}>
                            <div className={styles["send-txt"]}>
                                <div className={styles["content-txt"]}>Please enter your password to continue</div>
                            </div>
                        </div>
                    </div>
            <div className={styles["input-form"]}>              
                <div  className={styles["pin"]}>  
                {this.new_inputRefs.map((k, idx) => {
                    return (
                        <input className={styles["Input-PIN"]}     
                  style={{
            margin: "0px 4px 0px 4px",
            borderWidth: "0px 0px 3px 0px",
            borderStyle: "solid",
            textAlign: "center",
        }}  
                  type="password"
                  maxLength="1"
                  onChange={(e) => this.onChangeNewPIN(e.target.value, idx)}
                  ref={(r) => this.new_inputRefs[idx] = r}
                  onKeyDown={e => this.onPreviousNEW(e.keyCode, idx)}
                  
                ></input>
                    )
                })}
                </div>
            </div>
          </div>
          <div className={styles["mk-footer"]}>
           <Button className={styles["btn-payment"]} onClick={() => this.onClickPay()}>
              Pay
             </Button>

           </div>
        
      </Page>
    );
  }
}
