import React from "react";
import { Page, Navbar, Button, f7, List, ListInput } from "framework7-react";
import { API_URL, FormatImage, GET_TOKEN } from "../../../js/global";
import styles from "./styles.module.less";
import moment from "moment";
import _ from "lodash";

export default class index extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      item: [],
      merchant_id: 0,
      total: 0,
      current_page: 1,
      data_length: 0,
      total_pages: 0,
      limit: 10,
      has_more: false,
      isLoading: false,
      coupon_codes: "",
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();
      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }
      f7.request
        .promise({
          url:
            API_URL +
            "/v1/coupon/mine?limit=" +
            this.state.limit +
            "&page=" +
            this.state.current_page +
            "&m",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data;
          console.log("voucher", data);
          f7.preloader.hide();
          this.setState({
            ...this.state,
            data: data,
          });
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  redeem() {
    const code = this.state.coupon_codes;
    f7.request
      .promise({
        url: API_URL + "/v1/coupon/redeem",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          code: code,
        },
      })
      .then((res) => {
        const data = res.data.data;
        console.log("redeem", data);
        f7.preloader.hide();
      })
      .catch((err) => {
        // console.log("err", err);
        f7.preloader.hide();
      });
  }

  //   onClickClaimVoucher(item) {
  //       const coupon_id = item.id;
  //       f7.request
  //         .promise({
  //           url: API_URL + "/v1/coupon/redeem",
  //           method: "POST",
  //           contentType: "application/json",
  //           dataType: "json",
  //           headers: {
  //             Authorization: GET_TOKEN(),
  //           },
  //           data: {
  //             coupon_id: coupon_id,
  //           },
  //         })
  //         .then((res) => {
  //           this.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
  //           console.log("claim", res);
  //         })
  //         .catch((err) => {
  //           this.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
  //         });
  //   };

  render() {
    const categoryGroup = _.groupBy(
      this.state.data,
      (o) => o.voucher_category.key
    );
    return (
      <Page name="category">
        <Navbar large backLink="back" title="Voucher List" />
        {/* <input className={styles["redeem-code"]} placeholder="Redeem Code"></input> */}
        <div style={{ display: "flex", margin: "10px" }}>
          <div className={styles["wrap-redeem"]}>
            <input
              className={styles["redeem-code"]}
              onChange={(val) =>
                this.setState({ ...this.state, coupon_codes: val.target.value })
              }
            />
          </div>
          <Button
            className={styles["button-add"]}
            onClick={() => this.redeem()}
          >
            Redeem
          </Button>
        </div>
        <div>
          {this.state.data &&
            _.map(categoryGroup, (value, key) => {
              return (
                <>
                  <div style={{ margin: "20px" }}>
                    <div className={styles["tag"]}>
                      <div className={styles["delivery-text"]}></div>
                      <div className={styles["delivery-date"]}>
                        <div>{key}</div>
                      </div>
                    </div>
                  </div>
                  {value.map((item, index) => {
                    return (
                      <div className={styles["menu-item"]}>
                        <div
                          style={{ display: "flex", marginBottom: "14.5px" }}
                        >
                          {/* <a
                style={{ display: "flex", flex: 1 }}
                href={"/foods/" + item.type + "/" + item.id + "/"}
              > */}
                          <div className={styles["icon-background"]}>
                            <img
                              className={styles["img-menu-product"]}
                              src={FormatImage(
                                item.voucher_category.photo,
                                "thumb"
                              )}
                            />
                          </div>
                          <div className={styles["container-detail"]}>
                            <div className={styles["txt-menu-name"]}>
                              {item.name}
                              {item.end_period == null ? (
                                <div className={styles["txt-menu-date"]} />
                              ) : (
                                <div className={styles["txt-menu-date"]}>
                                  Available{" "}
                                  {moment(item.start_period).format(
                                    "DD MMMM YYYY"
                                  )}{" "}
                                  s/d{" "}
                                  {moment(item.end_period).format(
                                    "DD MMMM YYYY"
                                  )}
                                </div>
                              )}
                            </div>
                            {/* <div className={styles["txt-menu-detail"]}>
                    {item.product_category.value_string}
                  </div> */}
                          </div>
                          {/* </a> */}
                          <div className={styles["container-ico"]}>
                            {/* <a
                  onClick={() => deleteWhishlist(item)}
                  style={{
                    zIndex: "50",
                  }}
                >
                  <img src={IcoLove} />
                </a> */}
                            <div />
                          </div>
                        </div>
                        <div className="mk-line-break small"></div>
                      </div>
                    );
                  })}
                </>
              );
            })}
        </div>
        {/* <VoucherList data={this.state.data}/> */}
        {/* {this.state.data.length == 0 ? (
          <MessageEmpty />
        ) : (
          <MessageEmpty />
          <MessageList data={this.state.data} />
        )} */}
      </Page>
    );
  }
}
class Modal extends React.Component {
  render() {
    if (this.props.isOpen === false) return null;

    return (
      <div>
        <div className={styles["modal"]}>{this.props.children}</div>
        <div className={styles["bg"]} onClick={(e) => this.close(e)} />
      </div>
    );
  }

  close(e) {
    e.preventDefault();

    if (this.props.onClose) {
      this.props.onClose();
    }
  }
}
