import React from "react";
import { ImgBackground } from "../../../assets/auth";
import { IcoProfile } from "../../../assets/profile";
import { Button, List, Input, Page, Icon } from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN, FormatImage } from "../../../js/global";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      full_name: "",
      phone: "",
      email: "",
      created_at: "",
      isLoading: true,
      photo: "",
      photoObj: "",
      img : "",
      dob:""
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }

      //Load User Profile API
      f7.request
        .promise({
          url: API_URL + "/v1/profile",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data.photo
          this.setState({
            ...this.state,
            ...res.data.data,
            photo: res.data.data.photo + "?t=" + new Date().getTime(),
            isLoading: false,
            img:data,
            dob:res.data.data.dob,
          });
          if (res.data.data.age <= 21) {
            this.alert(); 
          }
          f7.preloader.hide();
        })
        .catch((err) => {
          // console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  GetDate(date) {
    const result = new Date(date);
    return (
      result.getDate() + "-" + result.getMonth() + "-" + result.getFullYear()
    );
  }
  GetBirthDate(date) {
    const result = new Date(date);
    return (
      result.getFullYear() + "-" + result.getMonth() + "-" + result.getDate() 
    );
  }

  async onClickSaveProfile() {
    const data = this.state;
    const app = this;
    app.$f7router.app.preloader.show();

    if (data.photoObj) {
      var formData = new FormData();
      formData.append("photo", data.photoObj);

      await app.$request
        .promise({
          url: API_URL + "/v1/profile/avatar",
          method: "POST",
          contentType: "multipart/form-data",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data: formData,
        })
        .then((res) => {
        })
        .catch((err) => {
          // console.log("err", err);
          // app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
        });
    }

    await app.$request
      .promise({
        url: API_URL + "/v1/profile",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          full_name: data.full_name,
          phone: data.phone,
          email: data.email,
          dob: data.dob
        },
      })
      .then((res) => {
        app.$f7.dialog.alert("Profile updated!", () =>
          app.$f7router.navigate("/home/")
        );
      })
      .catch((err) => {
        // app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });

    app.$f7router.app.preloader.hide();
  }

  onClickPhoto() {
    const inputPhoto = document.getElementById("input-profile-avatar");
    inputPhoto.click();
  }

  onChangePhoto(val) {
    const photo = val.target.files[0];
    const urlPhoto = URL.createObjectURL(photo);
    this.setState({ ...this.state, photo: urlPhoto, photoObj: photo });
  }
  alert(){
    this.$f7.dialog.alert("your age under 21 years old please enter your age to access ready to drink");

  }

  render() {
    return (
      <Page
        style={{ visibility: this.state.isLoading ? "hidden" : "visible" }}
        name="login"
      >
        <div className={styles["container"]}>
          <img className={styles["img-background"]} src={ImgBackground} />
          <Button
            back
            iconMd="material:arrow_back_ios"
            className={styles["btn-back"]}
          />
          <div style={{ flex: 1 }}>
            <div
              className={styles["oval"]}
              style={{ cursor: "pointer" }}
              onClick={() => this.onClickPhoto()}
            >
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.img == null ? IcoProfile : FormatImage(this.state.photo,"thumb")}
                />
            </div>
            <div className={styles["txt-title"]}>{this.state.full_name}</div>
            <div className={styles["txt-sub-title"]}>
              Joined at {this.GetDate(this.state.created_at)}
            </div>
            <div
              style={{ marginTop: "20px" }}
              className="mk-line-break medium"
            />
            <div className={styles["input-form"]}>
              <List>
                <div className={styles["txt-label"]}>Full Name</div>
                <Input
                  type="text"
                  placeholder="Input your full name"
                  value={this.state.full_name}
                  onChange={(val) =>
                    this.setState({
                      ...this.state,
                      full_name: val.target.value,
                    })
                  }
                ></Input>
                <div
                  className={styles["txt-label"]}
                  style={{ marginTop: "25px" }}
                >
                  Phone Number
                </div>
                <Input
                  type="tel"
                  placeholder="Input your phone number"
                  value={this.state.phone}
                  onChange={(val) =>
                    this.setState({ ...this.state, phone: val.target.value })
                  }
                ></Input>
                <div
                  className={styles["txt-label"]}
                  style={{ marginTop: "25px" }}
                >
                  Email Address
                </div>
                <Input
                  type="email"
                  placeholder="Input your email address"
                  value={this.state.email}
                  onChange={(val) =>
                    this.setState({ ...this.state, email: val.target.value })
                  }
                ></Input>
                <div
                  className={styles["txt-label"]}
                  style={{ marginTop: "25px" }}
                >
                  Date of Birth
                </div>
                <Input
                  type="date"
                  defaultValue={this.state.dob}
                  onChange={(val) =>
                    this.setState({ ...this.state, dob: val.target.value })
                  }
                ></Input>
              </List>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              // /href="/client/"
              onClick={() => this.onClickSaveProfile()}
              fill
              className={styles["btn-next"]}
            >
              Save Profile
            </Button>
          </div>
          <input
            type="file"
            id="input-profile-avatar"
            style={{ display: "none" }}
            accept="image/*"
            onChange={(val) => this.onChangePhoto(val)}
          />
        </div>
      </Page>
    );
  }
}
