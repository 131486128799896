import React, { useState } from "react";
import { IcoShare } from "../../../assets/home";
import styles from "./styles.module.less";
// import {
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterIcon,
//   TwitterShareButton,
//   WhatsappShareButton,
//   WhatsappIcon,
//   LineShareButton,
//   LineIcon,
// } from "react-share";

  export default function index(props){
    const shareUrl = window.location.href;
    const title = 'Frodo';
    return (
          <div className={styles["share-container"]}>
          </div>
          
        //   {/* facebook sharebutton */}
        //   <div className={styles["Demo__some-network"]}>
        //     <FacebookShareButton
        //       url={shareUrl}
        //       quote={title}
        //       className="Demo__some-network__share-button"
        //     >
        //       <FacebookIcon size={32} round />
        //     </FacebookShareButton>
        //   </div>

        // {/* twitter sharebutton */}
        //   <div className={styles["Demo__some-network"]}>
        //     <TwitterShareButton
        //       url={shareUrl}
        //       title={title}
        //       className="Demo__some-network__share-button"
        //     >
        //       <TwitterIcon size={32} round />
        //     </TwitterShareButton>
        //   </div>

        //   {/* whatssapp */}
        //   <div className={styles["Demo__some-network"]}>
        //     <WhatsappShareButton
        //       url={shareUrl}
        //       title={title}
        //       separator=":: "
        //       className="Demo__some-network__share-button"
        //     >
        //       <WhatsappIcon size={32} round />
        //     </WhatsappShareButton>
        //   </div>

        //   {/* line sharebutton */}
        //   <div className={styles["Demo__some-network"]}>
        //     <LineShareButton
        //       url={shareUrl}
        //       title={title}
        //       className="Demo__some-network__share-button"
        //     >
        //       <LineIcon size={32} round />
        //     </LineShareButton>
        //   </div>


      
    );
  }
