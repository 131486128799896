import React from "react";
import { Page, Toolbar, Link, Tabs, Tab } from "framework7-react";

export default () => {
  return (
    <Page pageContent={false}>
      <Toolbar tabbar labels bottom>
        <Link
          tabLink
          href="./"
          routeTabId="view-home"
          tabLinkActive
          iconIos="f7:house_fill"
          iconAurora="material:home"
          iconMd="material:home"
          text="Home"
        />
        <Link
          tabLink
          href="transactions/"
          routeTabId="view-transaction"
          iconIos="f7:square_list_fill"
          iconAurora="material:view_list"
          iconMd="material:view_list"
          text="Transaction"
        />
        {/* <Link
          tabLink
          href="profile/"
          routeTabId="view-profile"
          iconIos="f7:person_alt"
          iconAurora="f7:person_alt"
          iconMd="material:person_alt"
          text="Profile"
        /> */}
      </Toolbar>
      <Tabs>
        <Tab className="page-content" id="view-home" />
        <Tab className="page-content" id="view-transaction" />
        {/* <Tab className="page-content" id="view-profile" /> */}
      </Tabs>
    </Page>
  );
};
