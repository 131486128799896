import React from "react";
import { ImgBackground } from "../../../assets/auth";
import { IcoProfile,IcoTrash } from "../../../assets/profile";
import { Button, List, Input, Page, Icon } from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN } from "../../../js/global";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      id:"",
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {

      //Load Products API
      f7.request
        .promise({
          url: API_URL + "/v2/profile/address?limit=20",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then(async (res) => {
          let item = res.data.data;
         
          
          this.setState({ 
            ...this.state,
           data:item,
          });
          
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  onClickDelete(id) {
    this.$f7ready((f7) => {
      //Load Products API
      f7.request
        .promise({
          url: API_URL + "/v2/profile/address/"+id,
          method: "Delete",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then(async (res) => {
          window.location.reload()
          
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  onClickDefault(id){
    this.$f7ready((f7) => {
      //Load Products API
      f7.request
        .promise({
          url: API_URL + "/v1/profile/address/set-as-default/"+id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then(async (res) => {
          window.location.reload()
          
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  render() {
    return (
      <Page name="login">
        <div className={styles["container"]}>
          <img className={styles["img-background"]} src={ImgBackground} />
          <Button
            iconMd="material:arrow_back_ios"
            href={"/settings/"}
            className={styles["btn-back"]}
          />
          <div style={{ flex: 1 }}>
            <div className={styles["oval"]}>
              <Icon
                size="70px"
                md="material:people"
                className={styles["ico"]}
              />
            </div>

            <div className={styles["title"]}> Address</div>
            <div style={{display:"flex",justifyContent:"space-between", alignItems:"baseline"}}>
            <Button className={styles["add-button"]} href="/new-address/lng:/lat:/address:" onClick={() => window.location.reload()}>
                      Add New Addresss
              </Button>
            </div>
            
            <div className={styles["address-list"]}>
              {this.state.data.map((item,index)=>{
                return(
                  <div className={styles["address-wrap"]}>
                    <div style={{display:"flex",justifyContent:"space-between", alignItems:"baseline"}}>
                      <div className={styles["address-name"]}>
                        {item.receiver_name}
                      </div>
                      {item.default !=1 ? "" :
                       <div className={styles["txt-default"]}> Default Address</div>
                     }
                      {item.default != 1 ? <img onClick={() => this.onClickDelete(item.id)} className={styles["img-ico"]} src={IcoTrash}/> :""}
                    </div>
                    <div className={styles["address-phone"]}>
                      {item.receiver_phone}
                    </div>
                    <div className={styles["address-detail"]}>
                      {item.address}
                    </div>
                    <div className={styles["button-wrap"]}>
                    {/* <Button className={styles["address-button"]} href={"/edit-address/"+item.id} >
                        Change Address
                    </Button> */}
                    <Button 
                      onClick={() => this.onClickDefault(item.id)}
                      className={styles["address-button"]} style={{
                      visibility:item.default != 1 ? "visible" : "collapse"}}>
                        Set As Default
                    </Button>
                    </div>
                  </div>
                )
              })}
            </div>
            
          </div>
         
        </div>
      </Page>
    );
  }
}
