import React from "react";
import styles from "./styles.module.less";
import { IcoDelivery, IcoStar } from "../../../../../assets/home";
import {
  API_URL,
  GET_USER_POSITION,
  FormatImage,
} from "../../../../../js/global";
import { Icon } from "framework7-react";
const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
    };
  }

  componentDidMount() {
    this.loadAPI();
  }

  loadAPI() {
    let position;
    if (GET_USER_POSITION()) {
      position =
        GET_USER_POSITION().latitude + "," + GET_USER_POSITION().longitude;
    }

    this.$f7ready((f7) => {
      f7.request.promise
        .get(
          API_URL +
            "/v1/merchant?" +
            (position ? "coordinates=" + position + "&" : "") +
            "sort_by=near_by",
          null,
          "json"
        )
        .then((res) => {
          const data = res.data.data;
          this.setState({ data: data, isLoading: false });
          this.props.onLoaded(true);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.onLoaded(false);
        });
    });
  }

  render() {
    return (
      <div style={this.state.data.length == 0 ? hide : display}>
        <div className={styles["container-title-section"]}>
          <div className={styles["txt-title-section-1"]}>Merchant List</div>
          <a href="/merchants/" className={styles["txt-more-section"]}>
            See all
          </a>
        </div>
        <div className={styles["mk-horizontal-scroll"]}>
          {this.state.data.map((item, index) => {
            return (
              <a href={"/merchants/" + item.id + "/"}>
                <div
                  style={{
                    filter: item.is_open == "open" ? "" : "grayscale(100%)",
                  }}
                >
                  <div className={styles["container"]}>
                    <div className={styles["img-product"]}>
                      <img
                        className={styles["img-product"]}
                        src={FormatImage(item.photo, "thumb")}
                      />
                      {/* <div className={styles["txt-merchant-name"]}>
                      {item.merchant}
                    </div> */}
                    </div>
                    <div className={styles["container-detail-1"]}>
                      <div className={styles["txt-product-name"]}>
                        {item.name?.length > 38
                          ? item.name.slice(0, 38) + "..."
                          : item.name}
                      </div>
                      {/* <div className={styles["container-detail-2"]}>
                        <div className={styles["txt-detail"]}>
                          <img src={IcoStar} className={styles["ico"]} />
                          {item.rating}
                        </div>
                        <div className={styles["txt-detail"]}>
                          <img src={IcoDelivery} className={styles["ico"]} />
                          {item.distance} Km
                        </div>
                        <div
                          className={styles["txt-detail"]}
                          style={{ display: "flex" }}
                        >
                          <Icon
                            md="material:query_builder"
                            size="12px"
                            className={styles["ico"]}
                            style={{ marginLeft: "-2px" }}
                            color="blue"
                          />
                          <div style={{ paddingTop: "3px" }}>
                            {item.is_open}
                          </div>
                        </div>
                      </div> */}
                      <div className={styles["txt-product-price"]}>
                        {/* Food price start from Rp 13.000 */}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}
