import React from "react";
import { Page, Navbar,f7 } from "framework7-react";
import FavoriteList from "./components/favorite-list";
import MenuList from "./components/menu-list";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN } from "../../../js/global";
export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
    if (!GET_TOKEN()) {
      this.$f7router.navigate("/onboarding/");
    }
    else {
    this.refreshFavoriteList();}
 });
  }

  refreshFavoriteList() {
    if (!GET_TOKEN()) {
      this.$f7router.navigate("/onboarding/");
    }
    else {
      this.$f7ready(async (f7) => {
        f7.preloader.hide();
        await f7.request
          .promise({
            url: API_URL + "/v1/wishlist/?limit=10",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              Authorization: GET_TOKEN(),
            },
          })
          .then(async (res) => {
            const data = res.data.data;
            // Set State
            this.setState({
              ...this.state,
              data: data,
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
  
        f7.preloader.hide();
      });
    }
  }

  render() {
    return (
      <Page name="category">
        <Navbar large backLink="back" title="Your Favorite" />
        <MenuList
          updateWishList={() => this.refreshFavoriteList()}
          data={this.state.data}
        />
      </Page>
    );
  }
}
