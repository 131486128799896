import React from "react";
import styles from "./styles.module.less";
import moment from "moment";
import { IcoDelivery, IcoStar } from "../../../../../assets/home";
import { Button, Page, Navbar, Searchbar } from "framework7-react";
import {FormatImage  } from "../../../../../js/global";
import { Icon } from "framework7-react";

export default (props) => {
  return (
    <div>
      {props.data.map((item, index) => {
        return (
          <a href={"/merchants/" + item.id + "/"}>
            <div
              style={{
                filter: item.is_open == "open" ? "" : "grayscale(100%)",
              }}
              >
              <div className={styles["merchant-item"]}>
                <div style={{ display: "flex" }}>
                  <img className={styles["img-product"]} src={FormatImage(item.photo,"thumb")} />
                  <div className={styles["container-detail"]}>
                    <div className={styles["txt-name"]}>{item.name}</div>

                    <div className={styles["txt-detail"]}>{item.description}</div>
                    {/* <div className={styles["container-detail-2"]}>
                      <div className={styles["txt-detail"]}>
                        <img src={IcoStar} className={styles["ico"]} />
                        {item.rating}
                      </div>
                      <div className={styles["txt-detail"]}>
                        <img src={IcoDelivery} className={styles["ico"]} />
                        {item.distance} Km
                      </div>
                      <div
                        className={styles["txt-detail"]}
                        style={{ display: "flex" }}
                      >
                        <Icon
                          md="material:query_builder"
                          size="15px"
                          className={styles["ico"]}
                          style={{ marginLeft: "-5px" }}
                          color="blue"
                        />
                        <div style={{ paddingTop: "3px" }}>{item.is_open}</div>
                      </div>
                    </div> */}
                    {/* {item.stock ==0 ? 
                    <div className={styles["outstock-txt"]}>Out of Stock</div>:<div className={styles["txt-detail"]}>
                    <img src={IcoStar} className={styles["ico"]} />
                    {item.rating} - Great Taste!
                  </div>} */}
                    {/* <div>
                      Rp {Intl.NumberFormat().format(item.price ? item.price : 0)}
                    </div> */}
                    {/* <div className={styles["container-limit"]}>
                      <div width className={styles["txt-product-limit"]}>  
                        Kuota Pre-Order {"  "} : {"  "}{item.limit}
                    </div>
                  </div> */}
                  </div>
                </div>
                <div className="mk-line-break"></div>
              </div>
            </div>
          </a>
        );
      })}
       {props.page.current_page == props.page.total_pages || props.page.data_length == 0 ||props.page.isLoading == true ? "" :<Button
              className={styles["btn-load-more"]}
              iconSize="medium"
              outline
              onClick={() => {props.fetchMoreData();}}
            >
              <div style={{ marginLeft: "2px" }}>Load More</div>
            </Button>}
    </div>
  );
};
