import React from "react";
import { Page, Button, Input, List } from "framework7-react";
import { IcoLove, IcoPlusOval, IcoMinusOval } from "../../../assets/home";
import { API_URL, FormatImage } from "../../../js/global";
import { UPDATE_CART, SHOW_CART } from "../../../js/cart";
import { IncreaseDecrease, ButtonCart, Share} from "../../../components/customs";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import styles from "./styles.module.less";


const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

export default class extends React.Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      toggle: false,
      data: {
        item: [],
        merchant_id: 0,
        quantity: 0,
        total: 0,
      },
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));
  }

  onChangeNotes(value) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        notes: value,
      },
    });
  }

  handleChange(value) {

    console.log(this.state.data)
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        product_category: value.target.value,
      }
      // size: event.target.value
    });
  }
  
  handleSubmit(event) {
    event.preventDefault();
    
    alert(`You chose the ${this.state.size} pizza.`);
  }

  onChangeQty(value) {
    const item = this.state.data.item;

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        qty: value,
        price: value * item.price,
      },
    });
  }

  onClickAddToCart() {
    if (this.state.data.qty == 0) {
      this.$f7.dialog.alert("Please add an item.");
    } else {
      UPDATE_CART(this.state.data.item, this.state.data.qty, this.state.data.notes, this.state.data.product_category);
      this.$f7router.navigate(
        "/checkout/"
      );
    }
  }


  componentDidMount() {
    const comboId = this.props.id;
    this.$f7ready((f7) => {
      f7.preloader.show();

      //Load Products API
      f7.request.promise
        .get(API_URL + "/v1/combo/" + comboId , null, "json")
        .then(async (res) => {
          let data = res.data.data;

          await SHOW_CART(data)
            .then((res) => (data = res))
            .catch((err) => console.log("cart_err", err));

          this.setState({
            ...this.state,
            data: data,
          });
          f7.preloader.hide();
        })
        .then((res) => {
          console.log('just', this.state.data)
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  render() {
    var modal = [];
    modal.push(
      <div className="modal" style={this.state.toggle ? display : hide}>
      <Share/>
      </div>
    );
    return (
      <Page>
        {!this.state.data ? null : (
          <>
            <div className={styles["header"]}>
              <img
                className={styles["header-img"]}
                src={FormatImage(this.state.data.item.photo,"thumb")}
                
              />
              <Button
                href={"/merchants/" + this.state.data.item.merchant_id + "/"}
                iconMd="material:arrow_back_ios"
                className={styles["header-btn"]}
              />
              <div style={{ flex: 1 }} />
              {/* <Button
                onClick={() => this.onClickShare()}
                iconMd="material:share"
                className={styles["header-btn"]}
              /> */}
              <BrowserView>
              <Button
                iconMd="material:share" 
                className={styles["share-button"]}
                onClick={(event) => this.toggle(event)}>
                  {modal}
              </Button>
            </BrowserView>
            <MobileView>
              <Button
                onClick={() => this.onClickShare()}
                iconMd="material:share"
                className={styles["header-btn"]}
              />
            </MobileView>
              
            </div>
            <div
              style={{
                height: "106px",
                display: "flex",
                alignItems: "center",
                margin: "0px 20px 0px 20px",
                filter:
                  this.state.data.item.is_open == "open"
                    ? ""
                    : "grayscale(100%)",
              }}
            >
              <div className={styles["food-col-1"]}>
                <div className={styles["txt-food-name"]}>
                  {this.state.data.item.name}
                </div>
                <div className={styles["txt-food-detail"]}>
                  {this.state.data.item.description}
                </div>
              </div>
              <div className={styles["food-col-2"]}>
                <img src={IcoLove} />
                <IncreaseDecrease
                  defaultValue={this.state.data.qty}
                  onChange={(value) => this.onChangeQty(value)}
                  disable={this.state.data.item.is_open != "open"}
                />
              </div>
            </div>
            <div className="mk-line-break medium"></div>
            <div>
              <form className={styles["category-method"]} onSubmit={this.handleSubmit} >
                <strong style={{fontSize: "16px"}}>Choose Your Method</strong>
                <label className={styles["radio-label"]}>
                  Ready to Eat<input type="radio" value="READY TO EAT"
                  checked={this.state.data.product_category === "READY TO EAT"}
                  onChange={this.handleChange}/>
                </label>
                <hr style={{
                      width: "100%",
                      opacity: "0.3",
                }}></hr>
                <label className={styles["radio-label"]}>
                    Ready to Cook<input type="radio" value="READY TO COOK"  
                    checked={this.state.data.product_category === "READY TO COOK"}
                    onChange={this.handleChange}/>
                  </label>
                {/* <button type="submit" className="submit-button">Make your choice</button> */}
              </form>
            </div>
            <div className="mk-line-break medium"></div>
            <List style={{ margin: "20px 20px 0px 20px" }}>
              <div className={styles["txt-label"]}>Note</div>
              <Input type="text" placeholder="Type your note here"
                defaultValue={this.state.data.item.notes}
                onChange={(value) => this.onChangeNotes(value.target.value)}>
              </Input>
            </List>
            <div
              className="mk-footer"
              style={{
                display:
                  this.state.data.item.is_open == "open" ? "block" : "none",
                  // this.state.data.item.is_open == "open" && this.state.data.qty >=1 ? "block" : "none",
              }}
            >
              <ButtonCart
                quantity={this.state.data.qty}
                price={this.state.data.price}
                notes={this.state.data.notes}
                product_category={this.state.data.product_category}
                text="Add to cart"
                onClick={() => this.onClickAddToCart()}
              />
            </div>
          </>
        )}
      </Page>
    );
  }
}
