import React from "react";
import { Page, Button, ActionsButton } from "framework7-react";
import DeliverTo from "../deliver";
import OrderSummary from "../order-summary";
import Voucher from "../voucher";

import PickupSummary from "../pickup-summary";
import PaymentDetails from "../payment-details";
import styles from "./styles.module.less";
import { ORDER_WA, GET_CART, GET_MERCHANT_PROFILE } from "../../../../../js/global";
import {
  UPDATE_CART,
  REMOVE_CART
} from "../../../../../js/cart2";
import { API_URL, GET_TOKEN, FormatImage } from "../../../../../js/global";
import PickupTime from "../pickup-time";
import { IcoArrow } from "../../../../../assets/merchant";
import moment from "moment";
import preOrder from "../../../../home/main/components/pre-order";

const display = {
  display: "block",
};
const hide = {
  display: "none",
};
export default class index extends React.Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      phone: "",
      pin_enabled: 0,
      methodDelivery: "",
      methodFood: "",

      isLoading: true,
      cart: {
        item: [],
      },
      cart_PO: {
        item: [],
      },
      pickup_time: {
        date: "",
        time: moment().format("HH:mm"),
      },
      voucher_codes: "",
      payment_methods: [],
      banks: [],
      trial: 0,
      pick_delivery_method: false,
      data: {
        note: "",
        Myvoucher: [],
        coupon: [],
        coupon_codes: "",
        payment_method: 1,
        before_payment: 1,
        shipment_method: null,
        merchant_id: GET_MERCHANT_PROFILE() ? (GET_MERCHANT_PROFILE().merchant_id ?  GET_MERCHANT_PROFILE().merchant_id:GET_MERCHANT_PROFILE().id): "",
        name: "",
        photo: "",
        item: [],
        item_fee: [],
        toggle: false,
      },
      size: "",
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  toggle(event) {
    // this.setState((prevState) => ({
    //   toggle: !prevState.toggle,
    // }));
  }
  myVoucher() {
    const app = this.$f7;
    app.preloader.show();
    app.request
      .promise({
        url: API_URL + "/v1/coupon/mine?m="+this.state.data.merchant_id+"",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
      .then(async (res) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            Myvoucher: [res.data.data],
          },
        });
        console.log("hahahaa", this.state);
      })
      .catch((err) => {});
  }
  async getpaymentUrl(id) {
    const app = this.$f7;
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/get",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: id,
        },
      })
      .then((res) => {
        window.location.href = res.data.data.url.WebUrl;
      })
      .catch((err) => {
        if (this.state.trial >= 3) {
          throw err;
        }
        this.setState({
          trial: this.state.trial + 1,
        });
        this.getpaymentUrl(id);
      });
  }

  CreatepaymentUrl(id) {
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/create",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: id,
        },
      })
      .then(async (res) => {
        this.getpaymentUrl(id);
      })
      .catch((err) => {
        // preloader.hide();
        // alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  getValidateOrder() {
    const cart = this.state.cart;
    let currItem = [];
    for (var key in cart.item) {
      if (cart.item[key].qty !== 0) {
        currItem.push({
          product_id: cart.item[key].id,
          qty: cart.item[key].qty,
          notes: cart.item[key].notes,
          type: cart.item[key].type,
          serve_as: cart.item[key].serve_as,
        });
      }
    }
    const currItemConstruct = _.chain(currItem)
      .groupBy("type")
      .map((val, key) => ({
        type: key,
        data: val,
      }))
      .value();

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        item: [...currItemConstruct],
        // payment_method: this.state.banks[0].id,
      },
    });
    const app = this.$f7;
    const data = this.state.data;

    this.$request
      .promise({
        url: API_URL + "/v1/take-away/order/validate",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data:{
          item:data.item,
          address:data.address,
          // delivery_service_token:data.delivery_service_token,
          note:data.note,
          payment_method:data.payment_method,
          // shipment_method:data.shipment_method,
          coupon_codes:this.state.voucher_codes,
          merchant_id:this.state.data?.merchant_id,
          
        },
      })
      .then((res) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            item_fee: [res.data.data.fee],
          },
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  getPaymetMethod() {
    this.$f7ready((f7) => {
      // f7.preloader.show();

      //Load Products API
      f7.request.promise
        .get(API_URL + "/v1/payment-method?limit=5", null, "json")
        .then(async (res) => {
          let data = res.data.data;
          if (data) this.setState({ ...this.state, payment_methods: data });
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }
  onAddMore() {
    this.props.router.navigate(
      "/merchants/" + this.state.cart.merchant_id + "/"
    );
  }
  onClickOrderNow() {
      this.props.router.navigate("/payment-method/")
  }

  componentDidMount() {
    if (GET_CART()) {
      this.setState({ ...this.state, cart: GET_CART() });
    }

    this.$f7ready((f7) => {
      // f7.preloader.show();

      //Load Products API
      var merchant_id = this.state.data?.merchant_id;
      if(!merchant_id){
        merchant_id=this.props.merchant_id
      }

      console.log("MERCHANT:"+merchant_id)

      f7.request.promise
        .get(
          API_URL +
            "/v1/merchant/" +
            merchant_id +
            "?include=bank_informations",
          null,
          "json"
        )

        .then(async (res) => {
          await this.getValidateOrder()
          let data = res.data.data;

          if (data)
            this.setState({
              ...this.state,
              name: res.data.data.name,
              photo: res.data.data.photo,
            });
          if (data)
            this.setState({
              ...this.state,
              name: res.data.data.name,
              photo: res.data.data.photo,
              address: {
                ...this.state.address,
                latitude: res.data.data.latitude,
                longitude: res.data.data.longitude,
              },
            });
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }

  onCartUpdate(item, value, notes) {
      UPDATE_CART(item, value, notes)
        .then((res) => {
          this.setState({ ...this.state, cart: res });
        })
        .catch((err) => console.log("err", err));
    // }
  }

  AddMethod(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        before_payment: val,
        payment_method: val.id,
      },
    });

    this.toggle();
  }

  onChangeVoucher(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        coupon_codes: val,
      },
    });
  }

  onChangeNote(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        note: val,
      },
    });
  }

  onChangeTime(val) {
    this.setState({
      ...this.state,
      dataTIme: {
        ...this.state,
        pickup_time: { ...val },
      },
    });
  }

  handleChangeVoucherDelivery(value) {
    this.setState({
      ...this.state,
      methodDelivery: value,
    });
    console.log("handleChangeVoucherdelivery", this.state.methodDelivery);
  }

  onClickApplyVoucher(value) {
    this.setState({
      ...this.state,
      voucher_codes: value,
    });
    console.log("join voucher", this.state);
    this.state.cart_PO.quantity == 0
      ? this.getValidateOrder()
      :  this.getValidatePreOrder();
  }

  handleChangeVoucherFood(value) {
    this.setState({
      ...this.state,
      methodFood: value,
    });
    console.log("handleChangeVoucher food", this.state.methodFood);
  }

  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  onChangeNotes(item, notes) {
    UPDATE_CART(item, notes).then((res) => {
      this.setState({ ...this.state, cart: res });
    });
  }

  render() {
    // var modal = [];
    // modal.push(
    //   <div style={this.state.toggle ? display : hide}>
    //     <div className={styles["payment-items"]}>
    //       {this.state.payment_methods.map((item, index) => {
    //         return (
    //           // <div style={{ display: "flex", marginLeft: "20px" }}>
    //           <ActionsButton
    //             className={styles["input-btn"]}
    //             onClick={() => this.AddMethod(item)}
    //           >
    //             <div style={{ display: "flex", alignItems: "center"}}>
    //               <img className={styles["input-photo"]} src={item.photo}></img>
    //               <div className={styles["method-wrap"]}>
    //                 {item.key == "qoin" ? (
    //                   <div>
    //                     <div style={{ height: "20px" }}>
    //                       {item.value_string}
    //                     </div>
    //                     <div className={styles["desc-method"]}>
    //                       (OVO, Visa and Master Card)
    //                     </div>{" "}
    //                   </div>
    //                 ) : (
    //                   <div>{item.value_string}</div>
    //                 )}
    //               </div>
    //             </div>
    //           </ActionsButton>
    //         );
    //       })}
    //     </div>
    //   </div>
    // );
    return (
      <Page>
        {/* {this.state.toggle == true ? (
          <div>
            <div style={{ marginTop: "80px" }} />
            {modal}
          </div>
        ) : ( */}
          <div>
            <div />
            <div style={{ display: "flex", margin: 20, marginTop:60 }}>
              <img
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "5px",
                  backgroundColor: "gray",
                }}
                src={FormatImage(this.state.photo, "thumb")}
              />
              <div
                style={{
                  marginLeft: "20px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div className={styles["txt-restaurant-location"]}>
                  Pickup location
                </div>
                <a
                  onClick={() =>
                    this.openInNewTab(
                      "http://maps.google.com/?q=" +
                        this.state.address.latitude +
                        "," +
                        this.state.address.longitude
                    )
                  }
                >
                  <div className={styles["txt-restaurant-name"]}>
                    {this.state.name}
                  </div>
                </a>
                <div className={styles["txt-restaurant-details"]}></div>
              </div>
            </div>
            <div className="mk-line-break medium" />
            <PickupSummary
              data={this.state.cart ? this.state.cart : []}
              preOrder={this.state.cart_PO ? this.state.cart_PO : []}
              multiple={this.state.cart_PO ? true : false}
              onAddMore={() => this.onAddMore()}
              onUpdateCart={(item, val, notes) =>
                this.onCartUpdate(item, val, notes)
              }
              onChangeNotes={(item, notes) => this.onChangeNotes(item, notes)}
            />{" "}
            {/* <Voucher
              data={this.state ? this.state : []}
              codes={this.state.coupon_codes}
              onChangeVoucher={(val) => this.onChangeVoucher(val)}
              voucherDeliverySelected={this.state.methodDelivery}
              voucherFoodSelected={this.state.methodFood}
              handleChangeVoucherFood={(value) =>
                this.handleChangeVoucherFood(value)
              }
              handleChangeVoucherDelivery={(value) =>
                this.handleChangeVoucherDelivery(value)
              }
              onClickApplyVoucher={(value) => this.onClickApplyVoucher(value)}
              onChangeNote={(val) => this.onChangeNote(val)}
              addButton={() => this.addButton()}
            /> */}
            <div className="mk-line-break medium" />
            <PaymentDetails data={this.state ? this.state : []} />
            <Button
              className={styles["button-wrap"]}
              onClick={(event) => this.onClickOrderNow()}
            >
              <div>
                <div className={styles["method-btn"]}>
                  {/* {this.state.data.payment_method == 1
                    ?  */}
                    Choose Payment Method
                    {/* : this.state.data.before_payment.value_string} */}
                </div>
                {/* <div className={styles["notes"]}>
                  {this.state.data.payment_method == 1
                    ? ""
                    : ""}
                </div> */}
              </div>
              <img className={styles["img-ico"]} src={IcoArrow} />
            </Button>
            <div style={{ marginTop: "30px" }} />
            <div className={styles["container-btn-buy"]}>
              {this.state.data.payment_method == 1 ? (
                ""
              ) : (
                <Button
                  onClick={() =>
                    // this.state.cart_PO.quantity == 0
                      // ? 
                      this.onClickOrderNow()
                      // : this.onClickBookNow()
                  }
                  className={styles["btn-order"]}
                  text={this.state.cart_PO.quantity != 0 ? "PAY" : "PAY"}
                />
              )}
            </div>
            <div style={{ marginTop: "80px" }} />
          </div>
        {/* )} */}
      </Page>
    );
  }
}
