import React from "react";
import styles from "./styles.module.less";
import { Row, Col, f7 } from "framework7-react";
import { IcoLove } from "../../../../../assets/home";
import { IncreaseDecrease } from "../../../../../components/customs";
import { API_URL, GET_TOKEN,FormatImage  } from "../../../../../js/global";

export default (props) => {
  let data = [];
  if (props.data) {
    data = props.data;
  }
  console.log("liat",  data);

  return (
    <div>
      {data.map((item, index) => {
        return (
          <div style={{ backgroundColor: "white" }}>
            <div className={styles["txt-category-product"]}>
              {item.category}
            </div>
            <Items
              data={item.foods}
              onUpdateCart={(item, value) => props.onUpdateCart(item, value)}
              cart={props.cart}
              isOpen={props.isOpen}
              allData={props.allData}
              stock={item.stock}
              updateWishList={() => props.updateWishList()}
              type={item.type}
            />
          </div>
        );
      })}
    </div>
  );
};

const Items = (props) => {
  const cart = props.cart; 
  console.log("ini all data ready",props)
  const addWhishlist = (item) => {
    if (!GET_TOKEN()) {
      this.$f7router.navigate("/onboarding/");
    }
    else {
    f7.request
      .promise({
        url: API_URL + "/v1/wishlist/add",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          product_id: item.id,
        },
      })
      .then((res) => {
        console.log("res", res);
        props.updateWishList();
      })
      .catch((err) => {
        console.log("err", err);
        // app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });}
  };

  const deleteWhishlist = (item) => {
    if (!GET_TOKEN()) {
      this.$f7router.navigate("/onboarding/");
    }
    else {
    f7.request
      .promise({
        url: API_URL + "/v1/wishlist/delete?product_id=" + item.id,
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: null,
      })
      .then((res) => {
        console.log("res", res);
        props.updateWishList();
      })
      .catch((err) => {
        console.log("err", err);
        // app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });}
  };

  return (
    <div>
      {props.data.map((item, index) => {
        return (

          <a href={ ""}>
            <div
              className={styles["menu-item"]}
              style={{
                filter: props.isOpen == "open" ? "" : "grayscale(100%)",
              }}
            >
              <div
              style={{
                filter: item.stock != 0 ? "" : "grayscale(100%)",
              }}
              >
                <div style={{ display: "flex", marginBottom: "14.5px" }}>
                  <img className={styles["img-menu-product"]} src={FormatImage(item.photo,"thumb")} />
                  <div className={styles["container-detail"]}>
                    <div className={styles["txt-menu-name"]}> {item.name?.length > 50
                          ? item.name.slice(0, 50) + "..."
                          : item.name}</div>
                    <div className={styles["txt-menu-detail-desc"]}>
                    {item.description?.length > 100
                          ? item.description.slice(0, 100) + "..."
                          : item.description}
                    </div>  
                    {item.stock==0 ?<div className={styles["txt-menu-price"]}>
                     Out of Stock
                    </div> :"" }
                    
                    {(item.type == "combo" )?
                      <div className={styles["txt-menu-detail"]}>
                      {
                      item.food_category.map(item => {
                        return (
                          <div className={styles["txt-menu-detail"]}>
                            {item.value_string}
                          </div>
                        )
                      })}
                    </div> : ""}
                    {(item.price_before==null || item.price_before <= item.price) ? (
                      <div className={styles["txt-menu-price"]}>
                      Rp {Intl.NumberFormat().format(item.price)}
                      </div>
                      ) : (
                        <div>
                          <div className={styles["txt-menu-price-promo"]}>
                          Rp {Intl.NumberFormat().format(item.price_before)}
                          </div> 
                          <div width className={styles["txt-menu-price"]}>
                          Rp {Intl.NumberFormat().format(item.price)} </div>
                      </div>)}
                    
                  </div>
                  <div className={styles["container-ico"]}>
                    {/* <a
                      onClick={() =>
                        !item.is_wishlist
                          ? addWhishlist(item)
                          : deleteWhishlist(item)
                      }
                      style={{
                        filter: item.is_wishlist ? "" : "grayscale(100%)",
                        zIndex: "50",
                      }}
                    >
                      <img src={IcoLove} />
                    </a> */}
                    {/* {item.stock==0 || props.isOpen=="closed" ? " ": */}
                    <IncreaseDecrease
                      disable={props.isOpen == "closed"||item.stock==0}
                      defaultValue={() => {
                        let val = 0;
                        for (var key in cart.item) {
                          if (cart.item[key].id === item.id && cart.item[key].type === item.type && cart.item[key].serve_as === item.serve_as) {
                            val = cart.item[key].qty;
                          }
                        }
                        return val;
                      }}
                      onChange={(value) => {
                        props.onUpdateCart(item, value);
                      }}
                    />
                    {/* } */}
                  </div>
                </div>
                <div className="mk-line-break"></div>
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
};
