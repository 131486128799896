import React from "react";
import {
  Page,
  Link,
  Toolbar,
  Button,
  Tab,
  Tabs,
  Icon,
  f7,
  Navbar,
  BlockHeader
} from "framework7-react";
import { IcoDelivery, IcoStar } from "../../../assets/home";
import styles from "./styles.module.less";
import {
  API_URL,
  SET_TOKEN,
  GET_TOKEN,
  SetRedirectToCheckOut,
  FormatImage,
  GET_USER_PROFILE,
  SET_MERCHANT_PROFILE,
  GET_CART,
  COMING_SOON,
  UNDER_MAINTENANCE
} from "../../../js/global";
import {
  INDEX_CART,
  UPDATE_CART,
  INDEX_CART_PO,
  INFO_CART_PO,
  GET_TABLE,
  UPDATE_CART_PO,
  UPDATE_CART_WITHFOOD,
} from "../../../js/cart2";
import MenuTab from "./components/menu-tab";
import { ButtonCart, Share } from "../../../components/customs";
import { GET_PRODUCT } from "../../../js/network";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import PreOrder from "./components/preorder-tab";

const lodash = require("lodash");
const display = {
  display: "block",
};
const hide = {
  display: "none",
};

export default class extends React.Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);

    this.state = {
      merchant: null,
      cart: [],
      cart_PO: [],
      products: [],
      preOrder: [],
      rs_readytocook: [],
      rs_readytodrink: [],
      rs_readytoeat: [],
      cb_readytocook: [],
      cb_readytodrink: [],
      cb_readytoeat: [],
      apiCallCount: 0,
      toggle: false,
      current_page: 1,
      data_length: 0,
      total_pages: 1,
      limit: 90,
      has_more: false,
      isLoading: false,
      disable: false,
      table: 0,
      visibility_table: true,
      foods_data:[]
    };
    this.fetchData = this.fetchData.bind(this);
  }

  onClickShare() {
    navigator
      .share({
        title: "Frodo",
        text:
          "Check out Frodo! Delicious food from " + this.state.merchant.name,
        url: window.location.href,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }
  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  }

  componentDidMount() {
    this.requestDataWithCredential()
    this.refreshProducts();
  }

  requestDataWithCredential(){

    this.$f7ready((f7) => {

      setTimeout(() => {
        if(COMING_SOON){
          this.$f7router.navigate("/comingsoon/",{
            reloadAll: true,
            clearPreviousHistory: true,
            animate:false
          });
        }
        else if(UNDER_MAINTENANCE){
          this.$f7router.navigate("/comingsoon/",{
            reloadAll: true,
            clearPreviousHistory: true,
            animate:false
          });
        }
        else {
          if(!GET_TOKEN()||GET_TOKEN().includes("undefined")){
            console.log("TRIAL2:"+(JSON.stringify(window.location)).split("=")[1]);
  if((JSON.stringify(window.location)).split("=")[0]="?token"){
    f7.preloader.show();
    f7.request.promise
      .postJSON(
        API_URL +
          "/v1/auth/single-login",
          {
            token: decodeURIComponent((JSON.stringify(window.location)).split("=")[1]),
          },
        null
      )
      .then((res) => {
        f7.preloader.hide();
        if(res.data.status_code!=200){
          this.props.router.navigate("/onboarding/",{
            reloadAll: true,
            clearPreviousHistory: true,
            animate:false
          });
        }
        else {
          SET_TOKEN(res.data.token);
        }
      })
      .catch((err) => {
        f7.preloader.hide();
        this.props.router.navigate("/onboarding/",{
          reloadAll: true,
          clearPreviousHistory: true,
          animate:false
        });
      });
  }
          }
        }
      },100);
        

      
    });
  }

  refreshProducts() {
    const merchantId = this.props.id;
    this.$f7ready(async (f7) => {
      //Load Merchants API
      await f7.request.promise
        .get(API_URL + "/v1/merchant/" + merchantId, null, "json")
        .then((res) => {
          this.setState({ ...this.state, merchant: res.data.data });
          SET_MERCHANT_PROFILE(res.data.data)
        })
        .catch((err) => {
          console.log("err", err);
        });

      //loads Product
      try {
        let group_products = [];

        //using promise All Settled for reduce time request
        let foods_data = await Promise.allSettled([
          // GET_PRODUCT("product", merchantId, "readytocook"),
          // GET_PRODUCT("combo", merchantId, "readytocook"),
          GET_PRODUCT("product", merchantId, ""),
          // GET_PRODUCT("combo", merchantId, "readytoeat"),
          // GET_PRODUCT("product", merchantId, "readytodrink"),
          // GET_PRODUCT("combo", merchantId, "readytodrink"),
        ]);
        let cart;

        await INDEX_CART(merchantId,foods_data)
          .then((res) => (cart = res))
          .catch((err) => console.log("err", err));

        let table;
        await GET_TABLE()
          .then((res) => (table = res))
          .catch((err) => console.log("err", err));

        this.setState({
          ...this.state,
          cart: cart,
          products: group_products.concat(
            foods_data[0].value
          ),
          table: table,
          foods_data: foods_data[0].value
        });
      } catch (err) {
        console.log(err);
      }
      // Get Pre Order List
      // await f7.request
      //   .promise({
      //     url:
      //       API_URL +
      //       "/v1/pre-order/product/" +
      //       merchantId +
      //       "?limit=" +
      //       this.state.limit +
      //       "&" +
      //       "&page=" +
      //       this.state.current_page,
      //     method: "GET",
      //     contentType: "application/json",
      //     dataType: "json",
      //     headers: {
      //       Authorization: GET_TOKEN(),
      //     },
      //   })
      //   .then(async (res) => {
      //     const data = res.data.data;
      //     let cart_PO;

        //   await INDEX_CART_PO(merchantId)
        //     .then((res) => (cart_PO = res))
        //     .catch((err) => console.log("cart_err", err));
        //   let table;

        //   // Set State
        //   this.setState({
        //     ...this.state,
        //     cart_PO: cart_PO,
        //     preOrder: data,
        //     total_pages: res.data.paginator.total_pages,
        //     has_more:
        //       this.state.current_page < res.data.paginator.total_pages
        //         ? true
        //         : false,
        //     data_length: res.data.paginator.total_items,
        //   });
        // })
        // .catch((err) => {
        //   console.log("err", err);
        // });
      f7.preloader.hide();
    });
  }

  async getDataProducts(param, query, foodCategory) {
    return this.$f7ready(async (f7) => {
      await f7.request.promise
        .get(API_URL + "/v1/" + param, query, "json")
        .then((res) => {
          this.setState(
            {
              [foodCategory]: res.data.data,
              apiCallCount: this.state.apiCallCount + 1,
            },
            () => {
              this.mergeProduct();
            }
          );
        })
        .catch((err) => {
          return this.getDataProducts(param, query, foodCategory);
        });
    });
  }

  mergeProduct() {
    if (this.state.apiCallCount == 6) {
      let foods_eat_data = Object.assign(
        {},
        this.state.rs_readytoeat,
        this.state.cb_readytoeat
      );

      let foods_drink_data = Object.assign(
        {},
        this.state.rs_readytodrink,
        this.state.cb_readytodrink
      );

      let foods_cook_data = Object.assign(
        {},
        this.state.rs_readytocook,
        this.state.cb_readytocook
      );
      this.setState(
        {
          foods_eat: foods_eat_data,
          foods_cook: foods_cook_data,
          foods_drink: foods_drink_data,
        },
        () => {}
      );
    }
  }
  GetVisibility() {
    if (this.state.cart_PO.quantity !== null) {
      this.setState({
        ...this.state,
        visibility_po: false,
      });
    }
  }

  onCartPOUpdate(item, value) {
    if (this.state.cart.quantity != 0) {
      f7.dialog.alert("Please Finish Your Transaction First");
      // this.setState({ ...this.state, disable: true });
    } else {
      // UPDATE_CART_PO(item, value)
      //   .then((res) => {
      //     this.setState({ ...this.state, cart_PO: res });
      //   })
      //   .catch((err) => console.log("err", err));
    }
  }

  onCartUpdate(item, value) {
    // if (this.state.cart_PO.length != 0) {
    //   f7.dialog.alert("Please Finish Your Preorder Transaction First");
    //   // this.setState({ ...this.state, disable: true });
    // } else {
      UPDATE_CART_WITHFOOD(this.state.foods_data,item, value)
        .then((res) => {
          this.setState({ ...this.state, cart: res });
        })
        .catch((err) => console.log("err", err));
    // }
  }

  onClickAddToCart() {
    // if (!GET_TOKEN()) {
    //   SetRedirectToCheckOut(true);
    // }
    if(GET_CART().quantity>0){
      this.$f7router.navigate("/checkout/");
    }
  }

  fetchMoreData() {
    if (this.state.has_more) {
      this.fetchData(this.state.current_page + 1);
      this.setState({ current_page: this.state.current_page + 1 });
    }
    this.setState({
      has_more: this.state.current_page < this.state.total_pages,
    });
  }

  fetchData(page) {
    this.setState({ isLoading: true });
    // Load Search API
    f7.request.promise
      .get(
        API_URL +
          "/v1/pre-order/product/" +
          merchantId +
          "?limit=" +
          this.state.limit +
          "&" +
          "&page=" +
          page,
        null,
        "json"
      )
      .then((res) => {
        // console.log("res", res.data.data);
        this.setState({
          ...this.state,
          has_more: page < res.data.paginator.total_pages ? true : false,
          data: this.state.data.concat(res.data.data),
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ has_more: false, isLoading: false });
      });
    con;
  }

  render() {
    // var modal = [];
    // modal.push(
    //   <div className="modal" style={this.state.toggle ? display : hide}>
    //     <Share />
    //   </div>
    // );
    return (
      <Page
        style={{
          visibility: this.state.merchant ? "visible" : "hidden",
        }}
      >

{/* <Navbar backLink /> */}
<BlockHeader>

  <div className={styles["txt-merchant-name"]}>
    {this.state.merchant ? this.state.merchant.name : ""}
  </div>
   <div className={styles["txt-merchant-detail"]}>
     {this.state.merchant ? this.state.merchant.description : ""}
     </div>

   <div className={styles["txt-merchant-detail"]}>
     {this.state.merchant ? this.state.merchant.merchant_category.toString() : ""}
     </div>
</BlockHeader>
        {/* <div className={styles["header"]}> */}
          {/* <img
            className={styles["header-img"]}
            src={
              this.state.merchant
                ? FormatImage(this.state.merchant.photo, "thumb")
                : ""
            }
          /> */}
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            {/* <div style={{ display: "grid", width: "390px" }}> */}
              {/* <Button
                href={"/home/"}
                iconMd="material:arrow_back_ios"
                className={styles["header-btn"]}
              /> */}
              {/* <div className={styles["header-detail-main"]}>
                <div className={styles["header-detail"]}>
                  <div className={styles["header-detail-col-1"]}> */}
                    {/* <div className={styles["txt-merchant-name"]}>
                      {this.state.merchant ? this.state.merchant.name : ""}
                    </div> */}
                    {/* <div className={styles["txt-merchant-detail"]}>
                      <img src={IcoStar} className={styles["ico"]} />
                      4.7 - Great Taste!
                    </div>
                    <div className={styles["txt-merchant-detail"]}>
                      <img src={IcoDelivery} className={styles["ico"]} />
                      {this.state.merchant ? this.state.merchant.distance : ""}
                      {" Km - 10 min"}
                    </div> */}
                    {/* <div className={styles["txt-merchant-detail"]}>
                      <Icon
                        md="material:query_builder"
                        size="15px"
                        className={styles["ico"]}
                        color="blue"
                      />
                      {this.state.merchant ? this.state.merchant.is_open : ""}
                    </div> */}
                  {/* </div>
                  <div className={styles["header-detail-col-2"]}></div>
                </div>
              </div>
            </div> */}

            {/* <div style={{ flex: 1 }} /> */}
            {/* <BrowserView>
              <Button
                iconMd="material:share"
                className={styles["share-button"]}
                onClick={(event) => this.toggle(event)}
              >
                {modal}
              </Button>
            </BrowserView>
            <MobileView>
              <Button
                onClick={() => this.onClickShare()}
                iconMd="material:share"
                className={styles["header-btn"]}
              />
            </MobileView> */}
          {/* </div> */}
        {/* </div> */}


        <div className={styles["bodycontainer"]}>
        <MenuTab
              allData={this.state}
              cart={this.state.cart}
              onUpdateCart={(item, value) => this.onCartUpdate(item, value)}
              data={this.state.products}
              isOpen={this.state.merchant ? this.state.merchant.is_open : false}
              updateWishList={() => this.refreshProducts()}
            />

        </div>

        {/* <Toolbar noShadow tabbar>
          {this.state.table == 0 || this.state.table == null ? (
            <Link
              tabLink
              href="./"
              routeTabId="view-pre-order"
              className={styles["txt-tab"]}
              text="Pre Order"
            />
          ) : (
            ""
          )}
          <Link
            tabLink
            href="ready-to-eat/"
            routeTabId="view-ready-to-eat"
            className={styles["txt-tab"]}
            text="Ready To Eat"
          />
          {this.state.table == 0 || this.state.table == null ? (
            <Link
              tabLink
              href="ready-to-cook/"
              routeTabId="view-ready-to-cook"
              className={styles["txt-tab"]}
              text="Ready To Cook"
            />
          ) : (
            ""
          )}
          <Link
            tabLink
            href="ready-to-drink/"
            routeTabId="view-ready-to-drink"
            className={styles["txt-tab"]}
            text="Ready To Drink"
          />
        </Toolbar>
        <Tabs>
          <Tab className="page-content" id="view-ready-to-drink">
            {!GET_USER_PROFILE() || GET_USER_PROFILE().age <= 21 ? (
              <div className={styles["alert-drink"]}>
                <p className={styles["txt-notfound-1"]}>Attention</p>
                <p className={styles["txt-notfound-2"]}>
                  I hereby declare that, I'm over 21 years old
                  <br />
                  and mature enough to use this app to buy
                  <br />
                  and consume alcohol responsibily
                </p>
                {!GET_USER_PROFILE() ? (
                  <Button href="/onboarding/" className={styles["confirm-btn"]}>
                    Sure
                  </Button>
                ) : (
                  <Button
                    href="/edit-profile/"
                    className={styles["confirm-btn"]}
                  >
                    Sure
                  </Button>
                )}
              </div>
            ) : (
              <MenuTab
                cart={this.state.cart}
                allData={this.state}
                onUpdateCart={(item, value) => this.onCartUpdate(item, value)}
                data={this.state.foods_drink}
                isOpen={
                  this.state.merchant ? this.state.merchant.is_open : false
                }
                updateWishList={() => this.refreshProducts()}
              />
            )}
          </Tab>

          <Tab className="page-content" id="view-ready-to-eat">
            <MenuTab
              allData={this.state}
              cart={this.state.cart}
              onUpdateCart={(item, value) => this.onCartUpdate(item, value)}
              data={this.state.foods_eat}
              isOpen={this.state.merchant ? this.state.merchant.is_open : false}
              updateWishList={() => this.refreshProducts()}
            />
          </Tab>
          <Tab className="page-content" id="view-ready-to-cook">
            <MenuTab
              cart={this.state.cart}
              allData={this.state}
              onUpdateCart={(item, value) => this.onCartUpdate(item, value)}
              data={this.state.foods_cook}
              isOpen={this.state.merchant ? this.state.merchant.is_open : false}
              updateWishList={() => this.refreshProducts()}
            />
          </Tab>
          <Tab className="page-content" id="view-pre-order">
            <PreOrder
              // allData={this.state}
              // cart={this.state.cart_PO}
              // onUpdateCart={(item, value) => this.onCartUpdate(item, value)}
              // data={this.state.preOrder}
              // isOpen={this.state.merchant ? this.state.merchant.is_open : false}
              // updateWishList={() => this.refreshProducts()}
              // merchantId={this.props.id}
              data={this.state}
              merchantId={this.props.id}
              isOpen={this.state.merchant ? this.state.merchant.is_open : false}
              updateWishList={() => this.refreshProducts()}
              fetchMoreData={() => this.fetchMoreData()}
              onUpdateCartPO={(item, value) => this.onCartPOUpdate(item, value)}
            />
          </Tab>
        </Tabs> */}
        <div
          className="mk-footer"
          style={{
            visibility:
              this.state.cart.quantity
              // && this.state.cart_PO.quantity == 0
                ? "visible"
                : "hidden",
          }}
        >
          <ButtonCart
            quantity={this.state.cart.quantity}
            price={this.state.cart.total}
            text="Checkout"
            onClick={() => this.onClickAddToCart()}
          />
        </div>
        <div
          className="mk-footer"
          style={{
            visibility:
              this.state.cart.quantity == 0 && this.state.cart_PO.quantity !== 0
                ? "visible"
                : "hidden",
          }}
        >
          <ButtonCart
            quantity={this.state.cart_PO.quantity}
            price={this.state.cart_PO.total}
            text="Checkout"
            onClick={() => this.onClickAddToCart()}
          />
        </div>
      </Page>
    );
  }
}
