import React from "react";
import styles from "./styles.module.less";
import { IcoInboxEmpty } from "../../../../../assets/home";

export default () => {
  return (
    <div className={styles["container"]}>
      <img className={styles["img-background"]} src={IcoInboxEmpty} />
      <div className={styles["txt-style-1"]}>
        Oops! Your inbox is still empty
      </div>
      <div className={styles["txt-style-2"]}>
        When you have new messages,
        <br />
        you will see them here
      </div>
    </div>
  );
};
