import React, { useState, useRef } from "react";
import styles from "./styles.module.less";
import { Page,
  Navbar,
  Block,
  Button,
  BlockTitle,
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,Icon,
  f7, } from "framework7-react";



export default (props) => {
  const [actionGridOpened, setActionGridOpened] = useState(false);
let data = props.data;
let table = props.data.tables;

let lots_name;
//get value table name
 if(props.data){
    props.data.tables.map(v => {
      if(v.id == props.data.data.table_id){
        lots_name=v.lots_name
      }  
    })
 }

  //guest picker
  const options = [];
  function ChangeTable(val){
    props.onChangeTable(val);
  }

  return (
    <div style={{ margin: "20px" }}>
      <div className={styles["txt-title"]}>Dine in </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "11px",
        }}
      >
        {/* Date Picker */}
        <div
          style={{
            borderWidth: "0px 0px 1px 0px",
            borderColor: "black",
            borderStyle: "solid",
            paddingBottom: "8px",
            display: "flex",
            width: "190px",
            borderColor: "gray",
            justifyContent: "space-between"
          }}>
       <Button style={{width: "190px", textAlign: "left"}} onClick={() => {props.data.visibility == true? setActionGridOpened(true):""}}  >
            {props.data.data.table_id === 0 ? <div style={{color:"black"}}>Choose Table</div>: <div>{lots_name}</div>}
          </Button>
       <Actions
        grid={true}
        opened={actionGridOpened}
        onActionsClosed={() => setActionGridOpened(false)}
      >
        <ActionsGroup  className={styles["grid"]}>
        {props.data.tables.map(v => {
          return(
            <ActionsButton style={{border: "solid", width:"100%", margin:"20px"}} onClick={()=>ChangeTable(v.id)}>
            {/* <img
              slot="media"
              src="https://cdn.framework7.io/placeholder/fashion-96x96-6.jpg"
              width="48"
            /> */}
            <div style={{color: "red"}}>{v.lots_name}</div>
          </ActionsButton>
          )})}

        </ActionsGroup>
      </Actions>

          <Icon md="material:event_seat" style={{ color: "#e79d41" }} />
        </div>
        {/* Guest Drawer */}
        <div
          style={{
            borderWidth: "0px 0px 1px 0px",
            borderColor: "black",
            borderStyle: "solid",
            paddingBottom: "8px",
            display: "flex",
            width: "190px",
            borderColor: "gray",
            justifyContent: "space-between"
          }}
        >
          <input
            type="number"
            placeholder="1"
            style={{ width: "80px" }}
            onFocus={() => {}}
            
            
          />
          <Icon md="material:person" style={{ color: "#e79d41" }} />
        </div>
      </div>
    </div>
  );
};
