import React from "react";
import { Button, Page, Navbar, Searchbar } from "framework7-react";
import PreorderList from "./components/preorder-list";
import styles from "./styles.module.less";
import { API_URL } from "../../../js/global";
import { useLocation } from "react-router-dom";

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: this.props.s,
      sortByNearest: 0,
      sortByRating: 0,
      sortByPrice: 0,
      isLoading: true,
    };
  }
  
  onClickByNearest() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 1,
      sortByPrice: 0,
      sortByRating: 0,
    });
    this.$f7router.navigate("/bestseller/" + "near_by/" + s + "/", {
      animate: false,
    });
  }

  onClickByRating() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 0,
      sortByPrice: 0,
      sortByRating: 1,
    });
    this.$f7router.navigate("/bestseller/" + "rate/" + s + "/", {
      animate: false,
    });
  }

  onClickByPrice() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 0,
      sortByPrice: 1,
      sortByRating: 0,
    });
    this.$f7router.navigate("/bestseller/" + "cheapest/" + s + "/", {
      animate: false,
    });
  }

  componentDidMount() {
    const sortBy = this.props.sort_by ? this.props.sort_by : "near_by";
    const s = this.props.s ? this.props.s : "";
    switch (sortBy) {
      case "near_by":
        this.setState({ ...this.state, sortByNearest: 1 });
        break;
      case "rate":
        this.setState({ ...this.state, sortByRating: 1 });
        break;
      case "cheapest":
        this.setState({ ...this.state, sortByPrice: 1 });
        break;
    
    }
    
    this.$f7ready((f7) => {
      const prodId = this.props.id
      // const preoderId = this.props.preoderId
     
      f7.preloader.show();

      // Load Preorder API
      f7.request.promise
        .get(
          API_URL + "/v1/pre-order/merchant-list/"+ prodId +"?limit=10",
          null,
          "json"
        )
        .then(async(res) => {
          console.log("res", res.data.data);
              this.setState({ ...this.state, data: res.data.data }); 
              await this.cek();
              this.setState({
                ...this.state,
                isLoading: false,});
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  cek(){
    if (this.state.data.length == 1) {
      this.$f7router.navigate("/preorder/" + this.state.data[0].id + "/" + this.state.data[0].pre_order_id + "/")
      location.reload();
    }else{
      console.log(" ");
    }

  }


  onSubmitSearch() {
    this.$f7router.navigate("//" + "near_by/" + this.state.search + "/", {
      animate: false,
    });
  }

  render() {
    return (
      <Page pageContent name="search-page">
        {this.state.isLoading == true ?" ":
            <PreorderList 
              loading={this.state.isLoading}
              data={this.state.data}
              preorderId={this.props.preoderId}
            />}
      </Page>
    );
  }
}
