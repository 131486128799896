import React from "react";
import { Preloader, Page } from "framework7-react";
import {
  API_URL,
  GET_TOKEN,
  SET_TOKEN,
  COMING_SOON,
  UNDER_MAINTENANCE
} from "./../js/global";

export default class extends React.Component {
  componentDidMount() {
    this.$f7ready((f7) => {

      setTimeout(() => {
        if(COMING_SOON){
          this.$f7router.navigate("/comingsoon/",{
            reloadAll: true,
            clearPreviousHistory: true,
            animate:false
          });
        }
        else if(UNDER_MAINTENANCE){
          this.$f7router.navigate("/comingsoon/",{
            reloadAll: true,
            clearPreviousHistory: true,
            animate:false
          });
        }
        else {
          if((window.location.search.split("=").length)>1){
            if(window.location.search.split("=")[0]="?token"){
              console.log(window.location.search.split("=")[1]);
              f7.request.promise
                .postJSON(
                  API_URL +
                    "/v1/auth/single-login",
                    {
                      token: decodeURIComponent(window.location.search.split("=")[1]),
                    },
                  null
                )
                .then((res) => {
                  console.log(res);
                  SET_TOKEN(res.data.token);
                  this.$f7router.navigate("/home/",{
                    reloadAll: true,
                    clearPreviousHistory: true,
                    animate:false
                  });
                })
                .catch((err) => {
                  this.$f7router.navigate("/onboarding/",{
                    reloadAll: true,
                    clearPreviousHistory: true,
                    animate:false
                  });
                });
            }
            else {
              this.$f7router.navigate("/onboarding/",{
              reloadAll: true,
              clearPreviousHistory: true,
              animate:false
      });
            }
          }
          else {
            if(GET_TOKEN()){
              this.$f7router.navigate("/home/",{
                reloadAll: true,
                clearPreviousHistory: true,
                animate:false
              });
            }
            else {
              this.$f7router.navigate("/onboarding/",{
                reloadAll: true,
                clearPreviousHistory: true,
                animate:false
              });
            }
          }
        }
      },100)
    });
  }

  render() {
    return <Page></Page>;
  }
}
