import React from "react";
import { Page, Button, Input, List,f7 } from "framework7-react";
import { IcoLove, IcoPlusOval, IcoMinusOval } from "../../../assets/home";
import { API_URL,GET_TOKEN,FormatImage } from "../../../js/global";
import { UPDATE_CART, SHOW_CART2,INDEX_CART_PO } from "../../../js/cart2";
import { IncreaseDecrease, ButtonCart, Share} from "../../../components/customs";
import styles from "./styles.module.less";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      cart_PO:[],
      product:null,
      data: null,
      method: props.type,
      toggle: false,
      is_wishlist:false,
      disable:false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));
  }

  onClickShare() {
    navigator
      .share({
        title: "Frodo",
        text:
          "Check out Frodo! Delicious food from " + this.state.merchant.name,
        url: window.location.href,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }

  onChangeNotes(value) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        notes: value,
      },
    });
  }

  handleChange(value) {
    console.log(this.state.data)
    this.setState({
      ...this.state,
      method:value,
    });
    
  }
  
  addWhishlist() {
    if (!GET_TOKEN()) {
      this.$f7router.navigate("/onboarding/");
    }
    else {
      f7.request
      .promise({
        url: API_URL + "/v1/wishlist/add",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          product_id: this.state.data.item.id,
        },
      })
      .then((res) => {
        console.log("res", res);
        this.setState({
          ...this.state,
          data: { ...this.state.data, is_wishlist: true },
          is_wishlist: true
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
    }
 }

  deleteWhishlist() {
    if (!GET_TOKEN()) {
      this.$f7router.navigate("/onboarding/");
    }
    else {
      f7.request
        .promise({
          url: API_URL + "/v1/wishlist/delete?product_id=" + this.state.data.item.id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data: null,
        })
        .then((res) => {
          this.setState({
            ...this.state,
            data: { ...this.state.data, is_wishlist: false },
            is_wishlist:false
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }

  onChangeQty(value) {
    const item = this.state.data.item;
    if(this.state.cart_PO.quantity!=0){
      f7.dialog.alert("Please Finish Your Preorder Transaction First");
    }else{
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          qty: value,
          price: value * item.price,
        },
      });
    }
    
  }

  onClickAddToCart() {
    if (this.state.data.qty == 0) {
      this.$f7.dialog.alert("Please add an item.");
    }else if(this.state.data.qty > this.state.product.stock){
      this.$f7.dialog.alert("stock is not enough.");
    }
    // else if(!this.state.method){
    //   this.$f7.dialog.alert("Please chose your method")
    // } 
    else {
      UPDATE_CART(this.state.product, this.state.data.qty, this.state.data.notes);
      this.$f7router.navigate(
        "/merchants/" + this.state.cart_PO.merchant_id + "/"
      );
    }
  }

  componentDidMount() {
    const foodId = this.props.id;
    const foodtype = this.props.type === 'ready-stock' ? "product" : "combo";

    this.$f7ready((f7) => {
      f7.preloader.show();
      
      //Load Products API
      f7.request.promise({
        url: API_URL + "/v1/" + foodtype +"/" + foodId ,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
        .then(async (res) => {
          let data = res.data.data;
          this.setState({
            is_wishlist: data.is_wishlist,
            product: res.data.data
          });

          await SHOW_CART2(data, this.props.serve_as)
            .then((res) => (data = res))
            .catch((err) => console.log("cart_err", err));
            this.setState({
              ...this.state,
              data:data,
            });
            const merchantID = this.state.data.item.merchant_id;

            let cart_PO;
            // await INDEX_CART_PO(merchantID)
            // .then((res) => (cart_PO = res))
            // .catch((err) => console.log("cart_err", err));

          this.setState({
            ...this.state,
            cart_PO:cart_PO,
            method : data.item.serve_as
          });
         
          // if(this.state.cart_PO.quantity!==0){
          //   this.setState({
          //     ...this.state,
          //     disable:true,
          //   });
          //   f7.dialog.alert("Please Finish Your Preorder Transaction First");
          // }
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
 texting (){ 
    if (this.props.serve_as == "readytoeat") {
      return "Ready to Eat"
    }else if (this.props.serve_as == "readytocook") {
      return "Ready to Cook"
    }else if (this.props.serve_as == "readytodrink") {
      return "Ready to Drink"
    }else{
      return "Other"
    }
  }

  render() {
    // var modal = [];
    // modal.push(
    //   <div className="modal" style={this.state.toggle ? display : hide}>
    //   <Share/>
    //   </div>
    // );
    return (
      <Page>
        {!this.state.data ? null : (
          <>
          <div>
            <div className={styles["header"]}>
              <img
                className={styles["header-img"]}
                src={FormatImage(this.state.product.photo,"detail")}
              />
              <Button
                // href={"/merchants/" + this.state.data.item.merchant_id + "/"}
                back
                iconMd="material:arrow_back_ios"
                className={styles["header-btn"]}
              />
              
              <div style={{ flex: 1 }} />
              {/* <Button
                onClick={() => this.onClickShare()}
                iconMd="material:share"
                className={styles["header-btn"]}
              /> */}
              {/* <BrowserView>
              <Button
                iconMd="material:share" 
                className={styles["share-button"]}
                onClick={(event) => this.toggle(event)}>
                  {modal}
              </Button>
            </BrowserView>
            <MobileView>
              <Button
                onClick={() => this.onClickShare()}
                iconMd="material:share"
                className={styles["header-btn"]}
              />
            </MobileView> */}
              
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 20px 0px 20px",
                filter:
                  this.state.product.is_open == "closed" ? "grayscale(100%)": ""}}
            >
              <div className={styles["food-col-1"]}>
                <div style={{
                display: "flex",
                justifyContent:"space-between",
                width:"100%",
                alignItems:"baseline",
                  }}>
                  <div className={styles["txt-food-name"]}>
                    {this.state.product.name}
                  </div>
                  {/* <a
                    onClick={() =>
                      !this.state.is_wishlist
                        ? this.addWhishlist()
                        : this.deleteWhishlist()
                    }
                    style={{
                      filter: this.state.is_wishlist
                        ? ""
                        : "grayscale(100%)",
                      zIndex: "50",
                    }}
                  >
                    <img src={IcoLove} />
                  </a> */}
                </div>
                <div className={styles["txt-food-detail"]}>
                  {this.state.product.description}
                </div>
                <div style={{
                display: "flex",
                justifyContent:"space-between",
                width:"100%"
                  }}>
                  <div className={styles["txt-food-price"]}>
                    Rp. {Intl.NumberFormat().format(this.state.product.price)}
                  </div>
                  {this.state.product.stock == 0 || this.state.product.is_open== "closed"  ? "":  
                  <IncreaseDecrease
                    defaultValue={this.state.data.qty}
                    onChange={(value) => this.onChangeQty(value)}
                    disable={this.state.product.stock==0 || this.state.product.is_open != "open" || this.state.disable}
                  />
                  }
                </div>
                
                {(this.state.product.type == "combo" )?
                    <div className={styles["txt-food-detail"]}>
                    {
                    this.state.product.food_category.map(item => {
                      return (
                        <div className={styles["txt-food-detail"]}>
                          {item.value_string}
                        </div>
                      )
                    })}
                  </div> : ""}
              </div>
            </div>
            <div className="mk-line-break medium"></div>
            <List style={{ margin: "20px 20px 0px 20px" }}>
              <div className={styles["txt-label"]}>Note</div>
              <Input type="text" placeholder="Type your note here"
                defaultValue={this.state.data.item.notes}
                onChange={(value) => this.onChangeNotes(value.target.value)}>
              </Input>
            </List>
            </div>
            <div
              className="mk-footer"
              style={{
                display:
                  this.state.product.is_open == "open" ? "block" : "none",
                position: "relative",
                  
              }}
            >
              <ButtonCart
                quantity={this.state.data.qty}
                price={this.state.data.price}
                notes={this.state.data.notes}
                product_category={this.state.data.product_category}
                text="Add to cart"
                onClick={() => this.onClickAddToCart()}
              />
            </div>
          </>
        )}
      </Page>
    );
  }
}