import React from "react";
import styles from "./styles.module.less";
import { API_URL,FormatImage } from "../../../../../js/global";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      // Load Foods API
      f7.request.promise
        .get(
          API_URL + "/v1/product?limit=5",
          null,
          "json"
        )
        .then((res) => {
          const data = res.data.data;
          this.setState({ data: data, isLoading: false });
          this.props.onLoaded(true);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.onLoaded(false);
        });
    });
  }

  render() {
    return (
      <div className="mk-horizontal-scroll">
        {this.state.data.map((item, index) => {
          return (
            <a href={"/promo/" + item.id + "/"}>
              {item.price_before==null ? (
                <div>
                  </div>
                  ) : (
              <div className={styles["container"]}>
                <div className={styles["img-product"]}>
                  <img className={styles["img-product"]} src={FormatImage(item.photo,"thumb")} />
                  {/* <div className={styles["txt-merchant-name"]}>
                    {item.merchant}
                  </div> */}
                </div>
                <div className={styles["container-detail"]}>
                  <div className={styles["container-subdetail"]}>
                    <div className={styles["txt-name-up"]}>Discount</div>
                    <div className={styles["txt-name-down"]}>Up to</div>  
                  </div>
                  <div width className={styles["txt-product-price"]}>
                  {Math.round(((item.price_before - item.price) / item.price_before)*100)}%
                  </div>
                </div>
              </div>
              )}
            </a>
          );
        })}
      </div>
    );
  }
}
