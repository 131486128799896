import React from "react";
import Address from "./components/address";
import { ImgBackground } from "../../../assets/auth";

import { IcoProfile } from "../../../assets/profile";
import { Button, List, Input, Page, Icon,Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  f7, } from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN } from "../../../js/global";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiver_name: "",
      receiver_phone: "",
      address:"",
      zip_code: "",
      country:"",
      province:"",
      city:"",
      subdistrict:"",
      latitude:"",
      longitude:"",
      id:"",
      data: {
        addresses: { 
          details: "",
          country:"",
          province:"",
          city:"",
          subdistrict:"",
          latitude:"",
          longitude:"",
      },
    }
    };
  }
  
  componentDidMount() {
    const merchantId = this.props.merchantId;
    const prodId = this.props.id
    this.$f7ready((f7) => {

      //Load Products API
      f7.request
        .promise({
          url: API_URL + "/v2/profile/address/"+prodId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then(async (res) => {
          let item = res.data.data;
          this.setState({ 
            ...this.state,
          data:item,
          id:prodId,
          });
          
          f7.preloader.hide();
      
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  onClickEdit() {
    const data = this.state;
    const app = this;
    const prodId = this.props.id
    this.onUpdateAddress(); 
    // this.setState({
    //   ...this.state,
    //   data: {
    //     ...this.state,
    //    longitude:this.state.data.addresses.longitude,
    //    latitude: this.state.data.addresses.latitude,
    //   },
    // });
    app.$f7router.app.preloader.show();
    app.$request
      .promise({
        url: API_URL + "/v2/profile/address/"+prodId,
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: this.state.data,
      })
      .then((res) => {
        console.log("res", res);
        app.$f7router.app.preloader.hide();
        
        
        app.$f7.dialog.alert("Address updated!", () =>
          app.$f7router.navigate("/address/")
        );
        
      })
      .catch((err) => {
        console.log("err", err);  
        app.$f7router.app.preloader.hide();
        
      });
  }
  onUpdateName(value){
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        receiver_name: value,
      },
    });
    
  }
  onUpdatePhone(value){
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        receiver_phone: value,
      },
    });
  }
  onUpdateZip(value){
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        zip_value: value,
      },
    });
  }
  onUpdatesAddress(value){
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        address: value,
      },
    });
  }

  onSaveAddress(){
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        address: this.state.data.addresses.details,
        longitude:this.state.data.addresses.longitude,
        latitude:this.state.data.addresses.latitude,
      },
    });
 
  }
  onUpdateAddress(){
    this.setState({
      ...this.state,
       latitude:this.state.data.addresses.latitude,
       longitude:this.state.data.addresses.longitude,
       country:this.state.data.addresses.country,
       city:this.state.data.addresses.city,
       province:this.state.data.addresses.province,
       subdistrict:this.state.data.addresses.subdistrict,
      //  address:this.state.data.addresses.details,
    }) 
   }

  onChangeAddress(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        addresses: { ...val },
      },
    });
   
    this.onSaveAddress();
    
  }
  render() {
    return (
      <Page name="login">
          <img className={styles["img-background"]} src={ImgBackground} />
          <Button
            back
            iconMd="material:arrow_back_ios"
            className={styles["btn-back"]}
          />
          <div style={{ flex: 1 }}>
            <div className={styles["oval"]}>
              <Icon
                size="70px"
                md="material:people"
                className={styles["ico"]}
              />
            </div>
            <div className={styles["title"]}> Edit Address</div>
            <div className={styles["input-form"]}>
              <Address 
              onChangeAddress={(val) => this.onChangeAddress(val)}
              data={this.state ? this.state : []}/>
              <List>
              <div
                  className={styles["txt-label"]}
                  
                >
                  Address
                </div>
                <Input
                  type="text"
                  placeholder="Input your Address"
                  defaultValue={this.state.data.address}
                  required
                  validate
                  validateOnBlur
                  onChange={(value) =>this.onUpdatesAddress(value.target.value)}
                ></Input>
                <div className={styles["txt-label"]} style={{ marginTop: "25px" }}>Name</div>
                <Input
                  type="text"
                  placeholder="Input your name"
                  defaultValue={this.state.data.receiver_name}
                  required
                  validate
                  validateOnBlur
                  onChange={(value) =>this.onUpdateName(value.target.value)}
                ></Input>
                <div
                  className={styles["txt-label"]}
                  style={{ marginTop: "25px" }}
                >
                  Phone
                </div>
                <Input
                  type="text"
                  placeholder="Input your phone number"
                  defaultValue={this.state.data.receiver_phone}
                  required
                  validate
                  validateOnBlur
                  onChange={(value) =>this.onUpdatePhone(value.target.value)}
                ></Input>
                {/* <div
                  className={styles["txt-label"]}
                  style={{ marginTop: "25px" }}
                >
                  Zip Code
                </div>
                <Input
                  type="text"
                  placeholder="Input your Zip Code"
                  defaultValue={this.state.data.zip_code}
                  required
                  validate
                  validateOnBlur
                  onChange={(value) =>this.onUpdateZip(value.target.value)}
                ></Input> */}
            
              </List>
            </div>
          </div>
          
          <div className={styles["mk-footer"]}>
                        <Button className={styles["btn-next"]} onClick={() => this.onClickEdit()}>
                            Save Address
                        </Button>

                    </div>

          {/* <div style={{ width: "100%" }}>
            <Button
              onClick={() => this.onClickEdit()}
              fill
              className={styles["btn-next"]}
            >
              Save Address
            </Button>
          </div> */}
      </Page>
    );
  }
}
