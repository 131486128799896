import OnBoarding from "../pages/onboarding";
import ComingSoon from "../pages/comingsoon";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import ForgotPassword from "../pages/auth/forgot-password";
import ForgotPasswordConfirm from "../pages/auth/forgot-password-confirm";
import ForgotPasswordReset from "../pages/auth/forgot-password-reset";

import HomeMain from "../pages/home/main";
import PromoList from "../pages/home/promo-list";
import Promo from "../pages/home/promo";
import Merchants from "../pages/home/merchant";
import Category from "../pages/home/category";
import Categories from "../pages/home/categories";
import PreOrderPage from "../pages/home/preorder";
import BestSeller from "../pages/home/bestseller";
import ReadyToCook from "../pages/home/ready-to-cook";
import ReadyToDrink from "../pages/home/ready-to-drink";
import ReadyToEat from "../pages/home/ready-to-eat";
import Inbox from "../pages/home/inbox";
import Voucher from "../pages/home/voucher";
import Qr_scanner from "../pages/home/qr-scanner";
import ScanTable from "../pages/home/scan-table";
import Favorite from "../pages/home/favorite";
import SearchPage from "../pages/home/search";

import ClientPage from "../components/toolbar";

import MerchantMain from "../pages/merchant/main";
import Food from "../pages/merchant/food-detail";
import Combo from "../pages/merchant/combo-detail";
import PreOrder from "../pages/merchant/preorder-detail";
import PreOrderList from "../pages/merchant/preorder-list";
import Checkout from "../pages/merchant/checkout";
import PaymentMethod from "../pages/merchant/payment-method";
import BankInfo from "../pages/merchant/bank-info";
import Password from "../pages/merchant/password";

import Transaction from "../pages/transaction/main";
import TransactionDetail from "../pages/transaction/transaction-detail";

import ProfileMain from "../pages/profile/main";

import About from "../pages/profile/about";
import Contact from "../pages/profile/contact";
import FAQ from "../pages/profile/FAQ";
import Privacy from "../pages/profile/privacy";
import EditProfile from "../pages/profile/edit-profile";
import MyVoucher from "../pages/profile/my-voucher";
import Settings from "../pages/profile/settings";
import ChangePassword from "../pages/profile/change-password";
import ChangePIN from "../pages/profile/change-pin";

import NewAddress from "../pages/profile/new-address";
import Maps from "../pages/profile/new-address/components/maps";

import EditAddress from "../pages/profile/edit-address";
import Address from "../pages/profile/address";

import Main from "../pages";
import maps from "../pages/profile/new-address/components/maps";
import { LoginScreen } from "framework7-react";

var routes = [
  // Redirect to home
  {
    path: "/",
    component: Main,
  },

  // Profile
  // {
  //   path: "/privacy/",
  //   component: Privacy,
  // },
  // {
  //   path: "/faq/",
  //   component: FAQ,
  // },
  // {
  //   path: "/about/",
  //   component: About,
  // },
  // {
  //   path: "/contact/",
  //   component: Contact,
  // },
  // {
  //   path: "/edit-profile/",
  //   component: EditProfile,
  // },
  // {
  //   path: "/my-voucher/",
  //   component: MyVoucher,
  // },
  // {
  //   path: "/settings/",
  //   component: Settings,
  // },
  // {
  //   path: "/change-password/",
  //   component: ChangePassword,
  // },
  // {
  //   path: "/change-pin/",
  //   component: ChangePIN,
  // },
  // {
  //   path: "/new-address/:lng/:lat/:address",
  //   component: NewAddress,
  // },
  // {
  //   path: "/maps/",
  //   component: Maps,
  // },
  // {
  //   path: "/edit-address/:id",
  //   component: EditAddress,
  // },
  // {
  //   path: "/address/",
  //   component: Address,
  // },

  // On-Boarding
  {
    path: "/onboarding/",
    component: OnBoarding
  },

  //Coming Soon
  {
    path: "/comingsoon/",
    component: ComingSoon
  },

  // Auth
  // {
  //   path: "/login/",
  //   component: Login,
  // },
  // {
  //   path: "/register/",
  //   component: Register,
  // },
  // {
  //   path: "/forgot-password/",
  //   component: ForgotPassword,
  // },
  // {
  //   path: "/new-password/",
  //   component: ForgotPasswordConfirm,
  // },
  // {
  //   path: "/forgot-password-reset/",
  //   component: ForgotPasswordReset,
  // },
  // {
  //     path: "/transaction-list/",
  //       component: Transaction,
  //     },
  // Home
  // {
  //   path: "/promos/",
  //   component: PromoList,
  // },
  // {
  //   path: "/promo/:id/",
  //   component: Promo,
  // },
  // {
  //   path: "/merchants/",
  //   component: Merchants,
  // },
  // {
  //   path: "/merchantslist/:sort_by?/:s?/" ,
  //   component: Merchants
  // },
  {
    path: "/categories/",
    component: Categories,
  },
  // {
  //   path: "/qr-scanner/",
  //   component: Qr_scanner,
  // },
  {
    path: "/categories/:id/:category/:status/:sort_by?/:s?/",
    component: Category,
  },
  // {
  //   path: "/inbox/",
  //   component: Inbox,
  // },
  // {
  //   path: "/voucher/",
  //   component: Voucher,
  // },

  // {
  //   path: "/preorderpage/:sort_by?/:s?/" ,
  //   component: PreOrderPage
  // },

  // {
  //   path: "/ready-to-cook/:sort_by?/:s?/" ,
  //   component: ReadyToCook
  // },
  // {
  //   path: "/ready-to-drink/:sort_by?/:s?/" ,
  //   component: ReadyToDrink
  // },
  // {
  //   path: "/ready-to-eat/:sort_by?/:s?/" ,
  //   component: ReadyToEat
  // },
  // {
  //   path: "/bestseller/:sort_by?/:s?/" ,
  //   component: BestSeller
  // },
  

  // {
  //   path: "/favorites/",
  //   component: Favorite,
  // },

  // {
  //   path: "/search/:sort_by?/:s?/",
  //   component: SearchPage,
  // },
  {
    path: "/transaction-list/",
    component: Transaction,
  },

  // Main toolbar
  {
    path: "/home/",
    component: ClientPage,
    tabs: [
      {
        path: "/",
        id: "view-home",
        component: Merchants,
      },
      {
        path: "/transactions/",
        id: "view-transaction",
        component: Transaction,
      },
      // {
      //   path: "/profile/",
      //   id: "view-profile",
      //   component: ProfileMain,
      // },
    ],
  },

//EWallet
// {
//   path: "/ewallet/",
//   component: Ewallet,
  // tabs: [
  //   {
  //     path: "/",
  //     id: "view-topup",
  //   },
  //   {
  //     path: "/wallet-history/",
  //     id: "view-history",
  //   },
  //   {
  //     path: "/wallet-transfer/",
  //     id: "view-transfer",
  //   },
  // ],
// },
// {
//   path: "/payment-wallet/:amount?/:method?/",
//   component: PaymentWallet,
// },
// {
//   path: "/wallet-history/",
//   component: WalletHistory,
// },
// {
//   path: "/wallet-refund/",
//   component: WalletRefund,
// },
// {
//   path: "/wallet-transfer/",
//   component: WalletTransfer,
// },
// {
//   path: "/transfer-review/:amount?/:phone?/",
//   component: TransferReview,
// },
// {
//   path: "/transfer-confirm/:amount?/:phone?/:notes?",
//   component: TransferConfirm,
// },


  // Transaction
  {
    path: "/transactions/:id/",
    component: TransactionDetail,
  },

  // Merchant
  {
    path: "/merchants/:id/",
    component: MerchantMain,
    // tabs: [
    //   {
    //     path: "/ready-to-eat/",
    //     id: "view-ready-to-eat",
    //     // component: ReadyToEat,
    //   },
    //   {
    //     path: "ready-to-cook/",
    //     id: "view-ready-to-cook",
    //     // component: Blank,
    //   },
    //   {
    //     path: "/",
    //     id: "view-pre-order",
    //     // component: Blank,
    //   },
    //   {
    //     path: "/ready-to-drink/",
    //     id: "view-ready-to-drink",
    //     // component: Blank,
    //   },
    // ],
  },
  {
    path: "/payment-method/",
    component: PaymentMethod,
  },
  {
    path: "/transaction-list/",
    component: Transaction,
  },
  // {
  //   path: "/foods/:type/:id/:serve_as?/",
  //   component: Food,
  // },
  // {
  //   path: "/combo/:id/",
  //   component: Combo,
  // },
  // {
  //   path: "/bank-info/:id/",
  //   component: BankInfo,
  // },
  // {
  //   path: "/bank-wallet/:id/",
  //   component: BankWallet,
  // },
  // {
  //   path: "/password/:id/",
  //   component: Password,
  // },
  // {
  //   path: "/success-transfer/",
  //   component: SuccessTransfer,
  // },
  // {
  //   path: "/preorder/:merchantId/:preorderId/",
  //   component: PreOrder,
  // },
  // {
  //   path: "/dine-in/scan-table/:id/",
  //   component: ScanTable,
  // },

  // {
  //   path: "/preorderlist/:id",
  //   component: PreOrderList,
  // },
  {
    path: "/checkout/",
    component: Checkout,
    tabs: [
      // {
      //   path: "/",
      //   id: "view-delivery",
      // },
      {
        path: "/",
        id: "view-pickup",
      },
      // {
      //   path: "/dinein/",
      //   id: "view-dinein",
      // },

    ],
    
  },
  
];

export default routes;
