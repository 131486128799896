import React from "react";
import styles from "./styles.module.less";
import IcoUpload from "../../../../../assets/home";
import { API_URL, GET_TOKEN } from "../../../../../js/global";
import { Button } from "framework7-react";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      status_code:null,
      data: {
        balance:0,
        full_name:"",
      },
    };
  }

  GetAsset(){
     this.$f7ready(async (f7) => {
      f7.preloader.show();
      await f7.request
      .promise({
        url: API_URL + "/v1/asset",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
        .then(async (res) => {
          let data = res.data.data;
          this.setState({
            ...this.state,
            data: data,
            status_code:res.data.data.status_code,
            balance:res.data.data.balance,
            full_name:res.data.data.full_name,

          });
          f7.preloader.hide();
          
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  
  
  componentDidMount() {
    this.$f7ready(async (f7) => {
      f7.preloader.hide();
      await f7.request
        .promise({
          url: API_URL + "/v1/asset",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then(async (res) => {
          const data = res.data.data;
          
          // Set State
          this.setState({
            ...this.state,
            data: data,
            status_code:res.data.data.status_code,
            balance:res.data.data.balance,
            full_name:res.data.data.full_name
          });
        })
        .catch((err) => {
          console.log("err", err);
        });

      f7.preloader.hide();
    });
  }

  onCLickActivate(){
    this.$f7ready(async (f7) => {
      f7.preloader.show();
      await f7.request
      .promise({
        url: API_URL + "/v1/asset/activate",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
        .then(async (res) => {
          console.log(res);
          let data = res.data.data;
          this.setState({
            ...this.state,
            data: data,
            status_code:200
          });
          this.GetAsset()
          f7.preloader.hide();
          
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  
  

  render() {
    return (
     <div className={styles["main-wrap"]}>
       <div className={styles["inner-wrap"]}>
        <div className={styles["title-txt"]}>
          Frodo Pay 
        </div>
        <div className={styles["value-txt"]}>
          Rp {Intl.NumberFormat().format(this.state.balance)}
        </div>
       </div>
       
       {this.state.data.full_name == "" ?<Button href="/change-pin/" className={styles["topup-btn"]} >
          Activate
       </Button> : <Button href="/ewallet/" className={styles["topup-btn"]}>
         {/* <img src={IcoUpload}/> */}
            Top-Up
       </Button> }
     </div>
    );
  }
}
