import {API_URL, GET_TOKEN} from "./global";

export const GET_PRODUCT = (type ='product', merchantId= '', category='') =>
    new Promise((result, error) => {
        REQUEST('GET', type, {
        'limit': 100,
        'm' : merchantId,
        'category' : category
    })
    .then((res) => res.json())
    .then((res) => {
        let tempData =[]
        const data = res.data
        for(var key in data){
            tempData.push({...data[key],'serve_as' : category})
        }
        result(tempData)
    })
    .catch((err) => error(err))
}) 

export const REQUEST = (method = 'GET', param, query = {}, data =[]) => 
    new Promise((result, error) => {
        fetch(API_URL + "/v1/" + param + '?' + new URLSearchParams(query), {
            method : method,
            data : data,
            cache : 'no-cache',
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : GET_TOKEN() ?? null
            }
        })
        .then((res) => {
            result(res)
        })
        .catch((err) => error(err))
    })

export const GET_PO_PRODUCT = (merchantId= '') =>
    new Promise((result, error) => {
        PO_REQUEST('GET', merchantId)
    .then((res) => res.json())
    .then((res) => {
        let tempData =[]
        const data = res.data
        for(var key in data){
            tempData.push({...data[key]})
        }
        result(tempData)
    })
    .catch((err) => error(err))
}) 

export const PO_REQUEST = (method = 'GET', merchantId, data =[]) => 
    new Promise((result, error) => {
        fetch(API_URL + "/v1/pre-order/product/" +  merchantId + "?limit=100" , {
            method : method,
            data : data,
            cache : 'no-cache',
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : GET_TOKEN() ?? null
            }
        })
        .then((res) => {
            result(res)
        })
        .catch((err) => error(err))
    })
