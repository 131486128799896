import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  f7,
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
  Icon,
  List,
  Input,
} from "framework7-react";
import styles from "./styles.module.less";
import GoogleSearchLocation from "../../../../../components/customs/google-search-location";
import {
  GET_USER_ADDRESS,
  SET_USER_ADDRESS,
  API_URL,GET_TOKEN
} from "../../../../../js/global";

export default (props) => {
  const [geoLocation, setGeoLocation] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const googleSearchRef = useRef(null);

  let data=props.data.data.addresses;
 


// if(props.data.data.addresses.length != 0){
// }
 useEffect(() => {
    setTimeout(() => {
      getLocation();
      loadAddresses();
    }, 100);
  }, []);

 function loadAddresses() {
     f7.request
       .promise({
         url: API_URL + "/v2/profile/address",
         method: "GET",
         contentType: "application/json",
         dataType: "json",
         headers: {
           Authorization: GET_TOKEN(),
         },
       })
       .then((res) => {
         const data = res.data.data;
         const userAddress = GET_USER_ADDRESS();
         for (var key in data) {
           if (data[key].default === 1)
           {
            // SET_USER_ADDRESS(data[key]);
            setCurrentLocation(userAddress.address);
            props.onChangeAddress(data[key])
           }
         }
         f7.preloader.hide();
       })
       .catch((err) => {
         // console.log("err", err);
         f7.preloader.hide();
       });
   }

  function getLocation() {
    if(props.data.data.addresses){
        }
    // if (GET_USER_ADDRESS()) {
    //   // setCurrentLocation(GET_USER_ADDRESS().details);
    //   props.onChangeAddress(GET_USER_ADDRESS());
    // }
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      f7.dialog.alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    f7.request
      .promise({
        method: "GET",
        url:
          API_URL +
          "/v1/geocode/longlat?location=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&radius=500&type=address",
        dataType: "json",
        crossDomain: true,
      })
      .then((res) => {
        const location =
          res.data.results.length > 0 ? res.data.results[0].name : null;
        setGeoLocation(location);

        if (!GET_USER_ADDRESS()) setCurrentLocation(location);
      })
      .catch((err) => console.log("err", err));
  }

  function moveAddress(){
    props.addButton();
    googleSearchRef.current.close();
  }

  function onUpdateLocation(address) {
    console.log("curr address", address);
    if (address) {
      setCurrentLocation(address.address);
      SET_USER_ADDRESS(address);
      props.onChangeAddress(address);
      props.onChangeDeliveryStatus();
      setTimeout(() => {
        googleSearchRef.current.close();
      }, 100);
    }
  }
  function onUpdateLocationSearch(address) {
    if (address) {
      setCurrentLocation(address.details);
      SET_USER_ADDRESS(address.details);
      props.onChangeAddress(address.details);
      props.onChangeDeliveryStatus();
      setTimeout(() => {
        googleSearchRef.current.close();
      }, 100);
    }
  }

  return (
    <div style={{margin: "0px 20px 0px 20px" }}>
      <div
        onClick={() => googleSearchRef.current.open()}
        style={{  marginTop: "15px", display: "flex", cursor: "pointer" }}>
        <div style={{ flex: 1 }}>
          <div className={styles["txt-style-1"]}>Deliver to</div>
          <div className={styles["txt-style-2"]}>
            {currentLocation ? currentLocation : "Please choose your location"}
          </div>
        </div>
        <div style={{ alignSelf: "center", flexShrink: 0 }}>
          <Icon md="material:location_on" color="black" />
        </div>
      </div>
      <List>
        <Input
          onChange={(val) => props.onChangeNote(val.target.value)}
          type="text"
          placeholder="Add notes to delivery location"
        />
      </List>
      <Actions ref={googleSearchRef}>
        {/* <div style={{height:"500px", position: "inherit"}}> 
        </div> */}
        <ActionsGroup
          style={{
            height: "500px",
            backgroundColor: "white",
            borderRadius: "50px 50px 0px 0px",
            display: "flex",
            overflowY:"scroll"
          }}
        >
          <div style={{ width: "100%", margin: "15px 30px 0px 30px" }}>
            <div className={styles["rectangle"]} />
            <div className={styles["header-address"]}>
              <div className={styles["txt-deliver-to"]}>Deliver to</div>
              <Button className={styles["button-add"]} onClick={() => moveAddress()}>
              Add
              </Button>
            </div>
            <GoogleSearchLocation
              style={{ marginTop: "20px" }}
              onPlaceSelect={(address) => onUpdateLocationse(address)}
            />
            {/* <div
              style={{
                display: "flex",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => onUpdateLocation({ details: geoLocation })}
            >
              <div className={styles["current-button"]}>
                <Icon md="material:location_on" style={{ marginRight: "10px" }} />
                
                <div>
                  <div className={styles["txt-use-your-location"]}>
                    Use your current location
                  </div>
                  <div className={styles["txt-detail-current-location"]}>
                    {geoLocation ? geoLocation : "Please enable your location"}
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles["txt-your-location"]}>
            <Icon md="material:location_on" style={{ marginRight: "10px" }} />
            <div className={styles["txt-use-your-location"]}>Use your Address</div>
            </div>
           {
                data.length > 0 &&
                data[0].map(address => {
                return(
                <div className={styles["address-wrapper"]} onClick={() =>onUpdateLocation(address)}>
                <div className={styles["txt-use-your-location"]}>
                  {address.receiver_name}
                </div>
                <div className={styles["txt-detail-current-location"]}>
                  {address.receiver_phone}
                </div>
                <div className={styles["txt-detail-current-location"]}>
                  {address.address}
                </div>
              </div>
              );
            })}
            
          </div>
        </ActionsGroup>
      </Actions>
    </div>
  );
};
