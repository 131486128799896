import React from "react";
import styles from "./styles.module.less";
import moment from "moment";
import { Button, Page, Navbar, Searchbar } from "framework7-react";
import { IcoDelivery, IcoStar } from "../../../../../assets/home";
import {FormatImage  } from "../../../../../js/global";

export default (props) => {
  console.log("props", props);
  return (
    <div>
      {props.data.map((item, index) => {
        return (
          <a href={item.stock == 0 || item.is_open== "closed"  ? " ": "/foods/" + item.type + "/" + item.id + "/" }>
            <div
              style={{
                filter: item.stock == 0 || item.is_open== "closed"  ? "grayscale(100%)" : "",
              }}
              >
              <div className={styles["merchant-item"]}>
                <div style={{ display: "flex", marginBottom: "14.5px" }}>
                  <img className={styles["img-product"]} src={FormatImage(item.photo,"thumb")} />
                  <div className={styles["container-detail"]}>
                    <div className={styles["txt-name"]}>{item.name}</div>
                    <div className={styles["txt-detail"]}>
                    <img src={IcoStar} className={styles["ico"]} />
                    {item.rating} - Great Taste!
                  </div>
                  {item.stock ==0 ? 
                      <div className={styles["outstock-txt"]}> Ouf of Stock</div>:""}
                      {item.is_open =="closed" ? 
                      <div className={styles["outstock-txt"]}> Closed</div>:""}
                    <div>
                      Rp {Intl.NumberFormat().format(item.price ? item.price : 0)}
                    </div>
                    {/* <div className={styles["container-limit"]}>
                      <div width className={styles["txt-product-limit"]}>  
                        Kuota Pre-Order {"  "} : {"  "}{item.limit}
                    </div>
                  </div> */}
                  </div>
                </div>
                <div className="mk-line-break"></div>
                

              </div>
            </div>
          </a>
        );
      })}
      {props.page.current_page == props.page.total_pages || props.page.data_length == 0 || props.page.isLoading == true ? "" :<Button
              className={styles["btn-load-more"]}
              iconSize="medium"
              outline
              onClick={() => {props.fetchMoreData();}}
            >
              <div style={{ marginLeft: "2px" }}>Load More</div>
            </Button>}
      
    </div>
    
  );
};
