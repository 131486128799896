import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  f7,
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
  Icon,
} from "framework7-react";
import styles from "./styles.module.less";
import GoogleSearchLocation from "../../../../../components/customs/google-search-location";
import {
  GET_USER_ADDRESS,
  SET_USER_ADDRESS,
  API_URL,
  SET_USER_POSITION,
  GET_USER_POSITION,
} from "../../../../../js/global";

export default (props) => {
  const [geoLocation, setGeoLocation] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const googleSearchRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      getLocation();
    }, 100);
  }, []);

  function getLocation() {
    if (GET_USER_ADDRESS()) {
      setCurrentLocation(GET_USER_ADDRESS().details);
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      f7.dialog.alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    SET_USER_POSITION({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    f7.request
      .promise({
        method: "GET",
        url:
          API_URL +
          "/v1/geocode/longlat?location=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&radius=500&type=address",
        dataType: "json",
        crossDomain: true,
      })
      .then((res) => {
        const location =
          res.data.results.length > 0 ? res.data.results[0].name : null;
        setGeoLocation(location);

        if (!GET_USER_ADDRESS()) setCurrentLocation(location);
      })
      .catch((err) => console.log("err", err));
  }

  function onUpdateLocation(address) {
    if (address.details) {
      setCurrentLocation(address.details);
      SET_USER_ADDRESS(address);
      setTimeout(() => {
        googleSearchRef.current.close();
      }, 100);
    }
    // location.reload();
  }

  return (
    <div
      onClick={() => googleSearchRef.current.open()}
      style={{ marginTop: "15px", display: "flex", cursor: "pointer" }}
    >
      <div style={{ flex: 1 }}>
        <div className={styles["txt-style-1"]}>Your Location</div>
        <div className={styles["txt-style-2"]}>
          {currentLocation ? currentLocation : "Please enable your location"}
        </div>
      </div>
      <div style={{ alignSelf: "center", flexShrink: 0 }}>
        <Icon md="material:location_on" color="black" />
      </div>

      <Actions ref={googleSearchRef}>
        <ActionsGroup
          style={{
            height: "279px",
            backgroundColor: "white",
            borderRadius: "50px 50px 0px 0px",
            display: "flex",
          }}
        >
          <div style={{ width: "100%", margin: "15px 30px 0px 30px" }}>
            <div className={styles["rectangle"]} />
            <div className={styles["txt-deliver-to"]}>Deliver to</div>
            <GoogleSearchLocation
              style={{ marginTop: "20px" }}
              onPlaceSelect={(address) => onUpdateLocation(address)}
            />
            <div
              style={{ display: "flex", marginTop: "20px", cursor: "pointer" }}
              onClick={() => onUpdateLocation({ details: geoLocation })}
            >
              <Icon md="material:location_on" style={{ marginRight: "10px" }} />
              <div>
                <div className={styles["txt-use-your-location"]}>
                  Use your current location
                </div>
                <div className={styles["txt-detail-current-location"]}>
                  {geoLocation ? geoLocation : "Please enable your location"}
                </div>
              </div>
            </div>
          </div>
        </ActionsGroup>
      </Actions>
    </div>
  );
};
