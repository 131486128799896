import React from "react";
import { Page, Toolbar, Tab, Tabs, Link, Navbar, f7 } from "framework7-react";
import styles from "./styles.module.less";
import { IcoBackground } from "../../../assets/transaction";
import ActiveTransactionList from "./components/active-transaction-list";
import HistoryTransactionList from "./components/history-transaction-list";
import { API_URL, GET_TOKEN } from "../../../js/global";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      current_page : 1,
      data_length : 0,
      total_pages : 0,
      limit : 10,
      has_more : false
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      if (!GET_TOKEN()) {
        f7.preloader.hide();
        this.$f7router.navigate("/onboarding/",{
          reloadAll: true,
          clearPreviousHistory: true,
          animate:false
  });
        return;
      }

      //Load User Profile API
      f7.request
        .promise({
          url: API_URL + "/v1/history?limit="+this.state.limit+"&" +
          "&page=" + this.state.current_page,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.setState({
            ...this.state,
            data: data,
            total_pages : res.data.paginator.total_pages,
            has_more : this.state.current_page < res.data.paginator.total_pages ? true : false,
            data_length : res.data.paginator.total_items
          });
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  fetchMoreData (){
    setTimeout(() => {
      this.setState({current_page: this.state.current_page + 1},() => {
        this.fetchDatas()
        this.setState({has_more : this.state.current_page < this.state.total_pages})
      })
    }, 2000);
  
  }

  fetchDatas() {
    if(this.state.has_more) {
      this.fetchData()
    }
  }
  fetchData() {
    this.$f7ready((f7) => {
      // Load Search API
      f7.request.promise
        .get(
          API_URL + "/v1/history/in-active?limit="+this.state.limit+"&" +
          "&page=" + this.state.current_page,
          null,
          "json"
        )
        .then((res) => {
          // console.log("res", res.data.data);
          this.setState({ 
            ...this.state,
            has_more : this.state.current_page < res.data.paginator.total_pages ? true : false,
            data: this.state.data.concat(res.data.data)
          });
          
        })
        .catch((err) => {
          console.log("err", err.status);
          this.setState({has_more : false})
        });
    });
  }
  render() {
    return (
      <Page
        name="transaction"
        pageContent={false}
        style={{ overflow: "hidden" }}
      >
        <Navbar backlink title="Transaction"></Navbar>
        {/* <Button
          force
          iconMd="material:close"
          className={styles["header-btn"]}
          href={"/home/"}
          /> */}
        <div className={styles["txt-header"]}>  </div>
        {!this.state.data || this.state.data.length == 0 ? (
          <div className={styles["container"]}>
            {/* <img className={styles["img-background"]} src={IcoBackground} /> */}
            <div className={styles["txt-style-1"]}>
              Hmm, there is no transaction yet
            </div>
            <div className={styles["txt-style-2"]}>
              When you make new transactions,
              <br />
              you will see them here
            </div>
          </div>
        ) : (
          <>
            <Toolbar noShadow tabbar>
              <Link
                tabLinkActive
                tabLink="#view-active-transaction"
                text="Active Transaction"
              />
              <Link tabLink="#view-history" text="History" />
            </Toolbar>
            <Tabs>
              <Tab
                tabActive
                className="page-content"
                id="view-active-transaction"
              >
                <ActiveTransactionList />
              </Tab>
              <Tab className="page-content" id="view-history">
                <HistoryTransactionList />
              </Tab>
            </Tabs>
          </>
        )}
      </Page>
    );
  }
}
