import React from "react";
import { Row, Col } from "framework7-react";
import styles from "./styles.module.less";
import { number } from "prop-types";
export default function index(props) {
  let price = 0;
  let discounts = 0;
  let deliveryFee = [];
  let Coupon = [];
  let fee_total = 0;
  let fee_amount = 0;
  let v_amount = 0;
  let v_service = 0;
  let v_cooking = 0;
  let v_admin = 0;
  let v_delivery = 0;
  let change_type = 0;
  let total_percentage = 0;
  let total_fixedamount = 0;

  let data = props.data;
  console.log("payment fee", props.data.data.item_fee);
  let Gosend = 0;


  // const findWallet = props.data.data.item_fee
  if (props.data) {
    if (props.data.data.item_fee !== undefined) {
      deliveryFee = props.data.data.item_fee;
      console.log("delivery feee percent",  deliveryFee);
      deliveryFee.length > 0 &&
        deliveryFee[0].map((v) => {
          if (v.type == "percentage") {
            change_type = (v.amount * props.data.cart_PO.total) / 100;
            total_percentage += parseInt(change_type);
          }
          if (v.type == "percentage") {
            change_type = (v.amount * props.data.cart.total) / 100;
            total_percentage += parseInt(change_type);
          }
          return total_percentage;
        });
    }
  }
  if (props.data) {
    if (props.data.data.item_fee !== undefined) {
      deliveryFee = props.data.data.item_fee;
      deliveryFee.length > 0 &&
        deliveryFee[0].map((v) => {
          if (v.type == "fixed_amount") {
            total_fixedamount += parseInt(v.amount);
          }
          return total_fixedamount;
        });
    }
  }

  if (props.data.delivery_services == undefined || props.data.delivery_services.length == 0) {
    Gosend
  }else{
    Gosend = props.data.delivery_services.cost_value;
    Intl.NumberFormat().format(Gosend)
  }


  // if (props.data.cart_PO.quantity !== 0) {
  //   if (props.data.cart_PO) {
  //     for (var item in props.data.cart_PO.item) {
  //       if (props.data.cart_PO.item[item].qty != 0)
  //         price +=
  //           props.data.cart_PO.item[item].price *
  //           props.data.cart_PO.item[item].qty;
  //     }
  //   }
  // } else {
    if (props.data.cart) {
      for (var item in props.data.cart.item) {
        if (props.data.cart.item[item].qty !== 0)
          price +=
            props.data.cart.item[item].price * props.data.cart.item[item].qty;
      }
    }
  // }

  let all_fee = total_fixedamount + total_percentage;
  let total = price - discounts + all_fee + Gosend
  console.log("daftar fee price", price);
  console.log("daftar fee discount", total_percentage);
  // console.log("daftar fee allfee", total_fixedamount);
  // console.log("daftar fee gosend", gosend);
  

  return (
    <div style={{ margin: "20px" }}>
      <Row className={styles["container-row"]}>
        <Col className={styles["txt-label-1"]}>Payment Details</Col>
        <Col
          className={styles["txt-add-promo"]}
          style={{ textAlign: "right" }}
        ></Col>
      </Row>
      {data.cart.total === 0 ? (
        <Row className={styles["container-row"]}>
          <Col className={styles["txt-label-2"]}>Price (estimated)</Col>
          <Col className={styles["txt-detail-1"]}>
            {Intl.NumberFormat().format(data.cart_PO.total)}
          </Col>
        </Row>
      ) : (
        <Row className={styles["container-row"]}>
          <Col className={styles["txt-label-2"]}>Price (estimated)</Col>
          <Col
            className={styles["txt-detail-1"]}
            style={{ textAlign: "right" }}
          >
            {Intl.NumberFormat().format(data.cart.total)}
          </Col>
        </Row>
      )}
      <Row className={styles["container-row"]}>
        <Col className={styles["txt-label-2"]}>Discounts</Col>
        <Col className={styles["txt-detail-2"]} style={{ textAlign: "right" }}>
          {Intl.NumberFormat().format(discounts)}
        </Col>
      </Row>

      {deliveryFee.length > 0 &&
        deliveryFee[0].map((v) => {
          return (
            <Row className={styles["container-row"]}>
              <Col className={styles["txt-label-2"]}>{v.name}</Col>
              <Col
                className={styles["txt-detail-1"]}
                style={{ textAlign: "right" }}
              >
                {/* {Intl.NumberFormat().format(v.amount)} */}

                {v.type == "percentage"
                  ? Intl.NumberFormat().format(
                      (parseInt(v.amount) * price) / 100
                    )
                  : Intl.NumberFormat().format(v.amount)}
                {/* {v.type == "percentage" && v.key=="delivery_fee" ? Intl.NumberFormat().format(v_delivery) : Intl.NumberFormat().format(v.amount)} */}
                {/* {v.name =="Cooking Fee" && v_cooking !== 0 ? Intl.NumberFormat().format(v_cooking) : Intl.NumberFormat().format(v.amount)} */}
              </Col>
            </Row>
          );
        })}
      {Gosend == 0 || props.data.pick_delivery_method == false ? (
        ""
      ) : (
        <Row className={styles["container-row"]}>
          <Col className={styles["txt-label-2"]}>Gosend ({props.data.delivery_services.service_name})</Col>
          <Col
            className={styles["txt-detail-1"]}
            style={{ textAlign: "right" }}
          >
            {Intl.NumberFormat().format(parseInt(Gosend))}
          </Col>
        </Row>
      )}

      <div className="mk-line-break" style={{ marginTop: "9px" }} />
      <Row className={styles["container-row"]}>
        <Col className={styles["txt-label-3"]}>Total Payment</Col>
        <Col className={styles["txt-detail-3"]} style={{ textAlign: "right" }}>
          {Intl.NumberFormat().format(total)}
        </Col>
      </Row>
    </div>
  );
}
