import React from "react";
import styles from "./styles.module.less";
import {
  API_URL,
  FormatImage,
  GET_READYTODRINK_STATUS,
  GET_USER_PROFILE,
  SET_READYTODRINK_STATUS,
} from "../../../../../js/global";
import { Button } from "framework7-react";

const display = {
  display: "block",
};
const hide = {
  display: "none",
};
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
      togglehide: false,
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      // Load Foods API
      f7.request.promise
        .get(
          API_URL +
            "/v1/product?page=1&limit=10&coordinates=&s=&m=&sort_by=&category=readytodrink",
          null,
          "json"
        )
        .then((res) => {
          const data = res.data.data;
          this.setState({ data: data, isLoading: false });
          this.props.onLoaded(true);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.onLoaded(false);
        });
    });
  }

  onclickHide() {
    SET_READYTODRINK_STATUS(true);
  }
  togglehide(event) {
    this.setState((prevState) => ({
      togglehide: !prevState.togglehide,
    }));
    SET_READYTODRINK_STATUS(true);

  }

  render() {
    return (
      <div style={this.state.data.length == 0 ? hide : display}>
        <div style={GET_READYTODRINK_STATUS() == true || this.state.togglehide == true ? hide : display}>
          <div className={styles["container-title-section"]}>
            <div className={styles["txt-title-section-1"]}>Ready To Drink</div>
            {!GET_USER_PROFILE() || GET_USER_PROFILE().age <= 21 ? (
              <a
                onClick={() => this.togglehide()}
                className={styles["txt-more-section"]}
              >
                Hide
              </a>
            ) : (
              <a href="/ready-to-drink/" className={styles["txt-more-section"]}>
                See all
              </a>
            )}
          </div>
          {!GET_USER_PROFILE() || GET_USER_PROFILE().age <= 21 ? (
            <div className={styles["mk-horizontal-scroll"]}>
              <div className={styles["alert-drink"]}>
                <p className={styles["txt-notfound-1"]}>Attention</p>
                <p className={styles["txt-notfound-2"]}>
                  I hereby declare that, I'm over 21 years old
                  <br />
                  and mature enough to use this app to buy
                  <br />
                  and consume alcohol responsibily
                </p>
                {!GET_USER_PROFILE() ? (
                  <Button href="/onboarding/" className={styles["confirm-btn"]}>
                    Sure
                  </Button>
                ) : (
                  <Button
                    href="/edit-profile/"
                    className={styles["confirm-btn"]}
                  >
                    Sure
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className={styles["mk-horizontal-scroll"]}>
              {this.state.data.map((item, index) => {
                return (
                  <div
                    style={{
                      display: item.stock != 0 ? "block" : "none",
                    }}
                  >
                    <a href={"/foods/" + item.type + "/" + item.id + "/"}>
                      <div className={styles["container"]}>
                        <div className={styles["img-product"]}>
                          <img
                            className={styles["img-product"]}
                            src={FormatImage(item.photo, "thumb")}
                          />
                          <div className={styles["txt-merchant-name"]}>
                            {item.merchant}
                          </div>
                        </div>
                        <div className={styles["container-detail"]}>
                          <div className={styles["txt-product-name"]}>
                            {item.name}
                          </div>
                          <div width className={styles["txt-product-price"]}>
                            {item.price_before == 0 ||
                            item.price_before == null ? (
                              <div
                                width
                                className={styles["txt-product-price"]}
                              >
                                {Intl.NumberFormat().format(item.price)}
                              </div>
                            ) : (
                              <div>
                                <div
                                  width
                                  className={styles["txt-product-price-promo"]}
                                >
                                  {Intl.NumberFormat().format(
                                    item.price_before
                                  )}
                                </div>
                                <div
                                  width
                                  className={styles["txt-product-price"]}
                                >
                                  {Intl.NumberFormat().format(item.price)}{" "}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
