import React from "react";
import styles from "./styles.module.less";
import { IcoDelivery, IcoStar } from "../../../../../assets/home";
import { FormatImage  } from "../../../../../js/global";

export default (props) => {
  const data = props.data ? props.data : [1, 2, 3, 4];
  return (
    <div
      style={{
        display: "grid",
        rowGap: "2em",
        gridTemplateColumns: "auto auto auto",
      }}
    >
      {data.map((item, index) => {
        return (
          <a href={"/categories/" + item.id + "/" + item.value_string + "/ready-stock/"}>
            <div className={styles["container"]} style={{ margin: "auto" }}>
              <img className={styles["img-category"]} src={FormatImage(item.photo,"thumb")} />
              <div className={styles["txt-category-name"]}>
                {item.value_string}
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
};
