import React from "react";
import { Row, Col } from "framework7-react";
import styles from "./styles.module.less";
export default function index(props) {
  let price = 0;
  let discounts = 0;
  let deliveryFee = [];
  let Coupon = [];
  let fee_total = 0;
  let fee_amount= 0;
  let v_amount =0;
  let v_service =0;
  let total_fixedamount= 0;
  let change_type= 0;
  let total_percentage = 0 ;

  console.log("payment coupon", props.data);
  
    if (props.data) {
    props.data.transaction_details.map(v => {
      price = price + parseInt(v.amount)
    })
  }


  if (props.data) {
      if(props.data.total_amount != 0) {
        Coupon = props.data.transaction_coupon;
        Coupon.length > 0 &&
            Coupon.map(v => {
              if(v.type == "percentage"){
                Intl.NumberFormat().format(v.amount);
                }
              return (
                Coupon
                
              )
            })
      }
  }
  if (props.data) {
      if(props.data.total_amount != 0) {
        deliveryFee = props.data.transaction_fee;
        deliveryFee.length > 0 &&
            deliveryFee.map(v => {
              if(v.type == "percentage"){
                change_type = (v.amount * price)/100;
                total_percentage += parseInt(change_type) ;
                }
              return (
                total_percentage
                
              )
            })
      }
  }

  if (props.data) {
    if (props.data.total_amount != 0) {
      deliveryFee = props.data.transaction_fee;
      deliveryFee.length > 0 &&
        deliveryFee.map((v) => {
          if (v.type == "fixed_amount") {
            total_fixedamount += parseInt(v.amount);
          }
          return total_fixedamount;
        });
    }
  }

  let all_fee = total_percentage + total_fixedamount;
  let total = price - discounts + all_fee;


  return (
    <div style={{ margin: "20px" }}>
      
      <Row className={styles["container-row"]}>
        <Col className={styles["txt-label-1"]}>Payment Details</Col>
      </Row>
      <Row className={styles["container-row"]}>
        <Col className={styles["txt-label-2"]}>Price (estimated)</Col>
        <Col className={styles["txt-detail-1"]} style={{ textAlign: "right" }}>
          Rp {Intl.NumberFormat().format(price)}
        </Col>
      </Row>
      <Row className={styles["container-row"]}>
        <Col className={styles["txt-label-2"]}>Discounts</Col>
        <Col className={styles["txt-detail-2"]} style={{ textAlign: "right" }}>
          Rp {Intl.NumberFormat().format(discounts)}
        </Col>
      </Row>
      {
        deliveryFee.length > 0 &&
        deliveryFee.map(v => {
          return (<Row className={styles["container-row"]}>
            <Col className={styles["txt-label-2"]}>{v.name}</Col>
            <Col className={styles["txt-detail-1"]} style={{ textAlign: "right" }}>
            {v.type == "percentage"
                  ? Intl.NumberFormat().format(
                      (parseInt(v.amount) * price) / 100
                    )
                  : Intl.NumberFormat().format(v.amount)}
            {/* {v.type == "percentage" ? Intl.NumberFormat().format(v_service) : Intl.NumberFormat().format(v.amount)} */}
            </Col>
          </Row>
          )
        })
      }
      {
        Coupon.length > 0 &&
        Coupon.map(v => {
          return (<Row className={styles["container-row"]}>
            <Col className={styles["txt-label-2"]}>Voucher {v.name}</Col>
            <Col className={styles["txt-detail-1"]} style={{ textAlign: "right" }}>
            {Intl.NumberFormat().format(v.amount)}
            </Col>
          </Row>
          )
        })
      }
      
      <div className="mk-line-break" style={{ marginTop: "9px" }} />
      <Row className={styles["container-row"]}>
        <Col className={styles["txt-label-3"]}>Total Payment</Col>
        <Col className={styles["txt-detail-3"]} style={{ textAlign: "right" }}>
          Rp {Intl.NumberFormat().format(props.data.total_amount)}
        </Col>
      </Row>
    </div>
  );
}