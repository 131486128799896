import React from "react";
import styles from "./styles.module.less";
import {FormatImage  } from "../../../../../js/global";

export default function index(props) {
  return (
    <div>
      {props.data.map((item, index) => {
        return (
          <a href={"/promo/" + item.id + "/"}>
            {item.price_before==null ? (
            <div></div>
              ) : (
                <div className={styles["promo-item"]}> 
                  <div className={styles["img-promo"]}>
                    <img src={FormatImage(item.photo,"thumb")}className={styles["img-promo"]}></img>
                  </div>
                  <div className={styles["main-container"]}>
                    <div className={styles["txt-name-down"]}>{item.name}</div>
                    <div className={styles["container-detail"]}>
                      <div className={styles["container-subdetail"]}>
                        <div className={styles["txt-name-up"]}>Discount</div>
                        <div className={styles["txt-name-down"]}>Up to</div>  
                      </div>
                      <div width className={styles["txt-product-price"]}>
                      {Math.round(((item.price_before - item.price) / item.price_before)*100)}%
                      </div>
                    </div>
                  </div>
            </div>
            )}
          </a>
        );
      })}
    </div>
  );
}
