import React, { useState } from "react";
import { ImgBackground, IcoRegister } from "../../../assets/auth";
import { Button, List, Input, Page, f7, $f7 } from "framework7-react";
import PhoneNumberForm from "./components/phone-number-form";
import PersonalDataForm from "./components/personal-data-form";
import Completed from "./components/completed";
import OTP from "./components/otp";
import styles from "./styles.module.less";
import {
  API_URL,
  ChangePhoneAreaCode,
  SET_TEMP_USER_DATA,
  SET_TOKEN,
} from "../../../js/global";
export default (props) => {
  const [registerState, setRegisterState] = useState(0);
  const [data, setData] = useState({
    phone: "",
    full_name: "",
    email: "",
    password: "",
    c_password: "",
    client_code: "010101",
    otp: "",
    secret: "",
  });

  if (registerState === 3) {
    return <Completed />;
  }

  const onSendOTP = async (val) => {
    const _f7 = f7;
    _f7.preloader.show();
    await _f7.request.promise
      .postJSON(
        API_URL + "/v2/auth/otp/send",
        { phone: ChangePhoneAreaCode(data.phone) },
        "json"
      )
      .then((res) => {
        console.log("res", res);
        setData({ ...data, secret: res.data.data.hash });
        _f7.preloader.hide();
        _f7.dialog.alert("OTP has been sent. (" + val + "/3)");
      })
      .catch((err) => {
        console.log("err", err);
        _f7.preloader.hide();
        _f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  };

  const onClickRegister = async () => {
    const _f7 = f7;
    console.log("data", data);
    let isPhoneNumberValid = false;
    switch (registerState) {
      case 0:
        _f7.preloader.show();
        await _f7.request.promise
          .postJSON(
            API_URL + "/v2/auth/register/validate",
            { phone: ChangePhoneAreaCode(data.phone) },
            "json"
          )
          .then((res) => {
            console.log("res", res);
            _f7.preloader.hide();
            setRegisterState(registerState + 1);
            isPhoneNumberValid = true;
          })
          .catch((err) => {
            console.log("err", err);
            _f7.preloader.hide();
            // _f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
            isPhoneNumberValid = false;
          });

        if (!isPhoneNumberValid) break;

        await _f7.request.promise
          .postJSON(
            API_URL + "/v2/auth/otp/send",
            { phone: ChangePhoneAreaCode(data.phone) },
            "json"
          )
          .then((res) => {
            console.log("res", res);
            setData({ ...data, secret: res.data.data.hash });
            _f7.preloader.hide();
          })
          .catch((err) => {
            console.log("err", err);
            _f7.preloader.hide();
          });

        break;
      case 1:
        _f7.preloader.show();
        _f7.request.promise
          .postJSON(
            API_URL + "/v2/auth/otp/confirm",
            {
              phone: ChangePhoneAreaCode(data.phone),
              otp: data.otp,
              secret: data.secret,
            },
            "json"
          )
          .then((res) => {
            console.log("res", res);
            _f7.preloader.hide();
            setRegisterState(registerState + 1);
          })
          .catch((err) => {
            console.log("err", err);
            _f7.preloader.hide();
            _f7.dialog.alert("OTP code doesn't match.");
          });
        break;
      case 2:
        _f7.preloader.show();
        _f7.request.promise
          .postJSON(
            API_URL + "/v2/auth/register",
            { ...data, phone: ChangePhoneAreaCode(data.phone) },
            "json"
          )
          .then((res) => {
            console.log("res", res);
            _f7.preloader.hide();
            SET_TOKEN(res.data.token);
            SET_TEMP_USER_DATA({ phone: data.phone, password: data.password });
            setRegisterState(registerState + 1);
          })
          .catch((err) => {
            console.log("err", err);
            _f7.preloader.hide();
            _f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
          });
        break;
      default:
        setRegisterState(registerState + 1);
        break;
    }
  };

  return (
    <Page name="register">
      <div className={styles["container"]}>
      <img className={styles["img-background"]} src={ImgBackground} />
      <a href="/login/" className={styles["btn-top-right"]}>
        Login
      </a>
      <Button
        back
        iconMd="material:arrow_back_ios"
        className={styles["btn-back"]}
      />
      <div style={{ flex: 1 }}>
        <div className={styles["oval"]}>
          <img src={IcoRegister} className={styles["ico"]} />
        </div>
        <div className={styles["txt-title"]}>Register</div>
        <div style={{ overflow: "auto" }}>
          {registerState == 0 ? (
            <PhoneNumberForm onUpdate={(val) => setData({ ...data, ...val })} />
          ) : null}
          {registerState == 1 ? (
            <OTP
              onResendOTP={(val) => onSendOTP(val)}
              onUpdate={(val) => setData({ ...data, ...val })}
              onLimitOTP={() => setRegisterState(0)}
            />
          ) : null}
          {registerState == 2 ? (
            <PersonalDataForm
              onUpdate={(val) => setData({ ...data, ...val })}
            />
          ) : null}
        </div>
      </div>
      <div className="mk-footer no-shadow">
        <Button
          style={{
            margin: "0px 30px 0px 30px",
            alignSelf: "center",
            justifySelf: "center",
          }}
          onClick={() => onClickRegister()}
          fill
          className="mk-button"
        >
          {registerState === 2 ? "Register" : "Next"}
        </Button>
      </div>
    </div>
    </Page>
  );
};
