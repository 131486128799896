import React from "react";
import { Button, Page, List, ListItem, Icon } from "framework7-react";
import { IcoProfile } from "../../../assets/profile";
import styles from "./styles.module.less";
import { GET_TOKEN, SIGN_OUT, API_URL, FormatImage } from "../../../js/global";
export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      fullName: "",
      phone: "",
      photo: "",
      img: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }

      //Load Merchants API
      f7.request
        .promise({
          url: API_URL + "/v1/profile",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          console.log("res", res.data.data);
          const data = res.data.data.photo;
          this.setState({
            ...this.state,
            fullName: res.data.data.full_name,
            phone: res.data.data.phone,
            photo: res.data.data.photo + "?t=" + new Date().getTime(),
            img: data,
            isLoading: false,
          });
          console.log("img", this.state);
          f7.preloader.hide();
        })
        .catch((err) => {
          // console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  onClickSignOut() {
    SIGN_OUT();
    this.$f7router.navigate("/onboarding/");
  }

  render() {
    return (
      <Page
        style={{ visibility: this.state.isLoading ? "hidden" : "visible" }}
        name="transaction"
      >
        <div className={styles["txt-header"]}> Profile </div>
        <div className={styles["container-profile"]}>
          <img
            className={styles["img-profile"]}
            src={
              this.state.img == null
                ? IcoProfile
                : FormatImage(this.state.photo, "thumb")
            }
          />
          <div style={{ flex: 1 }}>
            <div className={styles["txt-profile-name"]}>
              {this.state.fullName}
            </div>
            <div className={styles["txt-profile-number"]}>
              {this.state.phone}
            </div>
          </div>
          <Button
            href="/edit-profile/"
            className={styles["ico-profile-edit"]}
            iconSize="25px"
            iconMd="material:edit"
          />
        </div>
        <div
          style={{
            height: "10px",
            backgroundColor: "#f6f6f6",
            marginTop: "20px",
          }}
        />

        <List className={styles["container-list"]}>
          <div className={styles["txt-list-label"]}>Account</div>
          <ListItem link="/settings/" title="Settings">
            <Icon slot="media" material="settings"></Icon>
          </ListItem>
          <ListItem link="/my-voucher/" title="My Voucher">
            <Icon slot="media" material="confirmation_number"></Icon>
          </ListItem>
          <ListItem link="/privacy/" title="Privacy Policy">
            <Icon slot="media" material="description"></Icon>
          </ListItem>
          <ListItem link="/faq/" title="FAQ">
            <Icon slot="media" material="help"></Icon>
          </ListItem>
          <ListItem link="/about/" title="About Us">
            <Icon slot="media" material="supervisor_account"></Icon>
          </ListItem>
          <ListItem link="/contact/" title="Contact Us">
            <Icon slot="media" material="phone"></Icon>
          </ListItem>
          {/* <ListItem link="#" title="Rating Us">
            <Icon slot="media" material="grade"></Icon>
          </ListItem> */}
        </List>
        <Button
          style={{ margin: "20px 20px 20px 20px" }}
          className="mk-button"
          fill
          onClick={() => this.onClickSignOut()}
        >
          Sign Out
        </Button>
      </Page>
    );
  }
}
