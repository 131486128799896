import React from "react";
import { Page, Button, ActionsButton } from "framework7-react";
import DeliverTo from "../deliver";
import Voucher from "../voucher";
import OrderSummary from "../order-summary";
import PaymentDetails from "../payment-details";
import styles from "./styles.module.less";
import {
  ORDER_WA,
  GET_CART,
  GET_USER_ADDRESS,
  GET_USER_POSITION,
  GET_MERCHANT_PROFILE,
} from "../../../../../js/global";
import { IcoDown, IcoCopy, IcoUp } from "../../../../../assets/transaction";

// import { UPDATE_CART, REMOVE_CART } from "../../../../../js/cart";
import {
  UPDATE_CART,
  REMOVE_CART,
  // INFO_CART_PO,
  // REMOVE_CART_PO,
  // UPDATE_CART_PO,
} from "../../../../../js/cart2";
import { API_URL, GET_TOKEN, FormatImage } from "../../../../../js/global";
import { IcoArrow, IcoVoucher } from "../../../../../assets/merchant";
import TransferTo from "../transfer-to";
import { func } from "prop-types";
import moment from "moment";
import voucher from "../voucher";

// import { WebView } from 'react-native-webview';

const display = {
  display: "block",
};
const hide = {
  display: "none",
};

export default class index extends React.Component {
  constructor() {
    super();
    const idMerchant = GET_CART() ? GET_CART().merchant_id : "";
    const address = GET_USER_ADDRESS();

    this.state = {
      fullName: "",
      phone: "",
      pin_enabled: 0,
      methodDelivery: "",
      methodFood: "",
      isLoading: true,
      merchant: GET_MERCHANT_PROFILE(),
      cart: {
        item: [],
      },
      cart_PO: {
        item: [],
      },
      voucher_codes: "",
      payment_methods: [],
      delivery_list: [],
      delivery_method: [],
      delivery_services: [],
      banks: [],
      trial: 0,
      address: address || {},
      data: {
        address:[], 
        item: [],
        Myvoucher: [],
        coupon: [],
        coupon_codes: "",
        addresses: [],
        note: "",
        payment_method: 1,
        before_payment: 1,
        shipment_method: 10,
        merchant_id: idMerchant,
        item: [],
        item_fee: [],
        toggle: false,
        delivery_service_token: "",
        toggleDelivery: false,
        toggleDeliveryDetail: false,
      },
      size: "",
      pick_delivery_method: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onClickPengiriman = this.onClickPengiriman.bind(this);
  }

  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  }
  toggleDelivery(event) {
    this.setState((prevState) => ({
      toggleDelivery: !prevState.toggleDelivery,
    }));
  }
  toggleDeliveryDetail(event, val) {
    this.setState((prevState) => ({
      toggleDeliveryDetail: !prevState.toggleDeliveryDetail,
      delivery_method: val,
    }));
    // this.setState({ ...this.state, delivery_method: val });
  }

  async getpaymentUrl(id) {
    const app = this.$f7;
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/get",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: id,
        },
      })
      .then((res) => {
        window.location.href = res.data.data.url.WebUrl;
      })
      .catch((err) => {
        if (this.state.trial >= 3) {
          throw err;
        }
        this.setState({
          trial: this.state.trial + 1,
        });
        this.getpaymentUrl(id);
      });
  }

  CreatepaymentUrl(id) {
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/create",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: id,
        },
      })
      .then(async (res) => {
        this.getpaymentUrl(id);
      })
      .catch((err) => {
        // preloader.hide();
        // alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  getAddressList() {
    const app = this.$f7;
    app.preloader.show();
    app.request
      .promise({
        url: API_URL + "/v2/profile/address",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: this.state.data,
      })
      .then(async (res) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            addresses: [res.data.data],
          },
        });
        console.log("address", this.state);
      })
      .catch((err) => {});
  }
  myVoucher() {
    const app = this.$f7;
    app.preloader.show();
    app.request
      .promise({
        url: API_URL + "/v1/coupon/mine?m="+this.state.data.merchant_id+"",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
      .then(async (res) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            Myvoucher: [res.data.data],
          },
        });
        console.log("hahahaa", this.state);
      })
      .catch((err) => {});
  }

  getValidatePreOrder() {
    const cart = this.state.cart_PO;
    let currItem = [];
    for (var key in cart.item) {
      if (cart.item[key].qty !== 0) {
        currItem.push({
          pre_order_id: cart.item[key].pre_order_id,
          quantity: cart.item[key].qty,
          food_notes: cart.item[key].notes,
        });
      }
    }

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        items: [...currItem],
        type_transaction: "po",
        address: this.state.address,
        coupon_codes: this.state.voucher_codes,
        note: this.state.data.note,
        merchant_id: this.state.cart_PO.merchant_id,
        shipment_method: 10,
        payment_method: this.state.data.payment_method,
      },
    });

    const app = this.$f7;
    app.preloader.show();
    app.request
      .promise({
        url: API_URL + "/v3/pre-order/order/validate",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: this.state.data,
      })
      .then(async (res) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            item_fee: [res.data.data.fee],
          },
        });
      })
      .catch((err) => {
        app.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  getValidateOrder() {

    const cart = this.state.cart;
    let currItem = [];
    for (var key in cart.item) {
      if (cart.item[key].qty !== 0) {
        currItem.push({
          product_id: cart.item[key].id,
          qty: cart.item[key].qty,
          notes: cart.item[key].notes,
          type: cart.item[key].type,
          serve_as: cart.item[key].serve_as,
        });
      }
    }
    const currItemConstruct = _.chain(currItem)
      .groupBy("type")
      .map((val, key) => ({
        type: key,
        data: val,
      }))
      .value();

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        item: [...currItemConstruct],
        coupon_codes: this.state.voucher_codes,
        // payment_method: this.state.banks[0].id,
      },
    });
    const app = this.$f7;
    const data = this.state.data;
    console.log("state validate order",);
    this.$request
      .promise({
        url: API_URL + "/v2/order/validate",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data:{
          item:data.item,
          address:data.address,
          delivery_service_token:data.delivery_service_token,
          note:data.note,
          payment_method:data.payment_method,
          shipment_method:data.shipment_method,
          coupon_codes:this.state.voucher_codes,
          merchant_id:data.merchant_id,
          
        },
      })
      .then((res) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            coupon : [res.data.data.coupon],
            item_fee: [res.data.data.fee],
          },          
        });
      })
      .catch((err) => {
        console.log("err", err);
        // location.reload();
        this.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  getPaymetMethod() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      //Load Products API
      f7.request.promise
        .get(API_URL + "/v1/payment-method?limit=5", null, "json")
        .then(async (res) => {
          let data = res.data.data;
          // setAddresses(data);
          //  for (var key in data) {
          //   onChangeMainAddress(data[key]);
          //    }
          if (data) this.setState({ ...this.state, payment_methods: data });
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }

  getDeliveryList() {
    if (!GET_USER_ADDRESS() && !GET_USER_POSITION()) {
      const app = this.$f7;
      console.log("gadapet");
      app.preloader.hide();
    } else {
      const app = this.$f7;
      const address = this.state.address;
      const merchant = this.state.merchant;
      const destinationLatLong = !GET_USER_ADDRESS()
        ? GET_USER_POSITION().latitude + ", " + GET_USER_POSITION().longitude
        : GET_USER_ADDRESS().latitude + ", " + GET_USER_ADDRESS().longitude;
      const originLatLong = merchant.latitude + ", " + merchant.longitude;
      app.preloader.show();
      app.request
        .promise({
          url: API_URL + "/v1/delivery-service",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data: {
            originLatLong: originLatLong,
            destinationLatLong: destinationLatLong,
          },
        })
        .then((res) => {
          let data = res.data.result;
          this.setState({ ...this.state, delivery_list: data });
          if (data[0].services.length == 0) {
            this.setState({ ...this.state, isLoading: false });
          } else {
            this.setState({ ...this.state, isLoading: true });
          }
          app.preloader.hide();
        })
        .catch((err) => {});
    }
  }
  getDeliveryToken(val) {
    const app = this.$f7;
    const address = this.state.address;
    const merchant = this.state.merchant;
    const serviceCode = val.service_code;
    const destinationLatLong = !GET_USER_ADDRESS()
      ? GET_USER_POSITION().latitude + ", " + GET_USER_POSITION().longitude
      : GET_USER_ADDRESS().latitude + ", " + GET_USER_ADDRESS().longitude;
    const originLatLong = merchant.latitude + ", " + merchant.longitude;
    app.preloader.show();
    app.request
      .promise({
        url: API_URL + "/v1/delivery-service/get-order-token",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          originLatLong: originLatLong,
          destinationLatLong: destinationLatLong,
          serviceCode: serviceCode,
        },
      })
      .then((res) => {
        let data = res.data.data.delivery_token_no;
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            delivery_service_token: data,
          },
        });
      })
      .catch((err) => {});
  }

  onClickOrderNow() {
    // this.onFixAddress();
    const f7 = this;
    if (
      this.state.pin_enabled == 0 &&
      this.state.data.before_payment.key == "frodowallet"
    ) {
      f7.$f7.dialog.alert("You must activate ewallet first!", () =>
        this.props.router.navigate("/change-pin/")
      );
    }
    if (this.state.pick_delivery_method == false) {
      f7.$f7.dialog.alert("Anda belum memilih metode pengiriman");
    } else {
      const cart = this.state.cart;
      let currItem = [];
      for (var key in cart.item) {
        if (cart.item[key].qty !== 0) {
          currItem.push({
            product_id: cart.item[key].id,
            qty: cart.item[key].qty,
            notes: cart.item[key].notes,
            type: cart.item[key].type,
            serve_as: cart.item[key].serve_as,
          });
        }
      }
      const currItemConstruct = _.chain(currItem)
        .groupBy("type")
        .map((val, key) => ({
          type: key,
          data: val,
        }))
        .value();

      // this.state.payment_methods.map(p => {

      // if(p.key=="qoin")
      // {
      //   this.setState({
      //     ...this.state,
      //     data: {
      //       ...this.state.data,
      //       payment_method: p.id,
      //     },
      //   });
      // }
      // })

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          item: [...currItemConstruct],
          coupon_codes: this.state.voucher_codes, 
          // payment_method: this.state.payment_methods.key,
        },
      });
      const app = this.$f7;
      const data = this.state.data;
      app.preloader.show();
      app.request
        .promise({
          url: API_URL + "/v2/order",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data:{
            item:data.item,
            address:data.address,
            delivery_service_token:data.delivery_service_token,
            note:data.note,
            payment_method:data.payment_method,
            shipment_method:data.shipment_method,
            coupon_codes:this.state.voucher_codes,
            merchant_id:data.merchant_id,
            
          },
        })

        .then(async (res) => {
          if (this.state.data.before_payment.key == "qoin") {
            this.getpaymentUrl(res.data.data.transaction.id);
            this.props.router.navigate(
              "/transactions/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
            2;
          }

          if (this.state.data.before_payment.key == "va_bca") {
            this.props.router.navigate(
              "/bank-info/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
          }
          if (this.state.data.before_payment.key == "frodowallet") {
            this.props.router.navigate(
              "/password/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
          }
          app.preloader.hide();

          REMOVE_CART();
        })
        .catch((err) => {
          app.dialog.alert(JSON.parse(err.xhr.responseText).message);
        });
      // app.preloader.hide()
    }
  }

  addButton() {
    this.props.router.navigate("/address/");
  }
  onAddMore() {
    this.props.router.navigate(
      "/merchants/" + this.state.cart.merchant_id + "/"
    );
  }
  onClickPengiriman() {
    this.props.router.navigate("/shipping-tab/");
  }
  onClickBookNow() {
    const f7 = this;
    if (
      this.state.pin_enabled == 0 &&
      this.state.data.before_payment.key == "frodowallet"
    ) {
      f7.$f7.dialog.alert("You must activate ewallet first!", () =>
        this.props.router.navigate("/change-pin/")
      );
    }
    if (this.state.pick_delivery_method == false) {
      f7.$f7.dialog.alert("Anda belum memilih metode pengiriman");
    } else {
      const cart = this.state.cart_PO;
      let currItem = [];
      for (var key in cart.item) {
        if (cart.item[key].qty !== 0) {
          const pickupGroup = _.groupBy(cart.item, (o) =>
            moment(o.pre_order_delivery_date).format("YYYY-MM-DD")
          );
          const pickupTime = Object.entries(pickupGroup).map(([key, value]) => {
            return {
              date: key,
              time: moment().format("HH:mm"),
            };
          });
          const findTime = pickupTime.findIndex(
            (o) =>
              o.date ===
              moment(cart.item[key].pre_order_delivery_date).format(
                "YYYY-MM-DD"
              )
          );
          const time = pickupTime[findTime];
          const date = moment(time.date).format("YYYY-MM-DD");
          currItem.push({
            pre_order_id: cart.item[key].pre_order_id,
            quantity: cart.item[key].qty,
            food_notes: cart.item[key].notes,
            pickup_time: date + " " + time.time,
          });
        }
      }

      // this.state.payment_methods.map(p => {

      // if(p.key=="qoin")
      // {
      //   this.setState({
      //     ...this.state,
      //     data: {
      //       ...this.state.data,
      //       payment_method: p.id,
      //     },
      //   });
      // }
      // })

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          item: [...currItem],
          type_transaction: "po",
          address: this.state.data.address,
          note: this.state.data.note,
          merchant_id: this.state.cart_PO.merchant_id,
          shipment_method: 10,
          payment_method: this.state.data.payment_method,
        },
      });
      const app = this.$f7;
      app.preloader.show();
      app.request
        .promise({
          url: API_URL + "/v3/pre-order/order",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data: this.state.data,
        })
        .then(async (res) => {
          if (res.data.data.transaction_group_id == null) {
            if (this.state.data.before_payment.key == "qoin") {
              this.getpaymentUrl(res.data.data.transaction.id);
              this.props.router.navigate(
                "/transactions/" + res.data.data.transaction[0].id + "/"
              );
              2;
            }

            if (this.state.data.before_payment.key == "va_bca") {
              this.props.router.navigate(
                "/bank-info/" + res.data.data.transaction[0].id + "/"
              );
            }
            if (this.state.data.before_payment.key == "frodowallet") {
              this.props.router.navigate(
                "/password/" + res.data.data.transaction[0].id + "/"
              );
            }

            app.preloader.hide();
            // REMOVE_CART_PO();
          } else {
            if (this.state.data.before_payment.key == "qoin") {
              this.getpaymentUrl(res.data.data.transaction.id);
              this.props.router.navigate(
                "/transactions/" + res.data.data.transaction_group_id + "/"
              );
              2;
            }

            if (this.state.data.before_payment.key == "va_bca") {
              this.props.router.navigate(
                "/bank-info/" + res.data.data.transaction_group_id + "/"
              );
            }
            if (this.state.data.before_payment.key == "frodowallet") {
              this.props.router.navigate(
                "/password/" + res.data.data.transaction_group_id + "/"
              );
            }
            app.preloader.hide();

            // REMOVE_CART_PO();
          }
        })
        .catch((err) => {
          // app.dialog.alert(JSON.parse(err.xhr.responseText).message);
        });
    }
  }

  componentDidMount() {
    if (GET_CART()) {
      this.setState({ ...this.state, cart: GET_CART() });
    }
    this.$f7ready((f7) => {
      f7.preloader.show();

      //Load Products API
      f7.request.promise
        .get(
          API_URL +
            "/v1/merchant/" +
            this.state.data.merchant_id +
            "?include=bank_informations",
          null,
          "json"
        )
        .then(async (res) => {
          // this.setState({
          //   ...this.state,
          //   merchant: res.data.data,
          // });
          this.loadAddress();
          this.getDeliveryList();
          let cart_PO;
          // await INFO_CART_PO()
          //   .then((res) => (cart_PO = res))
          //   .catch((err) => console.log("cart_err", err));
          // this.setState({ ...this.state, cart_PO: cart_PO });

          this.getPaymetMethod();
          this.getAddressList();
          console.log("liat address", this.state.data);
              this.state.cart_PO.quantity == 0
    ?  this.getValidateOrder()
    :  this.getValidatePreOrder();
          this.myVoucher();
          let data = res.data.data.bank_informations;
          if (data) this.setState({ ...this.state, banks: data });
        })

        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  loadAddress() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }

      //Load Merchants API
      f7.request
        .promise({
          url: API_URL + "/v1/profile",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          this.setState({
            ...this.state,
            fullName: res.data.data.full_name,
            phone: res.data.data.phone,
            pin_enabled: res.data.data.pin_enabled,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }
  onCartUpdate(item, value, notes) {
    if (this.state.cart_PO.quantity != 0) {
      // UPDATE_CART_PO(item, value, notes)
      //   .then((res) => {
      //     this.setState({ ...this.state, cart_PO: res });
      //   })
      //   .catch((err) => console.log("err", err));
    } else {
      UPDATE_CART(item, value, notes)
        .then((res) => {
          this.setState({ ...this.state, cart: res });
        })
        .catch((err) => console.log("err", err));
    }
  }

  AddMethod(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        before_payment: val,
        payment_method: val.id,
      },
    });

    this.toggle();
  }
  // AddMethodDelivery(val) {
  //   this.setState({ ...this.state, delivery_method: val });
  //   console.log("method delivery", this.state.delivery_method);
  // }

  alertdelivery() {
    const app = this.$f7;
    app.dialog.alert("Anda belum memilih metode pengiriman");
  }

  AddMethodDeliveryService(val) {
    const app = this.$f7;
    const alert = this;
    if (val.service_code == "gosend-sameday") {
      app.dialog.confirm(
        "Pengiriman Same day membutuhkan waktu 6-8 jam, Apakah anda yakin?",
        () => {
          this.setState({
            ...this.state,
            delivery_services: val,
            pick_delivery_method: true,
          });
          this.toggleDelivery();
          this.getDeliveryToken(val);
        }
      );
    } else {
      this.setState({
        ...this.state,
        delivery_services: val,
        pick_delivery_method: true,
      });
      this.toggleDelivery();
      this.getDeliveryToken(val);
    }
  }

  onChangeNote(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        note: val,
      },
    });
  }
  onChangeVoucher(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        coupon_codes: val,
      },
    });
  }

  onChangeNotes(value) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.cart,
        notes: value,
      },
    });

    // UPDATE_CART( this.setState({...this.state,cart: {...this.state.cart.notes},}))
  }

  onChangeOrderNote(value) {
    UPDATE_CART();
  }

  onChangeAddress(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        address: {
          longitude: val.longitude,
          latitude: val.latitude,
          details: val.address,
          postal_code: val.zip_code,
          receiver_name: val.receiver_name,
          receiver_phone: val.receiver_phone,
        },
        member_address_id: val.id,
      },
    });
    console.log("state data address",  this.state.data.address);
    this.state.cart_PO.quantity == 0
    ?  this.getValidateOrder()
    :  this.getValidatePreOrder();
    this.getDeliveryList();
    console.log("change",this.state.data);
  }

  onChangeDeliveryStatus() {
    this.setState({ ...this.state, pick_delivery_method: false });
  }

  handleChange(val) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        serve_as: val,
      },
    });
  }

  handleChangeVoucherDelivery(value) {
    this.setState({
      ...this.state,
      methodDelivery: value,
    });
    console.log("handleChangeVoucherdelivery", this.state.methodDelivery);
  }

  onClickApplyVoucher(value) {
    this.setState({
      ...this.state,
      voucher_codes: value,
    });
    console.log("join voucher", this.state);
    this.state.cart_PO.quantity == 0
      ? this.getValidateOrder()
      :  this.getValidatePreOrder();
  }

  handleChangeVoucherFood(value) {
    this.setState({
      ...this.state,
      methodFood: value,
    });
    console.log("handleChangeVoucher food", this.state.methodFood);
  }

  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  render() {
    var modal = [];
    modal.push(
      <div style={this.state.toggle ? display : hide}>
        <Button
          onClick={(event) => this.toggle(event)}
          iconMd="material:close"
          className={styles["close-btn"]}
        />
        <div className={styles["payment-items"]}>
          {this.state.payment_methods.map((item, index) => {
            return (
              // <div style={{ display: "flex", marginLeft: "20px" }}>
              <ActionsButton
                className={styles["input-btn"]}
                onClick={() => this.AddMethod(item)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img className={styles["input-photo"]} src={item.photo}></img>
                  <div className={styles["method-wrap"]}>
                    {item.key == "qoin" ? (
                      <div>
                        <div style={{ height: "20px" }}>
                          {item.value_string}
                        </div>
                        <div className={styles["desc-method"]}>
                          (OVO, Visa and Master Card)
                        </div>{" "}
                      </div>
                    ) : (
                      <div>{item.value_string}</div>
                    )}
                  </div>
                </div>
              </ActionsButton>
            );
          })}
        </div>
      </div>
    );

    var modalDelivery = [];
    modalDelivery.push(
      <div style={this.state.toggleDelivery ? display : hide}>
        <div className={styles["payment-items"]}>
          {this.state.delivery_list.map((item, index) => {
            return (
              <div>
                {this.state.isLoading == false ? (
                  <div>
                    <Button
                      onClick={(event) => this.toggleDelivery(event)}
                      iconMd="material:close"
                      className={styles["close-btn"]}
                    />
                    <div className={styles["detail-btn-notfound"]}>
                      <p className={styles["txt-notfound-1"]}>Notification</p>
                      <p className={styles["txt-notfound-2"]}>
                        Alamat pengiriman berada di luar jangkauan
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Button
                      className={styles["button-shipping-list"]}
                      onClick={(event) =>
                        this.toggleDeliveryDetail(event, item)
                      }
                    >
                      <img
                        className={styles["img-delivery"]}
                        src={item.logo_url}
                      ></img>
                      <img
                        className={styles["img-ico-delivery"]}
                        src={IcoArrow}
                      />
                    </Button>
                  </div>
                )}
                <div
                  className={styles["panel"]}
                  style={this.state.toggleDeliveryDetail ? display : hide}
                >
                  <div className={styles["button-shipping-detail"]}>
                    {item.services.map((service, index) => {
                      return (
                        <div
                          onClick={() => this.AddMethodDeliveryService(service)}
                        >
                          <div className={styles["shipping-detail-btn"]}>
                            <div>
                              {service.service_name} ({service.etd} jam)
                            </div>
                            <div>
                              Rp
                              {Intl.NumberFormat().format(
                                parseInt(service.cost_value)
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
    return (
      <Page>
        {this.state.toggle == true || this.state.toggleDelivery == true ? (
          <div>
            <div style={{ marginTop: "80px" }} />
            {this.state.toggle == true ? modal : " "}
            {this.state.toggleDelivery == true ? modalDelivery : " "}
          </div>
        ) : (
          <div>
            <div style={{ marginTop: "80px" }} />
            {/* <div style={{width:"100%",textAlign:"center"}}>
            <div className="preloader" />
            </div> */}
            <div style={{ display: "flex", margin: "20px" }}>
              <a
                onClick={() =>
                  this.openInNewTab(
                    "http://maps.google.com/?q=" +
                      this.state.merchant.latitude +
                      "," +
                      this.state.merchant.longitude
                  )
                }
              >
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "5px",
                    backgroundColor: "gray",
                  }}
                  src={FormatImage(this.state.merchant.photo, "thumb")}
                />
              </a>
              <div
                style={{
                  marginLeft: "20px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={styles["txt-restaurant-location"]}>
                  Delivery From
                </div>
                <a
                  onClick={() =>
                    this.openInNewTab(
                      "http://maps.google.com/?q=" +
                        this.state.merchant.latitude +
                        "," +
                        this.state.merchant.longitude
                    )
                  }
                >
                  <div className={styles["txt-restaurant-name"]}>
                    {this.state.merchant.name}
                  </div>
                </a>
                <div className={styles["txt-restaurant-details"]}></div>
              </div>
            </div>
            <div className="mk-line-break medium" />
            <DeliverTo
              data={this.state ? this.state : []}
              onChangeNote={(val) => this.onChangeNote(val)}
              onChangeAddress={(val) => this.onChangeAddress(val)}
              onChangeDeliveryStatus={() => this.onChangeDeliveryStatus()}
              addButton={() => this.addButton()}
            />
            {this.state.pick_delivery_method == false ? (
              <Button
                className={styles["button-shipping-list"]}
                onClick={(event) => this.toggleDelivery(event)}
              >
                <div className={styles["shipping-btn"]}>
                  Choose your delivery
                </div>
                <img className={styles["img-ico"]} src={IcoArrow} />
              </Button>
            ) : (
              <Button
                className={styles["button-shipping-list"]}
                onClick={(event) => this.toggleDelivery(event)}
              >
                <img
                  className={styles["img-delivery"]}
                  src={this.state.delivery_method.logo_url}
                ></img>
                <div className={styles["shipping-delivery"]}>
                  {this.state.delivery_services.service_name}
                </div>
              </Button>
            )}
            <OrderSummary
              data={this.state.cart ? this.state.cart : []}
              preOrder={this.state.cart_PO ? this.state.cart_PO : []}
              onUpdateCart={(item, val, notes) =>
                this.onCartUpdate(item, val, notes)
              }
              onChangeOrderNote={(value) => this.onChangeOrderNote(value)}
              onAddMore={() => this.onAddMore()}
              handleChange={(val) => this.handleChange(val)}
              handleSubmit={(event) => this.handleSubmit(event)}
            />
            <Voucher
              data={this.state ? this.state : []}
              codes={this.state.coupon_codes}
              onChangeVoucher={(val) => this.onChangeVoucher(val)}
              voucherDeliverySelected={this.state.methodDelivery}
              voucherFoodSelected={this.state.methodFood}
              handleChangeVoucherFood={(value) =>
                this.handleChangeVoucherFood(value)
              }
              handleChangeVoucherDelivery={(value) =>
                this.handleChangeVoucherDelivery(value)
              }
              onClickApplyVoucher={(value) => this.onClickApplyVoucher(value)}
              onChangeNote={(val) => this.onChangeNote(val)}
              addButton={() => this.addButton()}
            />

            <PaymentDetails
              data={this.state ? this.state : []}
              // data1={this.state.data.item_fee ? this.state.data.item_fee  : []}
            />
            <div className="mk-line-break medium" />

            {/* <TransferTo data={this.state} /> */}
            {this.state.pick_delivery_method == false ? (
              <Button
                className={styles["button-wrap"]}
                onClick={() => this.alertdelivery()}
              >
                <div>
                  <div className={styles["method-btn-disable"]}>
                    {this.state.data.payment_method == 1
                      ? "Choose Your Payment Method"
                      : this.state.data.before_payment.value_string}
                  </div>
                  <div className={styles["notes"]}>
                    {this.state.data.payment_method == 1
                      ? "please choose your payment method first"
                      : ""}
                  </div>
                </div>
                <img className={styles["img-ico"]} src={IcoArrow} />
              </Button>
            ) : (
              <Button
                className={styles["button-wrap"]}
                onClick={(event) => this.toggle(event)}
              >
                <div>
                  <div className={styles["method-btn"]}>
                    {this.state.data.payment_method == 1
                      ? "Choose Your Payment Method"
                      : this.state.data.before_payment.value_string}
                  </div>
                  <div className={styles["notes"]}>
                    {this.state.data.payment_method == 1
                      ? "please choose your payment method first"
                      : ""}
                  </div>
                </div>
                <img className={styles["img-ico"]} src={IcoArrow} />
              </Button>
            )}
            <div style={{ marginTop: "30px" }} />
            <div className={styles["container-btn-buy"]}>
              {this.state.data.payment_method == 1 ? (
                ""
              ) : (
                <Button
                  onClick={() =>
                    this.state.cart_PO.quantity == 0
                      ? this.onClickOrderNow()
                      : this.onClickBookNow()
                  }
                  className={styles["btn-order"]}
                  text={this.state.cart.is_preorder ? "PAY" : "PAY"}
                />
              )}
            </div>
            <div style={{ marginTop: "80px" }} />
          </div>
        )}
      </Page>
    );
  }
}
