import React, { useState } from "react";
import styles from "./styles.module.less";
import { Row, Col,Input } from "framework7-react";
import { IcoLove, IcoPencil } from "../../../../../assets/home";
import { IncreaseDecrease } from "../../../../../components/customs";
  import {FormatImage  } from "../../../../../js/global";
import _ from "lodash";
import moment from "moment";
// import TimePicker from "rc-time-picker";
// import 'rc-time-picker/assets/index.css';
import { Icon } from "framework7-react";


export default (props) => {
  let data = [];
  let time = props.preOrder.item;
  
let end_date;
//get value table name
//  if(props.preOrder){
//     props.preOrder.item.map(v => {
//       if(v.id == props.data.data.table_id){
//         lots_name=v.lots_name
//       }  
//     })
//  }

  // if (props.data.quantity==0&&props.preOrder.quantity!==0) {
  //   const currData = props.preOrder.item;
  //   let newData = [];
  //   for (var key in currData) {
  //     if (currData[key].qty != 0) {
  //       newData.push(currData[key]);
  //     }
  //   }

  //   data = newData;
  //   // data = props.data;
  // }
if(props.data.item.length !== 0){
  if(props.data){
    if (props.data.quantity!==0) {
      const currData = props.data.item;
      let newData = [];
      for (var key in currData) {
        if (currData[key].qty != 0) {
          newData.push(currData[key]);
        }
      }
      data = newData;
      }
      else {
        const currData = props.preOrder.item;
        let newData = [];
        for (var key in currData) {
        if (currData[key].qty != 0) {
          newData.push(currData[key]);
        }
      }
      data = newData;
      }
   
    // data = props.data;
  }
}
else{
  const currData = props.preOrder.item;
        let newData = [];
        for (var key in currData) {
        if (currData[key].qty != 0) {
          newData.push(currData[key]);
        }
      }
      data = newData;
}
  
  const getQty = (item) => {
    let val = 0;
    for (var key in data) {
      if (data[key].id === item.id && data[key].serve_as === item.serve_as && data[key].type === item.type)  {
        val = data[key].qty;
      }
    }
    return val;
  };

  const getQty_PO = (item) => {
    let val = 0;
    for (var key in data) {
      if (data[key].pre_order_id === item.pre_order_id)  {
        val = data[key].qty;
      }
    }
    return val;
  };

  const getNotes_PO = (item) => {
    let val = '';
    for (var key in data) {
      if (data[key].pre_order_id === item.pre_order_id)  {
        val = data[key].notes;
      }
    }
    return val;
  };

  const getPrice_PO = (item) => {
    let val = 0;
    for (var key in data) {
      if (data[key].pre_order_id === item.pre_order_id) {
        val = data[key].qty;
      }
    }
    return val * item.price;
  };

  const getNotes = (item) => {
    let val = '';
    for (var key in data) {
      if (data[key].id === item.id && data[key].serve_as === item.serve_as && data[key].type === item.type) {
        val = data[key].notes;
      }
    }
    return val;
  };

  // const handleValueChange = value => {
  //   data.pickup_time.time = value.format('HH:mm')
  //   props.onPickupTime(data)
  // };
  const getDisabledHours = () => {
    var hours = [];
    for(var i =0; i < moment().hour(); i++){
        hours.push(i);
    }
    return hours;
}

const getDisabledMinutes = (selectedHour) => {
    var minutes= [];
    if (selectedHour === moment().hour()){
        for(var i =0; i < moment().minute(); i++){
            minutes.push(i);
        }
    }
    return minutes;
}

  const getPrice = (item) => {
    let val = 0;
    for (var key in data) {
      if (data[key].id === item.id && data[key].serve_as === item.serve_as && data[key].type === item.type) {
        val = data[key].qty;
      }
    }
    return val * item.price;
  };

  const pickupTimeGroup = _.groupBy(data, (o) => moment(o.pre_order_delivery_date).format("YYYY-MM-DD"))

  
  return (
    <div>
      <div className={styles["summary-header"]}>
        <div className={styles["title"]}>Order Summary</div>
        {/* <div className={styles["add-btn"]} onClick={() => {
                      props.onAddMore();
                    }}>Add More</div> */}
      </div>
      {props.preOrder && _.map(pickupTimeGroup, (value, key) => {
        return (
          <>
           <div style={{ margin: "20px" }}>
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "11px",
        }}
      >
        {/* <div
          style={{
            borderWidth: "0px 0px 1px 0px",
            borderColor: "black",
            borderStyle: "solid",
            paddingBottom: "8px",
            display: "flex",
            width: "190px",
            borderColor: "gray",
            justifyContent:"space-between",
          }}
        >
           
          <div>{moment(key).format("DD-MM-YYYY") }</div>
           
          
          <Icon md="material:date_range" style={{ color: "#e79d41" }} />
        </div> */}

        {/* <div
          style={{
            borderWidth: "0px 0px 1px 0px",
            borderColor: "black",
            borderStyle: "solid",
            paddingBottom: "8px",
            display: "flex",
            width: "190px",
            justifyContent:"space-between",
            borderColor: "gray",
          }}
        >
          <div>
          <TimePicker defaultValue={moment()} 
          style ={{zIndex:"6000"}}
          showSecond={false}
          disabledHours={getDisabledHours}
          disabledMinutes={getDisabledMinutes} />
      </div>
          <Icon md="material:schedule" style={{ color: "#e79d41" }} />
        </div> */}
      </div>
    </div>
          {value.map((item, index) => {
            return (
          
              <div>
                <div className={styles["menu-item"]}>
                  <div style={{ display: "flex", marginBottom: "14.5px" }}>
                    <a href={"/foods/" + item.id + "/"}>
                     <img className={styles["img-menu-product"]} src={FormatImage(item.photo,"thumb")} />
                     </a>
                    <div className={styles["container-detail"]}>
                      <div className={styles["txt-menu-name"]}>{item.name}</div>
                      <div className={styles["txt-menu-detail"]}>
                      {item.description}
                      </div>
                      {/* {props.preOrder ? <div className={styles["txt-menu-price"]}>
                        Rp {Intl.NumberFormat().format(getPrice_PO(item))}
                      </div>: */}
                      <div className={styles["txt-menu-price"]}>
                        Rp {Intl.NumberFormat().format(item.price)}
                      </div>
                      {/* } */}
                      {/* {props.dataTime ? <PickupTime/>:
                      <PickupTime/>}
                       */}
                      <div className={styles["text-notes"]}>
                        {/* {getNotes(item)} */}
                        <div className={styles["container-ico"]}>
                      {/* {props.preOrder?  */}
                      {/* <IncreaseDecrease
                        defaultValue={getQty_PO(item)}
                        onChange={(value) => {
                          props.onUpdateCart(item, value);
                        }}
                      /> */}
                      {/* : */}
                      <IncreaseDecrease
                      defaultValue={getQty(item)}
                      onChange={(value) => {
                        props.onUpdateCart(item, value);
                      }}
                    />
                    {/* } */}
                    </div>
                        {/* {props.preOrder ?
                        <Input className={styles["notes-input"]} type="text" placeholder="Type your note here"
                        defaultValue={getNotes_PO(item)}
                        onChange={(value) => props.onUpdateCart(item, getQty_PO(item), value.target.value)}>
                      </Input>:  */}
                      <Input className={styles["notes-input"]} type="text" placeholder="Type your note here"
                        defaultValue={getNotes(item)}
                        onChange={(value) => props.onUpdateCart(item, getQty(item), value.target.value)}>
                      </Input>
                      {/* } */}
                      
                      </div>
                      {/* {item.type==="po" ? "":<div className={styles["text-notes"]}>
                      Method {" "} {item.serve_as=='readytocook' ? 'Ready To Cook' : 'Ready To Eat'}
                    </div>} */}
                      
                    </div> 
                  </div>
                  {/* <PickupTime  /> */}
                  <div className="mk-line-break"></div>
                </div>
              </div>
    
            );
          })}
          </>
        )
      })}
      {!props.preOrder && data.map((item, index) => {
        return (
          
          <div>
            <div className={styles["menu-item"]}>
              <div style={{ display: "flex", marginBottom: "14.5px" }}>
                <a href={"/foods/" + item.id + "/"}>
                 <img className={styles["img-menu-product"]} src={FormatImage(item.photo,"thumb")} />
                 </a>
                <div className={styles["container-detail"]}>
                  <div className={styles["txt-menu-name"]}>{item.name}</div>
                  <div className={styles["txt-menu-detail"]}>
                  {item.description}
                  </div>
                  {props.preOrder ? <div className={styles["txt-menu-price"]}>
                    Rp {Intl.NumberFormat().format(getPrice_PO(item))}
                  </div>:
                  <div className={styles["txt-menu-price"]}>
                    Rp {Intl.NumberFormat().format(getPrice(item))}
                  </div>}
                  {/* {props.dataTime ? <PickupTime/>:
                  <PickupTime/>}
                   */}
                  <div className={styles["text-notes"]}>
                    {/* {getNotes(item)} */}
                    {props.preOrder ?
                    <Input className={styles["notes-input"]} type="text" placeholder="Type your note here"
                    defaultValue={getNotes_PO(item)}
                    onChange={(value) => props.onUpdateCart(item, getQty_PO(item), value.target.value)}>
                  </Input>: 
                  <Input className={styles["notes-input"]} type="text" placeholder="Type your note here"
                    defaultValue={getNotes(item)}
                    onChange={(value) => props.onUpdateCart(item, getQty(item), value.target.value)}>
                  </Input>}
                  
                  </div>
                  {item.type==="po" ? "":<div className={styles["text-notes"]}>
                  
                  Method {" "} {item.serve_as=='readytocook' ? 'Ready To Cook' : 'Ready To Eat'}
                </div>}
                  
                </div> 

                <div className={styles["container-ico"]}>
                  <img src={IcoLove} />
                  {/* {props.preOrder? 
                  <IncreaseDecrease
                    defaultValue={getQty_PO(item)}
                    onChange={(value) => {
                      props.onUpdateCart(item, value);
                    }}
                  />: */}
                  <IncreaseDecrease
                  defaultValue={getQty(item)}
                  onChange={(value) => {
                    props.onUpdateCart(item, value);
                  }}
                />
                {/* } */}
                
                  
                </div>
              </div>
              {/* <PickupTime  /> */}
              <div className="mk-line-break"></div>
            </div>
          </div>

        );
      })}
    </div>
  );
};
