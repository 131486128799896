import React from "react";
import styles from "./styles.module.less";
import { API_URL, FormatImage } from "../../../../../js/global";

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
    };

  } 
  

  componentDidMount() {
    this.$f7ready((f7) => {
      // Load Foods API
      f7.request.promise
        .get(API_URL + "/v1/pre-order/product?limit=10", null, "json")
        .then((res) => {
          const data = res.data.data;
          this.setState({ data: data, isLoading: false });
          this.props.onLoaded(true);
          console.log("po", this.state);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.onLoaded(false);
        });
    });
  }

  render() {
    return (
      <div style={this.state.data.length == 0 ? hide : display}>
        <div className={styles["container-title-section"]}>
          <div className={styles["txt-title-section-1"]}>PreOrder</div>
          <a href="/preorderpage/" className={styles["txt-more-section"]}>
            See all
          </a>
        </div>
        <div className={styles["mk-horizontal-scroll"]}>
          {this.state.data.map((item, index) => {
            return (
              <a href={"/preorderlist/" + item.product_id + "/"}>
                <div className={styles["container"]}>
                  <div className={styles["img-product"]}>
                    <img
                      className={styles["img-product"]}
                      src={FormatImage(item.photo, "thumb")}
                    />
                    <div className={styles["txt-merchant-name"]}>
                      {item.merchant}
                    </div>
                  </div>
                  <div className={styles["container-detail"]}>
                    <div className={styles["txt-product-name"]}>
                      {item.name}
                    </div>
                    <div width className={styles["txt-product-price"]}>
                      {Intl.NumberFormat().format(item.price)}
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}
