import React from "react";
import { Page, Button, Searchbar, Swiper, SwiperSlide,Navbar,NavLeft,NavRight,NavTitle,Link } from "framework7-react";
import MenuScroller from "./components/menu-scroller";
import PromoScroller from "./components/promo-scroller";
import Deliver from "./components/deliver";
import Category from "./components/category";
import PreOrder from "./components/pre-order";
import ReadyToCook from "./components/ready-to-cook";
import ReadyToDrink from "./components/ready-to-drink";
import ReadyToEat from "./components/ready-to-eat";
import BestSeller from "./components/best-seller";
import MerchantList from "./components/merchant-list";
import Promo from "./components/promo-scroller";
import EWallet from "./components/e-wallet"
import Header from "./components/header";
import styles from "./styles.module.less";
import { Icon } from "framework7-react";
import { GET_USER_PROFILE, SET_READYTODRINK_STATUS, SET_USER_PROFILE } from "../../../js/global";
import { IcoEmail, Icolove, IcoQrCode, IcoVoucher } from "../../../assets/home";

// import QrReader from 'react-qr-reader';

import {
  API_URL,
  GetRedirectToCheckout,
  SetRedirectToCheckOut,GET_TOKEN 
} from "../../../js/global";

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      search: "",
      loadedComponent: 0,
      wallet:true,
      age:"",
      fullName:"",
      dob:"",
      result: "No result",
    };

  }

  componentDidMount() {
    if (GET_TOKEN) {
      this.loadProfile();
    }
    this.$f7.preloader.show();

    // this.$f7ready((f7) => {
    //   setTimeout(() => {
    //     if (GetRedirectToCheckout() === "true") {
    //       SetRedirectToCheckOut(false);
    //       this.$f7router.navigate("/checkout/");
    //     }
    //   }, 1000);
    // });
    
    if (!GET_TOKEN()) {
      this.setState({
        ...this.state,
        wallet:false
      });
    }
  }

  loadProfile() {
    this.$f7ready((f7) => {
      //Load Merchants API
      f7.request
        .promise({
          url: API_URL + "/v1/profile",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data
          SET_USER_PROFILE(data);
          const profile = GET_USER_PROFILE().age
          if (profile >= 21) {
            SET_READYTODRINK_STATUS(false)
          }
        })
        .catch((err) => {
          // console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  onLoaded() {
    if (!GET_TOKEN()) {
      this.$f7router.navigate("/onboarding/",{
        reloadAll: true,
        clearPreviousHistory: true,
        animate:false
});
      return;
    }

    let loadedComponent = this.state.loadedComponent + 1;
    let loadCompleted = false;
    // if (loadedComponent >= 3) {
      this.$f7.preloader.hide();
      loadCompleted = true;
    // }

    this.setState({
      ...this.state,
      loadedComponent: loadedComponent,
      isLoading: !loadCompleted,
    });
  }
  handleScan(data){
    if(data){
      this.setState({
        ...this.state,
        result:data,
      });
    }
  }
  handleError (err){
    console.error(err)
  }
  onSubmitSearch() {
    this.$f7router.navigate("/search/" + "new/" + this.state.search + "/", {
      animate: false,
    });
  }

  render() {
    return (
      <Page
        name="home"
        style={{ visibility: this.state.isLoading ? "hidden" : "visible" }}
      >
         {/* <Navbar>
           <div className={styles["search-div"]}>
            <Searchbar
            value={this.state.search}
            style={{fontSize:"10px"}}
            onSubmit={() => this.onSubmitSearch()}
            onChange={(val) =>
              this.setState({ ...this.state, search: val.target.value })
              
            }
            
            // style={{ flex: 1,width:"100%" }} 
          >    
<Button className={styles["btn-qr"]} href="/qr-scanner/">
          <img
          className={styles["img-qr"]}
          src={IcoQrCode}/>
          </Button>
          
</Searchbar>
          
          </div>
          
          <div style={{ marginTop: "3px", display: "flex" }}>

            <Button href="/qr-scanner/">
            <img
            className={styles["img-ico"]}
            src={IcoQrCode}/>
            </Button>

              <Button href="/inbox/">
              <img
            className={styles["img-ico"]}
            src={IcoEmail}/>
              </Button>
              <Button href="/voucher/">
              <img
            className={styles["img-ico"]}
            src={IcoVoucher}/>
              </Button>
       
            <Button href="/favorites/">
            <img
            className={styles["img-ico"]}
            src={Icolove}/>
            </Button>
           
          </div>
        </Navbar> */}

        <Header router={this.$f7router} />
        <div style={{ marginLeft: 25, marginRight: 25 }}>
          {/* <Deliver /> */}
          {/* <div style={this.state.wallet ? display : hide}>
            <EWallet/>
          </div> */}
          {/* <div className={styles["container-title-section"]}>
            <div className={styles["txt-title-section-1"]}>Promo</div>
            <a href="/promos/">
              <div className={styles["txt-more-section"]}>See all</div>
            </a>
          </div>
          <PromoScroller data={[1, 2, 3]} /> */}

          <Category onLoaded={() => this.onLoaded()} />

          {/* <div className={styles["container-title-section"]}>
            <div className={styles["txt-title-section-1"]}>Promo</div>
            <a href="/promos/" className={styles["txt-more-section"]}>
              See all
            </a>
          </div>
          <Promo onLoaded={() => this.onLoaded()} /> */}


          <BestSeller onLoaded={() => this.onLoaded()} />

          {/* <PreOrder onLoaded={() => this.onLoaded()} /> */}

          <MenuScroller onLoaded={() => this.onLoaded()} />

          {/* <ReadyToDrink onLoaded={() => this.onLoaded()}/>

          <ReadyToCook onLoaded={() => this.onLoaded()} />

          <ReadyToEat onLoaded={() => this.onLoaded()} /> */}

          <MerchantList onLoaded={() => this.onLoaded()} />
        </div>
      </Page>
    );
  }
}
