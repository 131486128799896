import React from "react";
import styles from "./styles.module.less";
import { Row, Col, f7 } from "framework7-react";
import {
  IcoDelivery,
  IcoStar,
  IcoLove,
  IcoPlusOval,
} from "../../../../../assets/home";
import { API_URL, GET_TOKEN, FormatImage } from "../../../../../js/global";

export default function index(props) {
  const deleteWhishlist = (item) => {
    f7.request
      .promise({
        url: API_URL + "/v1/wishlist/delete?product_id=" + item.id,
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: null,
      })
      .then((res) => {
        console.log("res", res);
        props.updateWishList();
      })
      .catch((err) => {
        console.log("err", err);
        // app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  };

  return (
    <div>
      {props.data.map((item, index) => {
        return (
          <div className={styles["menu-item"]}>
            <div style={{ display: "flex", marginBottom: "14.5px" }}>
              <a
                style={{ display: "flex", flex: 1 }}
                href={"/foods/" + item.type + "/" + item.id + "/"}
              >
                <img className={styles["img-menu-product"]} src={FormatImage(item.photo,"thumb")} />
                <div className={styles["container-detail"]}>
                  <div className={styles["txt-menu-name"]}>{item.name}</div>
                  <div className={styles["txt-menu-detail"]}>
                    {item.description}
                  </div>
                  <div className={styles["txt-menu-price"]}>
                    Rp {Intl.NumberFormat().format(item.price)}
                  </div>
                </div>
              </a>
              <div className={styles["container-ico"]}>
                <a
                  onClick={() => deleteWhishlist(item)}
                  style={{
                    zIndex: "50",
                  }}
                >
                  <img src={IcoLove} />
                </a>
                <div />
              </div>
            </div>
            <div className="mk-line-break"></div>
          </div>
        );
      })}
    </div>
  );
}
