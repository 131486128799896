import React from "react";
import styles from "./styles.module.less";
import { IcoDelivery, IcoStar } from "../../../../../assets/home";
import { API_URL, GET_TOKEN } from "../../../../../js/global";
import { Button } from "framework7-react";
 

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      current_page : 1,
      data_length : 0,
      total_pages : 0,
      limit : "15",
      has_more : false,
      isLoading: false
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();
      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }

      //Load User Profile API
      f7.request
        .promise({
          url: API_URL + "/v1/history/active?limit="+this.state.limit+"&" +
          "&page=" + this.state.current_page,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data;
          console.log("data active ",res.data.data);
          this.setState({
            ...this.state,
            data: data,
            total_pages : res.data.paginator.total_pages,
            has_more : this.state.current_page < res.data.paginator.total_pages ? true : false,
            data_length : res.data.paginator.total_items
          });
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  fetchData() {
    this.$f7ready((f7) => {
      // Load Search API
      f7.request
      .promise({
        url: API_URL + "/v1/history/active?limit="+this.state.limit+"&" +
        "&page=" + this.state.current_page,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
        .then((res) => {
          // console.log("res", res.data.data);
          this.setState({ 
            ...this.state,
            has_more : this.state.current_page < res.data.paginator.total_pages ? true : false,
            data: this.state.data.concat(res.data.data)
          });
          
        })
        .catch((err) => {
          console.log("err", err);
          this.setState({has_more : false})
        });
    });
  }
  fetchDatas() {
    if(this.state.has_more) {
      this.fetchData()
    }
  }
  fetchMoreData (){
    setTimeout(() => {
      this.setState({current_page: this.state.current_page + 1},() => {
        this.fetchDatas()
        this.setState({has_more : this.state.current_page < this.state.total_pages })
      })
    }, 2000);
  
  }
  render() {
    return (
      <div style={{ margin: "50px 20px 150px 20px" }}>
                    {/* <div className={styles["main-tab"]}>
                <div className={styles["main-content"]}> */}
                   {this.state.data.map((item, index) => {
                     const color = () => { 
                       if (item.order_status == "Delivery") {
                         return "Rectangle-blue"
                       }else if (item.order_status == "Waiting for Delivery") {
                         return "Rectangle-green"
                       }else if (item.order_status == "Waiting for Payment") {
                         return "Rectangle-red"
                       }else if (item.order_status == "Waiting for Pickup") {
                         return "Rectangle-yellow"
                       }else{
                         return "Rectangle-yellow"
                       }
                     }
                    return (
                      <a href={"/transactions/" + item.id + "/"}>
                      <div className={styles["merchant-item"]}>
                        <div style={{ display: "flex", marginBottom: "14.5px" }}>
                          <img
                            src={item.seller_image}
                            className={styles["img-product"]}
                          />
                          <div className={styles["container-detail-1"]}>
                            <div className={styles[color()]}>
                              <div className={styles["text111"]}>
                                {item.order_status}
                            </div>
                            </div>
                            <div className={styles["txt-name"]}>{item.seller_name}</div>
                            <div className={styles["container-detail-2"]}>
                              {item.transaction_no}
                            </div>
                          </div>
                        </div>
                        <div className="mk-line-break small"></div>
                      </div>
                    </a>
                     );
                    })}
                {/* </div>
            </div> */}
        {/* <Button
        dataLength={this.state.data_length}
        next={this.fetchMoreData()}
        scrollThreshold={1}
        hasMore={this.state.has_more}
        loader={<div className="loader" key={0}>Loading ... {this.state.current_page}</div>}
        >
        {this.state.data.map((item, index) => {
          return (
            <a href={"/transactions/" + item.id + "/"}>
              <div className={styles["merchant-item"]}>
                <div style={{ display: "flex", marginBottom: "14.5px" }}>
                  <img
                    src={item.seller_image}
                    className={styles["img-product"]}
                  />
                  <div className={styles["container-detail-1"]}>
                    <div className={styles["Rectangle"]}>
                      <div className={styles["text111"]}>
                        {item.order_status}
                    </div>
                    </div>
                    <div className={styles["txt-name"]}>{item.seller_name}</div>
                    <div className={styles["container-detail-2"]}>
                      {item.transaction_no}
                    </div>
                  </div>
                </div>
                <div className="mk-line-break"></div>
              </div>
            </a>
          );
        })}
        </Button> */}
      </div>
    );
  }
}
