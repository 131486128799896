import React from "react";
 import {
   Page,
   Link,
   Toolbar,
   Button,
   Tab,
   Tabs,
   Preloader,
   Icon,
   Navbar
 } from "framework7-react";
 
 import styles from "./styles.module.less";
 import { API_URL, GET_TOKEN, GET_CART } from "../../../js/global";
 import { INFO_CART_PO,GET_CART_PO,INFO_CART } from "../../../js/cart2";
 import DeliveryTab from "./components/delivery-tab";
 import DineTab from "./components/dine-tab";
import PickupTab from "./components/pickup-tab";
 import TransferTo from "./components/transfer-to";
 
const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
 export default class extends React.Component {
   constructor() {
     super();
 
     this.state = {
       data: [],
       banks: [],
       cart_PO: {},
       cart_ready:{},
       visibility_po:true,
       visibility_ready:true,
     };
   }
 
   async componentDidMount() {
     setTimeout(() => {
      if(!this.props.merchant_id){
       if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
        return;
      }
      }
      else {

      }
     }, 1);


     let cart_ready;
     await INFO_CART()
     .then((res) => (cart_ready = res))
     .catch((err) => console.log("cart_err", err));

     let cart_po;
    //  await INFO_CART_PO()
    //  .then((res) => (cart_po = res))
    //  .catch((err) => console.log("cart_err",  err));
    //  this.setState({
    //    ...this.state,
    //    cart_PO:cart_po,
    //    cart_ready:cart_ready,
    //  });
   }

   refreshPage(){
    window.location.reload(false);
   }

   GetVisibility(){
    this.state.cart_ready.item.map(v => {
      if(v.qty!==0){
          if(v.serve_as!=="readytoeat"){
            this.setState({
              ...this.state,
              visibility_ready:false,
            });
          }
        }
    })
    if(this.state.cart_PO.quantity !==0){
      this.setState({
        ...this.state,
        visibility_po:false,
      });
    }
   }

   render() {
     return (
       <Page
         pageContent={false}
         style={{ visibility: this.state.data ? "visible" : "hidden" }}
       >

        {/* <Navbar backLinkForce backLink title="Checkout" /> */}
         {/* <div className={styles["header"]}>
           <div className={styles["inner-header"]}>
             <div className={styles["txt-header"]}>
               {GET_CART()
                 ? GET_CART().merchant_name
                   ? GET_CART().merchant_name
                   : GET_CART().item[0].merchant
                   ? GET_CART().item[0].merchant
                   : ""
                 : ""}
             </div>
             <Button
               href="/home/"
               force
               iconMd="material:close"
               className={styles["header-btn"]}
             />
           </div>
         </div> */}
         <Toolbar noShadow tabbar>
           {/* <Link 
            onClick={() => window.location.reload()} 
            // iconIos="f7:house_fill"
            // iconAurora="f7:house_fill"
            iconMd="material:directions_bike"
            tabLink href="./"
            routeTabId="view-delivery"
            text="Delivery" 
            /> */}
          <Link 
            onClick={() => window.location.reload()} 
            // iconIos="f7:house_fill"
            iconAurora="material:storefront"
            iconMd="material:storefront"
            tabLinkActive
            tabLink href="./" 
            routeTabId="view-pickup" 
            text="Pick Up"
            />

            {/* {this.state.visibility_po==true && this.state.visibility_ready==true?<Link
            onClick={() => window.location.reload()} 
            // iconIos="f7:house_fill"
            // iconAurora="f7:house_fill"
            iconMd="material:restaurant"
            tabLink href="dinein/" 
            routeTabId="view-dinein"
            text="Dine In"
            />:"" } */}
          {/* <Link
            tabLink
            href="preorder/"
            routeTabId="view-preorder"
            text="Pre order"
          /> */}
         </Toolbar>
         <Tabs>
           {/* <Tab className="page-content" id="view-delivery">
             <DeliveryTab router={this.$f7router} />
           </Tab> */}
         <Tab className="page-content" id="view-pickup">
            {
              this.props.merchant_id && 
              <PickupTab router={this.$f7router} merchant_id={this.props.merchant_id}/>
            }
            {
              !this.props.merchant_id && 
              <PickupTab router={this.$f7router}/>
            }
          </Tab>
          {/* <Tab className="page-content" id="view-dinein">
            <DineTab router={this.$f7router} />
          </Tab> */}
         </Tabs>
       </Page>
     );
   }
 }