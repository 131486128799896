import React from "react";
import styles from "./styles.module.less";
import { Page, Navbar, Button, getDate } from "framework7-react";
import OrderSummary from "./components/order-summary";
import PaymentDetails from "./components/payment-details";
import OrderTimeline from "./components/order-timeline";
import DeliveryMethod from "./components/delivery-method";
import DeliveryAddress from "./components/delivery-address";
import DeliveryFrom from "./components/delivery-from";
import { GET_TOKEN, API_URL } from "../../../js/global";

import Rating from "./components/rating";
import { GET_CART } from "../../../js/cart2";
import { resetWarningCache } from "prop-types";

export default class extends React.Component {
  constructor() {
    super();
    // const idMerchant = this.state.data.seller_id;
    this.state = {
      merchant: {},
      results: [],
      data: false,
      payment_methods: [],
      isLoading: true,
      showTimeline: false,
      openRating: false,
      trial: 0,
      onReady: false,
      payment_name: "",
    };
  }

  getPaymentMethod() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      //Load Products API
      f7.request.promise
        .get(API_URL + "/v1/payment-method?limit=5", null, "json")
        .then(async (res) => {
          let data = res.data.data;
          this.setState({
            ...this.state,
            payment_methods: data,
          });
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      this.getPaymentMethod();
      f7.preloader.show();
      let today = new Date();
      //Load Merchants API
      f7.request
        .promise({
          url:
            API_URL +
            "/v1/history/" +
            this.props.id +
            "?include=transaction_progress,transaction_details,transaction_fee,merchant_lots,transaction_coupon",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.setState({
            ...this.state,
            data: data,
            isLoading: false,
          });
          console.log("detail", data);
          f7.preloader.hide();
          this.onGetReady();

          let payment = this.state.payment_methods;
          for (var key in payment) {
            if (payment[key].id == this.state.data.payment_method_id) {
              let payment_name = payment[key].value_string;
              let payment_key = payment[key].key;
              this.setState({
                ...this.state,
                payment_name: payment_name,
                payment_key: payment_key,
              });
            }
          }
          const merchant_id = this.state.data.seller_id;
          f7.request.promise
            .get(
              API_URL +
                "/v1/merchant/" +
                merchant_id +
                "?include=bank_informations",
              null,
              "json"
            )
            .then((res) => {
              const data = res.data.data;
              this.setState({
                ...this.state,
                merchant: data,
                address: {
                  ...this.state.address,
                  latitude: res.data.data.latitude,
                  longitude: res.data.data.longitude,
                },
              });
              this.getAddressMerchant(data);
              f7.preloader.hide();
            })
            .catch((err) => {
              console.log("err", err);
              f7.preloader.hide();
            });
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  getAddressMerchant(data) {
    const app = this.$f7;
    this.$request
      .promise({
        url:
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          data.latitude +
          "," +
          data.longitude +
          "&key=AIzaSyAsjlt67715gCmWurm0N9pko7Ja2O3SSqc",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
      })
      .then((res) => {
        const data = res.data.results;
        this.setState({
          ...this.state,
          results: data,
        });
      });
  }

  onGetReady() {
    const today = new Date();
    const today_date = today.getDate();
    const pickup = this.state.data.description.pickup_time;
    const pickup_date = new Date(pickup);
    const pickupDate = pickup_date.getDate();
    if (pickupDate <= today_date) {
      this.setState({ ...this.state, onReady: true });
    } else {
    }
  }

  openTab(url) {
    window.location.href = url;
  }
  getpaymentUrl() {
    const FoodId = this.props.id;
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/get",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: FoodId,
        },
      })
      .then((res) => {
        this.openTab(res.data.data.url.WebUrl);
      })
      .catch((err) => {
        // console.log(err)
        if (this.state.trial >= 3) {
          throw err;
        }
        this.setState({
          trial: this.state.trial + 1,
        });
        this.getpaymentUrl();
      });
  }

  onClickCompleteOrder() {
    const preloader = this.$f7.preloader;
    const alert = this.$f7.dialog.alert;

    preloader.show();
    this.$request
      .promise({
        url: API_URL + "/v1/complete-order/" + this.state.data.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
      })
      .then((res) => {
        const message = res.data.message;
        preloader.hide();
        if (this.state.data.description.type_transaction == "po") {
          alert(message, () => this.$f7router.navigate("/home/"));
        } else {
          alert(message, () => {
            this.onOpenRating();
          });
        }
      })
      .catch((err) => {
        preloader.hide();

        // alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  async createCryptoPayment(id) {
    const app = this.$f7;
    app.preloader.show();
    this.$request
      .promise({
        url: API_URL + "/v1/payment/qoinpay/create",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_no: id,
        },
      })
      .then((res) => {
        app.preloader.hide();
        window.location.href = CRYPTO_REDIRECT_URL + "?data="+res.data.data.token;
      })
      .catch((err) => {
        app.preloader.hide();
        if (this.state.trial >= 3) {
          throw err;
        }
        this.setState({
          trial: this.state.trial + 1,
        });
        this.createCryptoPayment(id);
      });
  }

  onClickConfirmOrder() {
    const preloader = this.$f7.preloader;
    const alert = this.$f7.dialog.alert;
    const FoodId = this.props.id;

    preloader.show();
    this.$request

      .promise({
        url: API_URL + "/v1/order/payment/create",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_id: FoodId,
        },
      })
      .then(async (res) => {
        const message = res.data.message;
        let item = res.data.data;
        let id = 0;
        let payment = this.state.payment_methods;

        if (this.state.payment_key == "frodowallet") {
          this.$f7router.navigate("/password/" + FoodId + "/");
        }
        if (this.state.payment_key == "va_bca") {
          this.$f7router.navigate("/bank-info/" + FoodId + "/");
        }
        if (this.state.payment_key == "qoin") {
          this.getpaymentUrl();
        }
        if (this.state.payment_key == "qoin-crypto") {
          this.createCryptoPayment();
        }

        // for(var key in payment){
        //   if(payment[key].key=="va_bca"){
        //     id=payment[key].id
        //   }
        // }

        //   if(id== this.state.data.payment_method_id){
        //     this.$f7router.navigate(
        //       "/bank-info/"+ FoodId+"/"
        //     );
        //   }
        //   else{
        //     this.getpaymentUrl()
        //   }

        preloader.hide();
        // this.$f7router.navigate("/home/");
      });
  }

  onOpenRating() {
    this.setState({ ...this.state, openRating: true });
  }

  onRatingClose() {
    this.setState({ ...this.state, openRating: false });
    this.$f7router.navigate("/home/");
  }

  onRated() {
    const alert = this.$f7.dialog.alert;

    alert("Thank you!", () => {
      setTimeout(() => {
        this.setState({ ...this.state, openRating: false });
      }, 100);
      this.$f7router.navigate("/home/");
    });
  }

  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  render() {
    const color = () => { 
      if (this.state.data.order_status == "Delivery") {
        return "Rectangle-blue"
      }else if (this.state.data.order_status == "Waiting for Delivery") {
        return "Rectangle-green"
      }else if (this.state.data.order_status == "Waiting for Payment") {
        return "Rectangle-red"
      }else if (this.state.data.order_status == "Waiting for Pickup") {
        return "Rectangle-yellow"
      }else{
        return "Rectangle-yellow"
      }
    }
    return (
      <Page style={{ visibility: this.state.data ? "visible" : "collapse" }}>

        <div style={{ display: "flex",margin:20 }}>
          <div className={styles[color()]}>
            <div className={styles["text111"]}>
              {this.state.data.order_status}
            </div>
          </div>
          
          {/* <div style={{ flex: 1 }} />
          <Button
            style={{ display: "flex", marginBottom: "10px" }}
            iconMd={
              this.state.showTimeline
                ? "material:arrow_drop_down"
                : "material:arrow_drop_up"
            }
            iconSize="35px"
            color="black"
            onClick={() =>
              this.setState({
                ...this.state,
                showTimeline: !this.state.showTimeline,
              })
            }
          /> */}
        </div>
        {/* <div
          style={{
            display: this.state.showTimeline ? "block" : "none",
          }}
        >
          <OrderTimeline
            delivery_detail={this.state.data.delivery_detail}
            data={this.state.data.transaction_progress}
          />
        </div> */}

        {/* <div className="mk-line-break medium" />
        <DeliveryFrom
          results={this.state.results}
          data={this.state.data}
          merchant={this.state.merchant}
          openInNewTab={(url) => this.openInNewTab(url)}
        /> */}

        <div className="mk-line-break medium" />
        <DeliveryAddress
          results={this.state.results}
          data={this.state.data}
          merchant={this.state.merchant}
          openInNewTab={(url) => this.openInNewTab(url)}
        />
        <div
          style={{
            visibility:
              this.state.data.order_status_code == 3 ? "visible" : "none",
          }}
        >
          <div className="mk-line-break medium" />
          <DeliveryMethod
            data={this.state.data.delivery_detail}
            foto={this.state}
            openInNewTab={(url) => this.openInNewTab(url)}
          />
          </div>
          <div className="mk-line-break medium" />
          <OrderSummary data={this.state.data.transaction_details} />
        <div className="mk-line-break medium" />
        <PaymentDetails data={this.state.data} allData={this.state} />
        <div
          className="mk-footer"
          style={{
            visibility:
              this.state.data.order_status_code == 2 ? "visible" : "collapse",
          }}
        >
          <Button
            fill
            className="mk-button"
            style={{ margin: "0px 30px 0px 30px", alignSelf: "center" }}
            onClick={() => this.onClickConfirmOrder()}
          >
            PAY
          </Button>
        </div>

        <div
          className="mk-footer"
          style={{
            visibility:
              this.state.data.order_status_code == 3 ? "visible" : "collapse",
          }}
        >
          <Button
            fill
            className="mk-button"
            style={{ margin: "0px 30px 0px 30px", alignSelf: "center" }}
            onClick={() => this.onClickCompleteOrder()}
          >
            Complete Order
          </Button>
        </div>

        <div className={styles["wrapper"]}>{this.state.payment_name}</div>
        <div
          className="mk-footer"
          style={{
            visibility:
              this.state.data.order_status_code == 5
                ? "visible"
                : "collapse",
          }}
        >
          <Button
            fill
            className="mk-button"
            style={{ margin: "0px 30px 0px 30px", alignSelf: "center" }}
            onClick={() => this.onClickCompleteOrder()}
          >
            Complete Order
          </Button>
        </div>

        <div style={{ marginTop: "100px" }} />
      </Page>
    );
  }
}
