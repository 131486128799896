import React from "react";
import { Page, Button, Input, List, f7,Accordion } from "framework7-react";
import { IcoDown,IcoCopy,IcoUp } from "../../../assets/transaction";
import { API_URL,GET_TOKEN } from "../../../js/global";
import styles from "./styles.module.less";
import { REMOVE_CART, UPDATE_CART, SET_CART } from "../../../js/cart2";
import moment from "moment";

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      phone: "",
      photo: "",
      img:"",
      item:{},
      transaction_detail:{},
      amount:0,
      order_id:0,
      toggle1: false,
      toggle2: false,
      toggle3: false,
      data: {
        key:"",
        name:"",
        fullName: "",
        va_number:"",
      },
    }

    // this.handleChange = this.handleChange.bind(this);
  }
  
 
  toggle1(event) {
    this.setState((prevState) => ({
      toggle1: !prevState.toggle1
    }));
  }
  toggle2(event) {
    this.setState((prevState) => ({
      toggle2: !prevState.toggle2
    }));
  }
  toggle3(event) {
    this.setState((prevState) => ({
      toggle3: !prevState.toggle3
    }));
  }

 bca_va(){
  const FoodId = this.props.id;
  this.$f7ready((f7) => {
    f7.preloader.show();
    
    //Load Products API
    f7.request.promise({
      url: API_URL + "/v1/payment/bca-va/create" ,
      method: "POST",
      contentType: "application/json",
      dataType: "json",
      headers: {
        Authorization: GET_TOKEN(),
      },
      data: {
        "order_id" : FoodId,
        "order_type": Number(this.props.id) ? "order" : "order_group"
      },
    })
      .then(async (res) => {
        let data = res.data.data;

        // this.GetTransaction();
        this.setState({
          ...this.state,
          data: data,
        });
        f7.preloader.hide();
      })
      .catch((err) => {
        console.log("err", err);
        f7.preloader.hide();
      });
  });
 }
 copyCode()  {
  // const number = this.state.data.va_number
  // this.state.data.va_number.select();
  // document.execCommand("copy");
  navigator.clipboard.writeText(this.state.data.va_number)
  this.$f7.dialog.alert("copied succes");
}

componentDidMount() {
  this.$f7ready((f7) => {
    f7.preloader.show();
    
    //Load Products API
    f7.request
        .promise({
          url: API_URL + "/v1/profile",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data.photo
          this.setState({
            ...this.state,
            fullName: res.data.data.full_name,
            phone: res.data.data.phone,     
            isLoading: false,
          });
          this.bca_va();
          Number(this.props.id) ? this.GetTransactionOrder():this.GetTransactionOrder_Group()
          f7.preloader.hide();
        })
        .catch((err) => {
          // console.log("err", err);
          f7.preloader.hide();
        });
  });
}
GetTransactionOrder_Group(){
  const FoodId = this.props.id;
  this.$f7ready((f7) => {
    f7.preloader.show();
    
    //Load Products API
    f7.request
        .promise({
          url:
            API_URL +"/v1/order-group/detail?order_id="+this.props.id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
      .then(async (res) => {
        let data = res.data.data;

        this.setState({
          ...this.state,
          transaction_detail:data,
        });
        const fee = this.state.transaction_detail.fee
        let total_fee=0;
        fee.map(v => {
          total_fee += parseInt(v.amount)
          
        })
        const items = this.state.transaction_detail.items
        let total_items=0;
        items.map(v => {
          total_items += parseInt(v.amount)
        })
        const total_price = total_fee + total_items
        this.setState({
          ...this.state,
          amount:total_price,
        });
        f7.preloader.hide();
      })
      .catch((err) => {
        console.log("err", err);
        f7.preloader.hide();
      });
  });
 }

 GetTransactionOrder(){
  const FoodId = this.props.id;
  this.$f7ready((f7) => {
    f7.preloader.show();
    
    //Load Products API
    f7.request
        .promise({
          url:
            API_URL +
            "/v1/history/" +
            this.props.id +
            "?include=transaction_progress,transaction_details,transaction_fee",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
      .then(async (res) => {
        let data = res.data.data;
        let amount = res.data.data.total_amount;

        this.setState({
          ...this.state,
          transaction_detail:data,
          amount:amount,
        });
        
        f7.preloader.hide();
      })
      .catch((err) => {
        console.log("err", err);
        f7.preloader.hide();
      });
  });
 }


  render() {
   
    return (
      <Page>
       <div className={styles["header"]}>
         <Button
            href ="/home/"
            iconMd="material:close"
            className={styles["header-btn"]}
          />
          <div className={styles["header-name"]}>
            {this.state.data.name}
          </div>
       </div>
       <div className={styles["content"]}>
        <div className={styles["message-txt"]}>
          {"Hi," + " "+this.state.fullName}
          </div>
          <div className={styles["sub-message-txt"]}>
          Pesanan anda telah di terima
          </div>
          <div className={styles["sub-message"]}>
          Terima kasih telah membeli
          </div>
          <div className={styles["sub-message"]}>
          makanan di frodo, kami akan
          </div>
          <div className={styles["sub-message"]}>
          akan menyiapkan makanan anda segera
          </div>
          <div className={styles["sub-message"]}>
          setelah pembayaran... 
          </div>

       </div>
       <div className="mk-line-break medium" />
       <div className={styles["content-txt"]}>
          {"Nomor" + " "+this.state.data.name}
         </div>
         <div style={{display:"flex",justifyContent:"center"}}>
         <div className={styles['main-txt']}>
           {this.state.data.va_number}
           
         </div>
         <Button onClick={() => this.copyCode()}>
            <img className={styles["icon-copy"] }src={IcoCopy}></img>
          </Button>
         </div>
         <div className={styles['amount-content']}><div className={styles["content-txt"]}>
          Total Amount  Rp {Intl.NumberFormat().format(this.state.amount)}
         </div>
    </div>       
       <div className="mk-line-break medium" />
       <div className={styles["sub-content"]}>
         <div className={styles["sub-txt"]}>Petunjuk Pembayaran</div>
         <Button className={styles["method-btn"]} onClick={(event) => this.toggle1(event)}>
           <div>ATM</div>
          {this.state.toggle1? 
          <img className={styles["icon-up"] }src={IcoUp}></img>:<img className={styles["icon-copy"] }src={IcoDown}></img>}
          </Button>
          <div className={styles["panel"]} style={this.state.toggle1 ? display : hide} >
            <ul>
              <li>Masukkan kartu ATM dan PIN BCA Anda</li>
              <li>Pilih menu Transaksi Lainnya</li>
              <li>Pilih menu Transfer</li>
              <li>Pilih menu Ke Rek BCA Virtual Account</li>
              <li>Masukkan 13222 + nomor ponsel Anda</li>
              <li>13222 + 08xx-xxxx-xxxx </li>
              <li>Masukkan nominal top-up</li>
              <li>Ikuti instruksi untuk menyelesaikan transaksi </li>
            </ul>
          </div>
          
          <Button className={styles["method-btn"]} onClick={(event) => this.toggle2(event)}>
          <div>M-BANKING</div>
          {this.state.toggle2? 
          <img className={styles["icon-up"] }src={IcoUp}></img>:<img className={styles["icon-copy"] }src={IcoDown}></img>}
          </Button>
          <div className={styles["panel"]} style={this.state.toggle2 ? display : hide} >
            <ul>
            <li>Login ke akun m-BCA Anda</li>
            <li>Pilih menu m-Transfer</li>
            <li>Pilih BCA Virtual Account</li>
            <li>Masukkan 13222 + nomor ponsel Anda:</li>
            <li>13222 + 08xx-xxxx-xxxx</li>
            <li>Masukkan nominal top-up</li>
            <li>Ikuti instruksi untuk menyelesaikan transaksi</li>
            </ul>
          </div>
          <Button className={styles["method-btn"]} onClick={(event) => this.toggle3(event)}>
           <div>KLIK-BCA</div>
           {this.state.toggle3? 
          <img className={styles["icon-up"] }src={IcoUp}></img>:<img className={styles["icon-copy"] }src={IcoDown}></img>}
          </Button>
          <div className={styles["panel"]} style={this.state.toggle3 ? display : hide} >
            <ul>
              <li>Login ke akun Klik BCA Anda</li>
              <li>Pilih menu Transfer Dana</li>
              <li>Pilih Transfer ke BCA Virtual Account</li>
              <li>Masukkan 13222 + nomor ponsel Anda</li>
              <li>13222 + 08xx-xxxx-xxxx</li>
             <li>Masukkan nominal top-up</li>
              <li>Ikuti instruksi untuk menyelesaikan transaksi</li>
            </ul>
          </div>
       </div>
       <div>
       <div className={styles["container-btn-buy"]}>
        <Button className={styles["btn-order"]} text={"Done"} href={"/home/"}/>
        </div> 
      </div>
      </Page>
    );
  }
}
