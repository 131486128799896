import React from "react";
// import {
//   withGoogleMap,
//   GoogleMap,
//   withScriptjs,
//   InfoWindow,
//   Marker,
// } from "react-google-maps";
// import Geocode from "react-geocode";
import { BrowserRouter } from "react-router-dom";

import {
  Button,
  List,
  Input,
  Page,
  Icon,
  Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  f7,
} from "framework7-react";
import NewAddress from "../../../../../new-address";
import { GET_USER_ADDRESS, SET_USER_ADDRESS } from "../../../../../../../js/global";
// Geocode.setApiKey("AIzaSyAsjlt67715gCmWurm0N9pko7Ja2O3SSqc");
// Geocode.enableDebug();
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
    this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    // Geocode.fromLatLng(
    //   this.state.mapPosition.lat,
    //   this.state.mapPosition.lng
    // ).then(
    //   (response) => {
    //     // console.log("get user addres", GET_USER_ADDRESS());
    //     const address = response.results[0].formatted_address.replace("/", ""),
    //       addressArray = response.results[0].address_components,
    //       city = this.getCity(addressArray),
    //       area = this.getArea(addressArray),
    //       state = this.getState(addressArray);
    //     this.setState({
    //       address: address ? address : "",
    //       area: area ? area : "",
    //       city: city ? city : "",
    //       state: state ? state : "",
    //     });
    //     const objAddress = {
    //       city: city,
    //       details: address,
    //       area: area,
    //       state: state,
    //       country: "Indonesia",
    //       latitude: this.state.mapPosition.lat,
    //       longitude:this.state.mapPosition.lng,
    //       postal_code: null,
    //       province: null,
    //       subdistrict:null,
    //     };

    //     SET_USER_ADDRESS(objAddress);
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     this.state.markerPosition.lat !== this.props.center.lat ||
  //     this.state.address !== nextState.address ||
  //     this.state.city !== nextState.city ||
  //     this.state.area !== nextState.area ||
  //     this.state.state !== nextState.state
  //   ) {
  //     return true;
  //   } else if (this.props.center.lat === nextProps.center.lat) {
  //     return false;
  //   }
  // }
  // /**
  //  * Get the city and set the city input value to the one selected
  //  *
  //  * @param addressArray
  //  * @return {string}
  //  */
  // getCity(addressArray) {
  //   let city = "";
  //   for (let i = 0; i < addressArray.length; i++) {
  //     if (
  //       addressArray[i].types[0] &&
  //       "administrative_area_level_2" === addressArray[i].types[0]
  //     ) {
  //       city = addressArray[i].long_name;

  //       return city;
  //     }
  //   }
  // }
  // /**
  //  * Get the area and set the area input value to the one selected
  //  *
  //  * @param addressArray
  //  * @return {string}
  //  */
  // getArea(addressArray) {
  //   let area = "";
  //   for (let i = 0; i < addressArray.length; i++) {
  //     if (addressArray[i].types[0]) {
  //       for (let j = 0; j < addressArray[i].types.length; j++) {
  //         if (
  //           "sublocality_level_1" === addressArray[i].types[j] ||
  //           "locality" === addressArray[i].types[j]
  //         ) {
  //           area = addressArray[i].long_name;

  //           return area;
  //         }
  //       }
  //     }
  //   }
  // }
  // /**
  //  * Get the address and set the address input value to the one selected
  //  *
  //  * @param addressArray
  //  * @return {string}
  //  */
  // getState(addressArray) {
  //   let state = "";
  //   for (let i = 0; i < addressArray.length; i++) {
  //     for (let i = 0; i < addressArray.length; i++) {
  //       if (
  //         addressArray[i].types[0] &&
  //         "administrative_area_level_1" === addressArray[i].types[0]
  //       ) {
  //         state = addressArray[i].long_name;
  //         return state;
  //       }
  //     }
  //   }
  // }
  // /**
  //  * And function for city,state and address input
  //  * @param event
  //  */
  // onChange(event) {
  //   this.setState({ [event.target.name]: event.target.value });
  // }
  // /**
  //  * This Event triggers when the marker window is closed
  //  *
  //  * @param event
  //  */
  // onInfoWindowClose(event) {}

  // onPlaceSelected(place) {
  //   const address = place.formatted_address,
  //     addressArray = place.address_components,
  //     city = this.getCity(addressArray),
  //     area = this.getArea(addressArray),
  //     state = this.getState(addressArray),
  //     latValue = place.geometry.location.lat(),
  //     lngValue = place.geometry.location.lng();
  //   // Set these values in the state.
  //   this.setState({
  //     address: address ? address : "",
  //     area: area ? area : "",
  //     city: city ? city : "",
  //     state: state ? state : "",
  //     markerPosition: {
  //       lat: latValue,
  //       lng: lngValue,
  //     },
  //     mapPosition: {
  //       lat: latValue,
  //       lng: lngValue,
  //     },
  //   });
  // }
  // /**
  //  * When the marker is dragged you get the lat and long using the functions available from event object.
  //  * Use geocode to get the address, city, area and state from the lat and lng positions.
  //  * And then set those values in the state.
  //  *
  //  * @param event
  //  */

  // onMarkerDragEnd(event) {
  //   let newLat = event.latLng.lat(),
  //     newLng = event.latLng.lng();

  //   Geocode.fromLatLng(newLat, newLng).then(
  //     (response) => {
  //       const address = response.results[0].formatted_address.replace("/", ""),
  //         addressArray = response.results[0].address_components,
  //         city = this.getCity(addressArray),
  //         area = this.getArea(addressArray),
  //         state = this.getState(addressArray);
  //       let latValue = newLat,
  //         lngValue = newLng;
  //       this.setState({
  //         address: address ? address : "",
  //         area: area ? area : "",
  //         city: city ? city : "",
  //         state: state ? state : "",
  //         markerPosition: {
  //           lat: latValue,
  //           lng: lngValue,
  //         },
  //         mapPosition: {
  //           lat: latValue,
  //           lng: lngValue,
  //         },
  //       });

  //       const objAddress = {
  //         city: city,
  //         details: address,
  //         area: area,
  //         state: state,
  //         country: "Indonesia",
  //         latitude: this.state.mapPosition.lat,
  //         longitude:this.state.mapPosition.lng,
  //         postal_code: null,
  //         province: null,
  //         subdistrict:null,
  //       };

  //       SET_USER_ADDRESS(objAddress);
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }

  // render() {
  //   const defaultMapOptions = {
  //     fullscreenControl: false,
  //     streetViewControl: false,
  //     mapTypeControl: false,
  //   };
  //   <NewAddress data={this.state} />;

  //   const AsyncMap = withScriptjs(
  //     withGoogleMap((props) => (
  //       <GoogleMap
  //         google={this.props.google}
  //         defaultZoom={this.props.zoom}
  //         defaultCenter={{
  //           lat: this.state.mapPosition.lat,
  //           lng: this.state.mapPosition.lng,
  //         }}
  //         defaultOptions={defaultMapOptions}
  //       >
  //         <InfoWindow
  //           onClose={this.onInfoWindowClose}
  //           position={{
  //             lat: this.state.markerPosition.lat + 0.0003,
  //             lng: this.state.markerPosition.lng,
  //           }}
  //         >
  //           <div>
  //             <a
  //               href={
  //                 "/new-address/" +
  //                 this.state.markerPosition.lng +
  //                 "/" +
  //                 this.state.markerPosition.lat +
  //                 "/" +
  //                 this.state.address
  //               }
  //             >
  //               <span style={{ padding: 0, margin: 0, color: "black" }}>
  //                 {this.state.address}
  //               </span>
  //             </a>
  //           </div>
  //         </InfoWindow>
  //         <Marker
  //           google={this.props.google}
  //           name={""}
  //           draggable={true}
  //           onDragEnd={this.onMarkerDragEnd}
  //           position={{
  //             lat: this.state.markerPosition.lat,
  //             lng: this.state.markerPosition.lng,
  //           }}
  //         />
  //         <Marker />
  //       </GoogleMap>
  //     ))
  //   );

  //   let map;
  //   if (this.props.center.lat !== undefined) {
  //     map = (
  //       <div>
  //         <AsyncMap
  //           data={this.state}
  //           googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAsjlt67715gCmWurm0N9pko7Ja2O3SSqc&libraries=places"
  //           loadingElement={<div style={{ height: `100%` }} />}
  //           containerElement={<div style={{ height: "780px" }} />}
  //           mapElement={<div style={{ height: `100%` }} />}
  //         />
  //       </div>
  //     );
  //   } else {
  //     map = <div style={{ height: this.props.height }} />;
  //   }
  //   return map;
  // }
}
