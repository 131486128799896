import React from "react";
import { Page } from "framework7-react";
import styles from "./styles.module.less";
import MenuList from "../menu-list";

const lodash = require("lodash");

export default  (props) => {
  let data = [];

console.log("data drink", props.data);

  if (Object.keys(props.data).length !== 0) {
    const groupData = lodash.groupBy(
      props.data,
      "food_category[0].value_string"
    );

    for (var key in groupData) {
      data.push({
        category: key == "undefined" ? "Other" : key,
        foods: groupData[key],
      });
    }
  }

  return (
    // <Page>
      <div
      >
        {props.cart.merchant_id != null && props.data.length == 0?<div className={styles["txt-notfound"]}>Mohon Maaf Item Tidak Tersedia Saat Ini</div>:""}
        <MenuList
          onUpdateCart={(item, value) => props.onUpdateCart(item, value)}
          cart={props.cart}
          allData={props.allData}
          data={data}
          isOpen={props.isOpen}
          updateWishList={() => props.updateWishList()}
        />
      </div>
    // </Page>
  );
};
