import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.less";
import {
  Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  f7,
  Input,
  List,
  Icon
} from "framework7-react";
import { API_URL, GET_TOKEN,GET_USER_ADDRESS,
  SET_USER_ADDRESS,
  SET_USER_POSITION,
  GET_USER_POSITION, } from "../../../../../js/global";
import GoogleSearchLocation from "../../../../../components/customs/google-search-location";

export default (props) => {
  const [geoLocation, setGeoLocation] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [mainAddress, setMainAddress] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [currentLocationProps, setCurrentLocationProps] = useState("");

  const actionRef = useRef(null);

  useEffect(() => {
    getLocation();
  }, []);

  function getLocation() {
    if (GET_USER_ADDRESS()) {
      setCurrentLocation(GET_USER_ADDRESS().details);
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      f7.dialog.alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    SET_USER_POSITION({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    f7.request
      .promise({
        method: "GET",
        url:
          API_URL +
          "/v1/geocode/longlat?location=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&radius=500&type=address",
        dataType: "json",
        crossDomain: true,
      })
      .then((res) => {
        const location =
          res.data.results.length > 0 ? res.data.results[0].name : null;
        setGeoLocation(location);

        if (!GET_USER_ADDRESS()) setCurrentLocation(location);
      })
      .catch((err) => console.log("err", err));
  }


  function onUpdateLocation(address) {
    if (address.details) {
      setCurrentLocation(address.details);
      SET_USER_ADDRESS(address);
      props.onChangeAddress(address)
      setTimeout(() => {
      actionRef.current.close();
      }, 100);
    }
  }

  return (
    <div className={styles["container"]} onClick={() => actionRef.current.open()}>
      <div className={styles["row-1"]}>
        <div className={styles["col-2"]}>
          <div className={styles["txt-deliver"]}>Location</div>
          <div className={styles["location-wrapper"]}>
            <div className={styles["txt-location"]}>
            {currentLocation ? currentLocation : "Please choose your location"}
            </div>
            <div style={{ alignSelf: "center", flexShrink: 0 }}>
            </div>
          </div>
        </div>
      </div>
      <Actions ref={actionRef}>
        <ActionsGroup style={{
            height: "279px",
            backgroundColor: "white",
            borderRadius: "50px 50px 0px 0px",
            display: "flex",
          }}
        >
          <div style={{ width: "100%", margin: "15px 30px 0px 30px" }}>
            <div className={styles["rectangle"]} />
            <div className={styles["txt-deliver-to"]}>Deliver to</div>
          <GoogleSearchLocation
              style={{ marginTop: "20px" }}
              onPlaceSelect={(address) => onUpdateLocation(address)}
            />
          </div>
        </ActionsGroup>
      </Actions>
    </div>
  );
};
