import React from "react";
import { Page, Navbar } from "framework7-react";
import MessageList from "./components/message-list";
import MessageEmpty from "./components/message-empty";
import { API_URL,GET_TOKEN } from "../../../js/global";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();
      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }
      f7.request
        .promise({
          url: API_URL + "/v1/notification?limit=5&page=",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data;
          f7.preloader.hide();
          this.setState({
            ...this.state,
            data: data,
          });
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
      
    });
  
  }

  render() {
    return (
      <Page name="category">
        <Navbar large backLink="back" title="Inbox" />
        {this.state.data.length == 0 ? (
          <MessageEmpty />
        ) : (
          <MessageList data={this.state.data} />
        )}
      </Page>
    );
  }
}
