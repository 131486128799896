import {GET_PRODUCT} from "./network";
import {GET_PO_PRODUCT} from "./network";

export const INDEX_CART = (merchantId,foods_data) => 
    new Promise((result, error) => {
        CreateIndexCart(merchantId,foods_data)
        .then((res) => {
            let data = res;
            console.log("RES"+JSON.stringify(res))
            let cart = JSON.parse(window.localStorage.getItem('user_cart'))
            
            if(cart) {
                if(cart.merchant_id != merchantId || cart.is_preorder === true){
                    let newCart = {
                        merchant_id :merchantId,
                        total : 0,
                        quantity: 0,
                        notes:'',
                        item : [],
                    }
                    for (var key in data) {
                        newCart.item.push({...data[key], qty:0, notes:''})
                    }
                    cart = newCart;
                }
            } else {
                let newCart ={
                    merchant_id : merchantId,
                    total:0,
                    quantity : 0,
                    notes:"",
                    item:[],
                };
                for (var key in data) {
                    newCart.item.push({ ...data[key], qty:0, notes:''});
                }
                cart = newCart;
            }

            var saveData = JSON.stringify(cart);
            window.localStorage.setItem("user_cart", saveData);
            result(cart);
        })
        .catch((err) => error(err))
    })

const CreateIndexCart = (merchantId,foods_data) =>
    new Promise( async(result, error) => {
        try{
            let tempData = []

            const cartGroup = foods_data;
            const data = tempData.concat(cartGroup[0].value)

            result(data)
        }
        catch(err){
            console.log(err)
            error(err)
        }
    })

    export const SHOW_CART2 = (product, serve_as) => 
        new Promise((result, error) => {
            INDEX_CART(product.merchant_id).then((res) => {
                try{
                    let qty = 0;
                    let price = 0;
                    let cart = res;
                    let item = {};
                    let itemId = product.id;
                    console.log(serve_as)
                    if(cart) {
                        for(var key in cart.item){
                            let currItem = cart.item[key];
                            if(serve_as) {
                                if(currItem.id === itemId && currItem.type === product.type && currItem.serve_as === serve_as) {
                                    item =currItem;
                                    qty =currItem.qty;
                                    price = currItem.price * currItem.qty;
                                }
                            }else {
                                    if(currItem.id === itemId && currItem.type === product.type){
                                        item = currItem;
                                        qty = currItem.qty;
                                        price = currItem.price * currItem.qty;
                                    }
                                }
                            }
                        }
                        const data = {
                            item: item ,
                            qty : qty,
                            price: price,
                        };
                        result(data);
                    }  catch (err){
                        error(err);
                    }
            });
        });

        export const UPDATE_CART_WITHFOOD = (foods_data, product, value, notes, serve_as ='') => 
            new Promise((result, error) => {
                try {
                    let cart = JSON. parse(window.localStorage.getItem("user_cart"));

                    const itemId = product.id;
                    const typeP = product.type;
                    const serveAsP = serve_as !== '' ? serve_as : (product.serve_as?product.serve_as:'');

                    if(cart.item.length>0 && !cart.item[0].id){
                        cart.item=[];
                        for (var data in foods_data) {
                            cart.item.push({...foods_data[data], qty:0, notes:''})
                        }
                    }

                    let total = 0;
                    let quantity = 0;
                    for(var key in cart.item ){
                        let currItem = cart.item[key];
                        if(currItem.id === itemId && currItem.serve_as === serveAsP && currItem.type === typeP ) {
                            currItem.notes = notes?? currItem.notes;
                            currItem.qty = value;
                        }
                        total += currItem.qty * currItem.price;
                        quantity += currItem.qty;
                    }
                        cart.total= total;
                        cart.quantity = quantity;
                        cart.notes = notes;

                        var saveData = JSON.stringify(cart);
                        window.localStorage.setItem("user_cart", saveData);

                        result(cart);
                    }
                    catch(err) {
                        error(err);
                    }
                
            });

        export const UPDATE_CART = (product, value, notes, serve_as ='') => 
            new Promise((result, error) => {
                try {
                    let cart = JSON. parse(window.localStorage.getItem("user_cart"));

                    const itemId = product.id;
                    const typeP = product.type;
                    const serveAsP = serve_as !== '' ? serve_as : (product.serve_as?product.serve_as:'');

                    let total = 0;
                    let quantity = 0;
                    for(var key in cart.item ){
                        let currItem = cart.item[key];
                        if(currItem.id === itemId && currItem.serve_as === serveAsP && currItem.type === typeP ) {
                            currItem.notes = notes?? currItem.notes;
                            currItem.qty = value;
                        }
                        total += currItem.qty * currItem.price;
                        quantity += currItem.qty;
                    }
                        cart.total= total;
                        cart.quantity = quantity;
                        cart.notes = notes;

                        var saveData = JSON.stringify(cart);
                        window.localStorage.setItem("user_cart", saveData);

                        result(cart);
                    }
                    catch(err) {
                        error(err);
                    }
                
            });

export const REMOVE_CART = () => {
    window.localStorage.removeItem("user_cart");
};

export const GET_CART = () => {
    const cart = window.localStorage.getItem("user_cart");
    if(cart) {
        JSON.parse(cart);
    }
};

export const INFO_CART=()=>
    new Promise((result, error) => {
    try {
        let cart = JSON. parse(window.localStorage.getItem("user_cart"));
    result(cart)
    }
    catch(err) {
        error(err);
    }
});

export const SET_CART = (cart)=> {
    const cartJSON = JSON.stringify(cart);
    window.localStorage.setItem("user_cart", cartJSON);
}

export const SET_TABLE=(table)=>{
    const tableJSON =JSON.stringify(table);
    window.localStorage.setItem("user_table",tableJSON);
}

export const GET_TABLE=()=>
    new Promise((result, error) => {
    try {
        let table = JSON. parse(window.localStorage.getItem("user_table"));
    result(table)
    }
    catch(err) {
        error(err);
    }
});

export const REMOVE_TABLE = () => {
    window.localStorage.removeItem("user_table");
};
