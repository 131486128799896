import React from "react";
import { ImgBackground, IcoLogin } from "../../../assets/auth";
import { Button, List, Input, Page, Navbar } from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN } from "../../../js/global";
import { string } from "prop-types";
import ReactDOM from "react-dom";
import Parser from "html-react-parser";

var stringToHTML = function (str) {
  var dom = document.createElement("div");
  dom.innerHTML = str;
  return dom;
};

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();

      //Load Merchants API
      f7.request
        .promise({
          url: API_URL + "/v1/pages/detail/" + this.props.id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.setState({
            ...this.state,
            data: data,
            isLoading: false,
          });
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  render() {
    return (
      <Page>
        <Navbar backLink title="Promo"></Navbar>
        {Parser(this.state.data ? this.state.data.body : "")}
      </Page>
    );
  }
}
