import React, { useState, useEffect, useRef }  from "react";


import { Button, List, Input, Page, Icon,Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  f7, } from "framework7-react";
import styles from "./styles.module.less";
import Map from "./components/location";
import { GET_USER_POSITION  } from "../../../../../js/global";

export default class extends React.Component {
  constructor( props ){
    super( props );
    this.state = {
    data:{},
    test:1,
    }
   }
   
  componentDidMount(){
  const loc = GET_USER_POSITION()
  this.setState({ 
   ...this.state,
  data:loc,
 });
  }

  render() {

    return (
      <Page name="login">
         <Button
            back
            iconMd="material:arrow_back_ios"
            className={styles["btn-back"]}
            style={{ margin: "5px 0px 0px 5px"}}
          />{this.state.data.longitude == null ?"":
              <div style={{ marginTop: "45px"}}>
              <Map
    
     google={this.props.google}
     center={{lat: this.state.data.latitude, lng: this.state.data.longitude}}
     data={this.state}
    //  max-height="100px"
    //  height='700px'
     zoom={18}
    />
    </div>}
      </Page>
    );
  }
}
