import React from "react";
import styles from "./styles.module.less";
import moment from "moment";
import { IcoPhone, IcoMaps } from "../../../../../assets/transaction";
export default function index(props) {
  const data = props.data ? props.data : null;
  const merchant = props.merchant;
  const alamat = props.results[0]?.formatted_address;
  if (!data) return <div />;
  let method;
  if(data.description.pickup_time == null && data.description.address ==null){
    method =2
  }
  else if(data.description.pickup_time== null && data.merchant_lots==null){
    method=0
  }
  else{
    method=1
  }
  return (
    <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
      {
        method == 1 ? 
        <div> 
          <div className={styles["title"]}>Pickup Time</div>
          <div className={styles["txt-address"]}>{moment(data.description.pickup_time).format("LLL") }</div>
          <div className={styles["title"]}>Pickup Location</div>
          <a onClick={() =>props.openInNewTab("http://maps.google.com/?q="+
          merchant.latitude+","+merchant.longitude) }>
          <div className={styles["txt-contact"]}>
          <img className={styles["img-maps"]} src={IcoMaps}/>
          {data.seller_name }</div>
          <div className={styles["txt-contact"]}>{alamat}</div></a>
          <div className={styles["txt-contact"]}>{}</div>
          {/* {data.order_status_code !=99 && data.order_status_code !=88 ? 
          <div className={styles["txt-contact"]} onClick={()=> window.open("https://wa.me/6287778988998?text=Hello%20Frodo")}>
            <img className={styles["img-phone"]} src={IcoPhone}/>Contact Me
          </div>:""}  */}
        </div>
        : method == 0 ?
        <div>
          <div className={styles["title"]}>Delivery Address</div>
          <div className={styles["txt-address"]}>{data.description.address.details}</div>
          <div className={styles["txt-address"]}>{data.description.note ? "Note :" + " " +  data.description.note : "-"}</div>
          {data.order_status_code !=99 && data.order_status_code !=88 ? 
          <div className={styles["txt-contact"]} onClick={()=> window.open("https://wa.me/6287778988998?text=Hello%20Frodo")}><img className={styles["img-phone"]} src={IcoPhone}/>Contact Me</div>:""} 
        </div>
        : <div>
          <div className={styles["title"]}>Dine In Table</div>
          <div className={styles["txt-address"]}>{data.merchant_lots.table_name}</div>
          <div className={styles["txt-address"]}>{data.description.note ? "Note :" + " " +  data.description.note : "-"}</div>
          {data.order_status_code !=99 && data.order_status_code !=88 ? 
          <div className={styles["txt-contact"]} onClick={()=> window.open("https://wa.me/6287778988998?text=Hello%20Frodo")}><img className={styles["img-phone"]} src={IcoPhone}/>Contact Me</div>:""} 
        </div> 
      }
    </div>
  );
}