import React from "react";
import { Button, Page, Navbar, Searchbar } from "framework7-react";
import PreorderList from "./components/preorder-list";
import styles from "./styles.module.less";
import { API_URL,GET_USER_POSITION } from "../../../js/global";
 

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: this.props.s,
      sortByNearest: 0,
      sortByRating: 0,
      sortByPrice: 0,
      item: [],
      merchant_id: 0,
      quantity: 0,
      total: 0,
      current_page : 1,
      data_length : 0,
      total_pages : 0,
      limit : 10,
      has_more : false,
      isLoading: false
    };
		this.fetchData = this.fetchData.bind(this);
  }

  onClickByNearest() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 1,
      sortByPrice: 0,
      sortByRating: 0,
    });
    this.$f7router.navigate("/preorderpage/" + "near_by/" + s + "/", {
      animate: false,
    });
  }

  onClickByRating() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 0,
      sortByPrice: 0,
      sortByRating: 1,
    });
    this.$f7router.navigate("/preorderpage/" + "rating/" + s + "/", {
      animate: false,
    });
  }

  onClickByPrice() {
    const s = this.props.s ? this.props.s : "";
    this.setState({
      ...this.state,
      sortByNearest: 0,
      sortByPrice: 1,
      sortByRating: 0,
    });
    this.$f7router.navigate("/preorderpage/" + "cheapest/" + s + "/", {
      animate: false,
    });
  }

  componentDidMount() {
    const sortBy = this.props.sort_by ? this.props.sort_by : "";
    const s = this.props.s ? this.props.s : "";
    switch (sortBy) {
      case "near_by":
        this.setState({ ...this.state, sortByNearest: 1 });
        break;
      case "rating":
        this.setState({ ...this.state, sortByRating: 1 });
        break;
      case "cheapest":
        this.setState({ ...this.state, sortByPrice: 1 });
        break;
    }

    this.$f7ready((f7) => {
      f7.preloader.show();
      let position;
      if (GET_USER_POSITION()) {
        position =
          GET_USER_POSITION().latitude + "," + GET_USER_POSITION().longitude;
      }
      // Load Preorder API
      f7.request.promise
        .get(
          API_URL + "/v1/pre-order/product?limit="+this.state.limit +
          "&page=" + this.state.current_page +"&"+(position ? `coordinates=${position}` : "")+"&"+ "s=" +
          s +
          "&sort_by=" +
          sortBy,
          null,
          "json"
        )
        .then((res) => {
          console.log("res", res.data.data);
          this.setState({ 
            ...this.state, 
            data: res.data.data,
            total_pages : res.data.paginator.total_pages,
            has_more : this.state.current_page < res.data.paginator.total_pages ? true : false,
            data_length : res.data.paginator.total_items
          });
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  onChangeQty(value) {
    const item = this.state.data.item;

    const stateData = {
      ...this.state,
      data: {
        item: item,
        quantity: value,
        total: value * item[0].price,
        is_preorder: true,
      },
    };

    console.log("stateData", stateData.data);
    this.setState(stateData);
    UPDATE_CART(stateData.data.item[0], value);
  }

  onSubmitSearch() {
    this.$f7router.navigate("/preorderpage/" + "new/" + this.state.search + "/", {
      animate: false,
    });
  }

  fetchMoreData (){
    const page = this.state.current_page + 1

     if(this.state.has_more) { 
      this.fetchData(page)
      this.setState({current_page: page });
      console.log("FETCH MORE DATA", this.state);
      }
      this.setState({has_more : this.state.current_page < this.state.total_pages})
      console.log("FETCH MORE DATA false", this.state);
    }

  fetchData(page) {
    this.setState({isLoading:true})
    const sortBy = this.props.sort_by ? this.props.sort_by : "";
    const s = this.props.s ? this.props.s : "";
    switch (sortBy) {
      case "near_by":
        this.setState({ ...this.state, sortByNearest: 1 });
        break;
      case "rating":
        this.setState({ ...this.state, sortByRating: 1 });
        break;
      case "cheapest":
        this.setState({ ...this.state, sortByPrice: 1 });
        break;
    }

    this.$f7ready((f7) => {
      let position;
      if (GET_USER_POSITION()) {
        position =
          GET_USER_POSITION().latitude + "," + GET_USER_POSITION().longitude;
      }
      f7.request.promise
        .get(
          API_URL + "/v1/pre-order/product?limit="+this.state.limit +
          "&page=" +page +"&"+(position ? `coordinates=${position}` : "")+"&"+ "s=" +
          s +
          "&sort_by=" +
          sortBy,
          null,
          "json"
        )
        .then((res) => {
          this.setState({ 
            ...this.state,
            has_more : page < res.data.paginator.total_pages ? true : false,
            data: this.state.data.concat(res.data.data),
            isLoading:false
          });
          
        })
        .catch((err) => {
          console.log("err", err);
          this.setState({has_more : false,isLoading:false})
        });
    });
  }

  render() {
    return (
      <Page 
      name="search-page"
      infinite
      infiniteDistance={100}
      infinitePreloader={this.state.isLoading}
      onInfinite={()=>this.fetchMoreData()}>
        <Navbar backLink/>
        <div className={styles["title"]}> Preorder </div>
        <div style={{ marginRight: "10px", marginLeft: "10px" }}>
          <Searchbar
              value={this.state.search}
              onChange={(val) =>
                this.setState({ ...this.state, search: val.target.value })
              }
              onSubmit={() => this.onSubmitSearch()}
            />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "15px",
            }}
          >
            <Button
              iconMd="material:place"
              iconSize="medium"
              className={
                this.state.sortByNearest == 1
                  ? styles["btn-category-active"]
                  : styles["btn-category-inactive"]
              }
              fill={this.state.sortByNearest == 1}
              outline={this.state.sortByNearest == 0}
              onClick={() => this.onClickByNearest()}
            >
              <div style={{ marginLeft: "2px" }}>By Nearest Place</div>
            </Button>
            {/* <Button
              iconMd="material:star"
              iconSize="medium"
              className={
                this.state.sortByRating == 1
                  ? styles["btn-category-active"]
                  : styles["btn-category-inactive"]
              }
              fill={this.state.sortByRating == 1}
              outline={this.state.sortByRating == 0}
              onClick={() => this.onClickByRating()}
            >
              <div style={{ marginLeft: "2px" }}>By Rating</div>
            </Button> */}
            <Button
              iconMd="material:text_format"
              iconSize="medium"
              className={
                this.state.sortByPrice == 1
                  ? styles["btn-category-active"]
                  : styles["btn-category-inactive"]
              }
              fill={this.state.sortByPrice == 1}
              outline={this.state.sortByPrice == 0}
              onClick={() => this.onClickByPrice()}
            >
              <div style={{ marginLeft: "2px" }}>Cheapest</div>
            </Button>
          </div>
        </div>

        <PreorderList data={this.state.data}
          page={this.state}
          fetchMoreData={()=>this.fetchMoreData()}/>
      </Page>
    );
  }
}
