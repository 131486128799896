import React from "react";
import styles from "./styles.module.less";
import { IcoDelivery, IcoStar } from "../../../../../assets/home";
import { API_URL,GET_TOKEN,FormatImage } from "../../../../../js/global";
import { Button, Page, Navbar, Searchbar } from "framework7-react";

export default function index(props) {

  const data = props.data ? props.data : [];
  console.log("props po", props);
  
  return (
    <div>
          {props.data.length == 1 ? "":
      <div className={styles["nav"]}>
          <Navbar backLink backLinkForce backLinkUrl="/home/" />
          <div className={styles["head-txt"]}>Choose Your Pickup Location</div>
        </div>}
      {props.data.map((item, index) => {
        return (
          <a  href={"/preorder/" + item.id + "/" + item.pre_order_id + "/"}>
            {props.data.length == 1 ? "":
            <div className={styles["merchant-item"]}>
              <div style={{ display: "flex", marginBottom: "14.5px" }}>
                <img className={styles["img-product"]} src={FormatImage(item.photo,"thumb")} />
                <div className={styles["container-detail"]}>
                  <div className={styles["txt-name"]}>{item.name}</div>
                  {/* <div className={styles["txt-detail"]}>{item.description}</div> */}
                  <div className={styles["txt-detail"]}>
                    <img src={IcoStar} className={styles["ico"]} />
                    {item.rating} - Great Taste!
                  </div>
                  <div className={styles["txt-name"]}>{item.is_open}</div>
                </div>
              </div>
              <div className="mk-line-break"></div>
            </div>}
          </a>
        );
      })}
    </div>
  );
};
