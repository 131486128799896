import React from "react";
import { Page, Button, Input, List,f7 } from "framework7-react";
import { IcoLove, IcoPlusOval, IcoMinusOval } from "../../../assets/home";
import { IcoArrow } from "./../../../assets/merchant";
import { CRYPTO_REDIRECT_URL,API_URL,GET_TOKEN,GET_CART,REMOVE_CART,QOINPAY_ID } from "../../../js/global";
import { UPDATE_CART, SHOW_CART2 } from "../../../js/cart2";
import { IncreaseDecrease, ButtonCart, Share} from "../../../components/customs";
import styles from "./styles.module.less";
import moment from "moment";

export default class extends React.Component {
  constructor() {
    super();
    const idMerchant = GET_CART() ? GET_CART().merchant_id : "";
    this.state = {
      fullName: "",
      phone: "",
      pin_enabled: 0,
      methodDelivery: "",
      methodFood: "",

      isLoading: true,
      cart: {
        item: [],
      },
      cart_PO: {
        item: [],
      },
      pickup_time: {
        date: "",
        time: moment().format("HH:mm"),
      },
      voucher_codes: "",
      payment_methods: [],
      banks: [],
      trial: 0,
      pick_delivery_method: false,
      data: {
        note: "",
        Myvoucher: [],
        coupon: [],
        coupon_codes: "",
        payment_method: 1,
        before_payment: 1,
        shipment_method: null,
        merchant_id: idMerchant,
        name: "",
        photo: "",
        item: [],
        item_fee: [],
        toggle: false,
      },
      size: "",
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (GET_CART()) {
      this.setState({ ...this.state, cart: GET_CART() });
    }

    this.$f7ready((f7) => {
      // f7.preloader.show();

      this.$f7ready((f7) => {
        // f7.preloader.show();

        if (!GET_TOKEN()) {
          this.$f7router.navigate("/onboarding/");
        }

        //Load Merchants API
        f7.request
          .promise({
            url: API_URL + "/v1/profile",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              Authorization: GET_TOKEN(),
            },
          })
          .then((res) => {
            f7.preloader.hide();
            this.setState({
              ...this.state,
              fullName: res.data.data.full_name,
              phone: res.data.data.phone,
              // pin_enabled: res.data.data.pin_enabled,
              isLoading: false,
            });
          })
          .catch((err) => {
            console.log("err", err);
            f7.preloader.hide();
          });
      });
    });
    
  }

  radioChange(){
    console.log("CLICKED")
  }

  async getpaymentUrl(id) {
    const app = this.$f7;
    app.preloader.show();
    this.$request
      .promise({
        url: API_URL + "/v1/order/payment/get",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: { 
          order_id: id,
        },
      })
      .then((res) => {
        app.preloader.hide();
        var view = app.views.current;
        console.log(view.history)
        if(view.history.length>0){
          if(view.history[0].includes("home")){
            if(view.history.length > 0){
              view.router.back(view.history[0],{
                force:true
              });
            }
          }
          else if(view.history[0].includes("//")){
            if(view.history.length > 0){
              view.router.back(view.history[1],{
                force:true
              });
            }
          }
        }
      setTimeout(() => {
        window.location.href = res.data.data.url.WebUrl;
      }, 1000);
      })
      .catch((err) => {
        app.preloader.hide();
        if (this.state.trial >= 3) {
          throw err;
        }
        this.setState({
          trial: this.state.trial + 1,
        });
        this.getpaymentUrl(id);
      });
  }
  
  async createCryptoPayment(id) {
    const app = this.$f7;
    app.preloader.show();
    this.$request
      .promise({
        url: API_URL + "/v1/payment/qoinpay/create",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          order_no: id,
        },
      })
      .then((res) => {
        app.preloader.hide();
        window.location.href = CRYPTO_REDIRECT_URL + "?data="+res.data.data.token;
      })
      .catch((err) => {
        app.preloader.hide();
        if (this.state.trial >= 3) {
          throw err;
        }
        this.setState({
          trial: this.state.trial + 1,
        });
        this.createCryptoPayment(id);
      });
  }

  onClickOrderNowKripto() {
    const f7 = this;

      const cart = this.state.cart;
      let currItem = [];
      for (var key in cart.item) {
        if (cart.item[key].qty !== 0) {
          currItem.push({
            product_id: cart.item[key].id,
            qty: cart.item[key].qty,
            notes: cart.item[key].notes,
            type: cart.item[key].type,
            serve_as: cart.item[key].serve_as,
          });
        }
      }
      const currItemConstruct = _.chain(currItem)
        .groupBy("type")
        .map((val, key) => ({
          type: key,
          data: val,
        }))
        .value();

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          item: [...currItemConstruct],
          pickup_time: this.state.pickup_time,
        },
      });

      const app = this.$f7;
      const data = this.state.data;
      console.log("CHECK:"+JSON.stringify(cart))
      app.preloader.show();
      app.request
        .promise({
          url: API_URL + "/v1/take-away/order",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data: {
            item:data.item,
            address:data.address,
            // delivery_service_token:data.delivery_service_token,
            note:data.note,
            payment_method: QOINPAY_ID,
            // shipment_method:data.shipment_method,
            pickup_time:data.pickup_time,
            coupon_codes:this.state.voucher_codes,
            merchant_id:data.merchant_id,
          }
        })

        .then(async (res) => {
          //reqquest payment first
            app.preloader.hide();
            REMOVE_CART();
            this.createCryptoPayment(res.data.data.transaction.id);
            this.props.router.navigate(
              "/transactions/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
        })
        .catch((err) => {
          app.preloader.hide();
          app.dialog.alert(JSON.parse(err.xhr.responseText).message);
        });
  }

  onClickOrderNowNonKripto() {
    const f7 = this;

      const cart = this.state.cart;
      let currItem = [];
      for (var key in cart.item) {
        if (cart.item[key].qty !== 0) {
          currItem.push({
            product_id: cart.item[key].id,
            qty: cart.item[key].qty,
            notes: cart.item[key].notes,
            type: cart.item[key].type,
            serve_as: cart.item[key].serve_as,
          });
        }
      }
      const currItemConstruct = _.chain(currItem)
        .groupBy("type")
        .map((val, key) => ({
          type: key,
          data: val,
        }))
        .value();

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          item: [...currItemConstruct],
          pickup_time: this.state.pickup_time,
        },
      });

      const app = this.$f7;
      const data = this.state.data;
      app.preloader.show();
      app.request
        .promise({
          url: API_URL + "/v1/take-away/order",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
          data: {
            item:data.item,
            address:data.address,
            // delivery_service_token:data.delivery_service_token,
            note:data.note,
            payment_method:109,
            // payment_method:data.payment_method,
            // shipment_method:data.shipment_method,
            pickup_time:data.pickup_time,
            coupon_codes:this.state.voucher_codes,
            merchant_id:data.merchant_id,
          }
        })

        .then(async (res) => {
          //reqquest payment first
            app.preloader.hide();
            REMOVE_CART();
            this.getpaymentUrl(res.data.data.transaction.id);
            this.props.router.navigate(
              "/transactions/" +
                res.data.data.transaction_progress.transaction_id +
                "/"
            );
        })
        .catch((err) => {
          app.preloader.show();
          app.dialog.alert(JSON.parse(err.xhr.responseText).message);
        });
  }

  render() {
    return (
      <Page>
      <div className={styles["txt-title"]}>
        Payment Method
      </div>
      <div className={styles["content"]}>
      
      <Button style={{height:65}} onClick={(event) => this.onClickOrderNowKripto()}>
                  <div className={styles["input-wrap"]}>
                  <img
        src="https://tudungsaji-id.s3.ap-southeast-1.amazonaws.com/template/logo-qoinpay.png"
        style={{
          width: "70px",
          height: "35px",
          marginRight:"10px",
          marginLeft:"10px",
          marginTop:"5px",
          borderRadius: "5px",
          flexShrink: 0,
          objectFit: "contain",
        }}
      ></img>
                          <div className={styles["txt-wrap"]}>
                              <div className={styles["payment-txt"]}> Qoinpay</div>
                          </div>   
                  </div>
      </Button>

      <Button style={{height:65}} onClick={(event) => this.onClickOrderNowNonKripto()}>
                  <div className={styles["input-wrap"]}>
                  <img
        src="https://blog.elevenia.co.id/wp-content/uploads/2015/01/logo-CC.png"
        style={{
          width: "80px",
          height: "45px",
          marginRight:"10px",
          borderRadius: "5px",
          flexShrink: 0,
          objectFit: "contain",
        }}
      ></img>
                          <div className={styles["txt-wrap"]}>
                              <div className={styles["payment-txt"]}> Credit / Debit Card</div>
                          </div>   
                  </div>
      </Button>
      </div>
</Page>
    );
  }
}
