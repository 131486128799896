import React from "react";
import { App, View } from "framework7-react";
import routes from "../js/routes";

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      // Framework7 Parameters
      f7params: {
        name: "QoinCrypto-Shop", // App name
        theme: "aurora", // Automatic theme detection "auto" / "ios"

        // App routes
        routes: routes,

        // Register service worker
        serviceWorker: {
          path: "/service-worker.js",
        },
      },
    };
  }
  render() {
    return (
      <App
        params={this.state.f7params}
        style={{ maxWidth: "450px", margin: "auto" }}
      >
        <View pushState pushStateSeparator="#" />
      </App>
    );
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      // Call F7 APIs here
    });
  }
}
