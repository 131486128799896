import React from "react";
import styles from "./styles.module.less";
import { IcoDelivery, IcoStar } from "../../../../../assets/home";
import { FormatImage  } from "../../../../../js/global";
import { Button, Page, Navbar, Searchbar } from "framework7-react";
import moment from "moment";
  

export default (props) => {
  return (
    <div>
    {props.props.status == "ready-stock" ? <div>
      {props.data.map((item, index) => {
        return (
          <a href={item.stock == 0 || item.is_open== "closed"  ? " ": "/foods/" + item.type + "/" + item.id + "/" }>
          <div
            style={{
              filter: item.stock == 0 || item.is_open== "closed"  ? "grayscale(100%)" : ""
            }}
            >
            <div className={styles["merchant-item"]}>
              <div style={{ display: "flex", marginBottom: "14.5px" }}>
                <img className={styles["img-product"]} src={FormatImage(item.photo,"thumb")} />
                <div className={styles["container-detail"]}>
                  <div className={styles["txt-name"]}>{item.name}</div>
                  {/* <div className={styles["txt-detail"]}>
                    <img src={IcoStar} className={styles["ico"]} />
                    {item.rating} - Great Taste!
                  </div> */}
                  <div className={styles["txt-detail-price"]}>
                    Rp. {Intl.NumberFormat().format(item.price)}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </a>
        );
      })}
   {props.page.current_page == props.page.total_pages || props.page.data_length == 0 || props.page.isLoading == true? "" :<Button
      className={styles["btn-load-more"]}
      iconSize="medium"
      outline
      onClick={() => {props.fetchMoreData();}}
    >
      <div style={{ marginLeft: "2px" }}>Load More</div>
    </Button>}

    </div>:<div>
      {props.data.map((item, index) => {
        return (
          <a href={"/preorderlist/" + item.product_id + "/"}>
          <div
            // style={{
            //   filter: item.stock == 0 || item.is_open== "closed"  ? "grayscale(100%)" : "",
            // }}
            >
            <div className={styles["merchant-item"]}>
              <div style={{ display: "flex", marginBottom: "14.5px" }}>
                <img className={styles["img-product"]} src={FormatImage(item.photo,"thumb")} />
                <div className={styles["container-detail"]}>
                  <div className={styles["txt-name"]}>{item.name}</div>
                  <div className={styles["txt-detail"]}>
                    {/* <img src={IcoStar} className={styles["ico"]} />
                    {item.rating} - Great Taste! */}
                  </div>
                  <div className={styles["txt-detail-po"]}>
                    {/* <img src={IcoDelivery} className={styles["ico"]} /> */}
                    Start From {moment(item.pre_order_start_date).format("DD/MM/YYYY")} - {moment(item.pre_order_end_date).format("DD/MM/YYYY")} 
                  </div>
                  <div className={styles["txt-detail-po"]}>
                    {/* <img src={IcoDelivery} className={styles["ico"]} /> */}
                    Will Be Delivered On {moment(item.pre_order_delivery_date).format("DD/MM/YYYY")} 
                  </div>
                </div>
              </div>
              <div className="mk-line-break"></div>
            </div>
            </div>
          </a>
        );
      })}
   {props.page.current_page == props.page.total_pages || props.page.data_length == 0 || props.page.isLoading == true? "" :<Button
      className={styles["btn-load-more"]}
      iconSize="medium"
      outline
      onClick={() => {props.fetchMoreData();}}
    >
      <div style={{ marginLeft: "2px" }}>Load More</div>
    </Button>}

    </div>}
    </div>
  );
};

