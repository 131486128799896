import React from "react";
import styles from "./styles.module.less";

export default function index(props) {
  return (
    <div>
      {props.data.map((item, index) => {
        return (
          <a href={"/promo/" + item.descriptions.url_path + "/"}>
            <div className={styles["message-item"]}>
              <div style={{ display: "flex", marginBottom: "14.5px" }}>
                <div className={styles["img-message"]}>
                  <div className={styles["txt-img-message"]}>MK</div>
                </div>
                <div className={styles["container-detail"]}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div className={styles["txt-name"]}>
                      {item.descriptions.message_title}
                    </div>
                    <div className={styles["txt-date"]}>{item.date}</div>
                  </div>
                  <div className={styles["txt-detail"]}>
                    {item.descriptions.message_body}
                  </div>
                </div>
              </div>
              <div className="mk-line-break"></div>
            </div>
          </a>
        );
      })}
    </div>
  );
}
