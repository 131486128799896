import React from "react";
export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      search: "",
      loadedComponent: 0,
      wallet:true,
      result: "No result",
    };
        this.handleScan = this.handleScan.bind(this);

  }

  componentDidMount() {
    

  }
  


  render() {
    return (
    <Page name="category">
        <Navbar large backLink="back" title="Qr Scanner" />
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}
        />
        
        <p href={this.state.result}>{this.state.result}</p>
        </Page>
    );
  }
}
