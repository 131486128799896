import React from "react";
import styles from "./styles.module.less";
import { Page, Navbar, Button,getDate } from "framework7-react";
export default function index(props) {
  const data = props.data ? props.data : null;
  if (!data) return <div />;
  return (
    <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
      <div className={styles["title"]}>Delivery Method</div>
      <div style={{ display: "flex", marginTop: "11px" }}>
        <img
          style={{
            height: "40px",
            width: "40px",
            marginRight: "15px",
            backgroundColor: "gray",
          }}
          src={props.foto.data.seller_image}
        />
        <div className={styles["txt-style-1"]}>
          <div className={styles["column-text"]} >
          <div className={styles["txt-driver-name"]}>{props.data.courier_name}</div>
          <div className={styles["txt-driver-bike"]}>{props.data.plat_no}</div>
          </div>
          <Button className={styles["btn-tracking"]}
          onClick={() =>props.openInNewTab(props.data.tracking_url) }>
            Tracking
          </Button>
        </div>
      </div>
    </div>
  );
}
