import React, { Fragment } from "react";
import { Page, Navbar, Button, f7 } from "framework7-react";
import VoucherList from "./components/voucher-list";
import VoucherEmpty from "./components/voucher-empty";
import { API_URL, GET_TOKEN, FormatImage } from "../../../js/global";
import styles from "./styles.module.less";
import moment from "moment";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      item: [],
      merchant_id: 0,
      total: 0,
      current_page: 1,
      data_length: 0,
      total_pages: 0,
      limit: 10,
      has_more: false,
      isLoading: false,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      f7.preloader.show();
      if (!GET_TOKEN()) {
        this.$f7router.navigate("/onboarding/");
      }
      f7.request
        .promise({
          url:
            API_URL +
            "/v1/coupon/list?limit=5&page=" +
            this.state.current_page +
            "&",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then((res) => {
          const data = res.data.data;
          console.log("voucher", data);
          f7.preloader.hide();
          this.setState({
            ...this.state,
            data: data,
          });
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }
  onClickClaimVoucher(item) {
    f7.preloader.show();
    const coupon_id = item.id;
    f7.request
      .promise({
        url: API_URL + "/v1/coupon/redeem",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          coupon_id: coupon_id,
        },
      })
      .then((res) => {
        f7.preloader.hide();
        this.$f7.dialog.alert("Successful claim voucher");  
      })
      .catch((err) => {
        f7.preloader.hide();
        this.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }
  openModal(item) {
    this.setState({ isModalOpen: true, item: item });
    console.log("item", this.state.item.product_category.value_string);
  }
  closeModal() {
    this.setState({ isModalOpen: false });
  }
  render() {
    return (
      <Fragment>
        <Page name="category">
          <Navbar large backLink="back" title="Voucher List" />
          {this.state.data.map((item, index) => {
            return (
              <div className={styles["menu-item"]}>
                <div style={{ display: "flex", marginBottom: "14.5px" }}>
                  {/* <a
                style={{ display: "flex", flex: 1 }}
                href={"/foods/" + item.type + "/" + item.id + "/"}
              > */}
                  <div className={styles["icon-background"]}>
                    <img
                      className={styles["img-menu-product"]}
                      src={FormatImage(item.voucher_category.photo, "thumb")}
                    />
                  </div>
                  <div className={styles["container-detail"]}>
                    <div
                      className={styles["txt-menu-name"]}
                      onClick={() => this.openModal(item)}
                    >
                      {item.name}
                      {item.end_period == null ? (
                        <div className={styles["txt-menu-date"]} />
                      ) : (
                        <div className={styles["txt-menu-date"]}>
                          {moment(item.start_period).format("YYYY-MM-DD")} s/d{" "}
                          {moment(item.end_period).format("YYYY-MM-DD")}
                        </div>
                      )}
                      <div className={styles["txt-menu-detail"]}>
                        {item.product_category.value_string}
                      </div>
                    </div>
                    <Button
                      className={styles["topup-btn"]}
                      onClick={() => this.onClickClaimVoucher(item)}
                    >
                      Claim
                    </Button>
                  </div>

                  <div className={styles["container-ico"]}>
                    <div />
                  </div>
                </div>
                <div className="mk-line-break small"></div>
              </div>
            );
          })}
        </Page>

        <Modal
          isOpen={this.state.isModalOpen}
          onClose={() => this.closeModal()}
        >
          <div className={styles["modal-title"]}>
            <div style={{ width: "100%", paddingTop: "45px" }}>
              <div className={styles["desc-txt"]}>Voucher</div>
              {this.state.item.name}
              <hr className={styles["fade-line"]}></hr>
              <div className={styles["desc-txt"]}>Expired date</div>
              {moment(this.state.item.start_period).format(
                "YYYY-MM-DD"
              )} s/d {moment(this.state.item.end_period).format("YYYY-MM-DD")}
              <hr className={styles["fade-line"]}></hr>
              <div className={styles["detail-info"]}>
                <div className={styles["desc-txt"]}>Description</div>
                <div dangerouslySetInnerHTML={{__html: this.state.item.description}} />
                <hr className={styles["fade-line"]}></hr>
                <div className={styles["desc-txt"]}>
                  {/* {this.state.data.transaction_no} */}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

class Modal extends React.Component {
  render() {
    if (this.props.isOpen === false) return null;

    return (
      <div>
        <div className={styles["modal"]}>{this.props.children}</div>
        <div className={styles["bg"]} onClick={(e) => this.close(e)} />
      </div>
    );
  }

  close(e) {
    e.preventDefault();

    if (this.props.onClose) {
      this.props.onClose();
    }
  }
}
