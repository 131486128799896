import React from "react";
import { ImgBackground, IcoLogin } from "../../../assets/auth";
import { Button, List, Input, Page } from "framework7-react";
import styles from "./styles.module.less";
import {
  API_URL,
  SET_TOKEN,
  ChangePhoneAreaCode,
  GET_TEMP_USER_DATA,
  SET_READYTODRINK_STATUS,
} from "../../../js/global";

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      username: GET_TEMP_USER_DATA() ? GET_TEMP_USER_DATA().phone : "",
      password: GET_TEMP_USER_DATA() ? GET_TEMP_USER_DATA().password : "",
    };
  }

  componentDidMount() {}

  onClickLogin() {
    SET_READYTODRINK_STATUS(false);
    const _username = ChangePhoneAreaCode(this.state.username);
    const _password = this.state.password;
    const app = this;
    app.$f7router.app.preloader.show();
    app.$f7router.app.request.promise
      .postJSON(API_URL + "/v2/auth/login", {
        username: _username,
        password: _password,
      })
      .then(function (res) {
        SET_TOKEN(res.data.token);
        app.$f7router.app.preloader.hide();
        app.$f7router.navigate("/home/");
      })
      .catch(function (err) {
        app.$f7router.app.preloader.hide();
        app.$f7.dialog.alert(JSON.parse(err.xhr.responseText).message);
      });
  }

  render() {
    return (
      <Page name="login">
        <div className={styles["container"]}>
        <img className={styles["img-background"]} src={ImgBackground} />
        <a href="/register/" className={styles["btn-top-right"]}>
          Register
        </a>
        <Button
          back
          iconMd="material:arrow_back_ios"
          className={styles["btn-back"]}
        />
        <div style={{ flex: 1 }}>
          <div className={styles["oval"]}>
            <img src={IcoLogin} className={styles["ico"]} />
          </div>
          <div className={styles["txt-title"]}>Login</div>
          <div className={styles["input-form"]}>
            <List>
              <div className={styles["txt-label"]}>Phone</div>
              <Input
                type="tel"
                placeholder="0812xxxxxxxx"
                clearButton
                value={this.state.username}
                validate
                validateOnBlur
                required
                onChange={(val) =>
                  this.setState({ ...this.state, username: val.target.value })
                }
              ></Input>
              <div
                className={styles["txt-label"]}
                style={{ marginTop: "25px" }}
              >
                Password
              </div>
              <Input
                type="password"
                placeholder="Input your password"
                value={this.state.password}
                validate
                validateOnBlur
                required
                onChange={(val) =>
                  this.setState({ ...this.state, password: val.target.value })
                }
              ></Input>
              <a
                href="/forgot-password/"
                className={styles["btn-forgot-password"]}
              >
                Forgot password?
              </a>
            </List>
          </div>
        </div>
        <div className="mk-footer no-shadow">
          <Button
            onClick={() => this.onClickLogin()}
            fill
            className="mk-button"
            style={{
              margin: "0px 30px 0px 30px",
              alignSelf: "center",
              justifySelf: "center",
            }}
          >
            Login
          </Button>
        </div>
        </div>
      </Page>
    );
  }
}
