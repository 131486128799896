import React from "react";
import { IcoContact } from "../../../assets/profile";
import { ImgBackground, IcoLogin } from "../../../assets/auth";
import { Button, List, Input, Page, Icon } from "framework7-react";
import styles from "./styles.module.less";

export default class extends React.Component {
  onClickChatNow() {
    window.open(
      "https://api.whatsapp.com/send?phone=6287778988998&text=Halo Frodo saya ingin bertanya.",
      "_self"
    );
  }

  componentDidMount() {}

  render() {
    return (
      <Page name="contact">
        <div className={styles["container"]}>
          <img className={styles["img-background"]} src={ImgBackground} />
          <Button
            back
            iconMd="material:arrow_back_ios"
            className={styles["btn-back"]}
          />
          <div style={{ flex: 1 }}>
            <div className={styles["oval"]}>
              <Icon md="material:phone" size="50px" className={styles["ico"]} />
            </div>
            <div className={styles["txt-title"]}>Contact</div>
            <div className={styles["input-form"]}>
              <b>Address</b>
              <br />
              Jalan Pulomas Barat VE No 8 <br />
              Jakarta Timur, <br />
              DKI Jakarta, Indonesia <br />
            </div>

            <div className={styles["input-form"]}>
              <b>Customer Care Email</b>
              <br />
              info@frodo.id
              <br />
            </div>

            <div className={styles["input-form"]}>
              <b>Phone Number</b>
              <br />
              021 2247 4990
              <br />
            </div>

            <div className={styles["input-form"]}>
              <b>WhatsApp Number</b>
              <div
                onClick={() => this.onClickChatNow()}
                className={styles["float-right"]}
              >
                Chat Now
              </div>
              0877-7898-8998
              <br />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
