import React from "react";
import { Button, Page, Navbar, Searchbar } from "framework7-react";
import PromoList from "./components/promo-list";
import styles from "./styles.module.less";
import { API_URL } from "../../../js/global";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: this.props.s,
      sortByNearest: 0,
      sortByRating: 0,
      sortByPrice: 0,
    };
  }


  componentDidMount() {
    const sortBy = this.props.sort_by ? this.props.sort_by : "near_by";
    const s = this.props.s ? this.props.s : "";
    switch (sortBy) {
      case "near_by":
        this.setState({ ...this.state, sortByNearest: 1 });
        break;
      case "rate":
        this.setState({ ...this.state, sortByRating: 1 });
        break;
      case "cheapest":
        this.setState({ ...this.state, sortByPrice: 1 });
        break;
    }

    this.$f7ready((f7) => {
      f7.preloader.show();

      // Load Preorder API
      f7.request.promise
        .get(
          API_URL + "/v1/product?limit=4",
          null,
          "json"
        )
        .then((res) => {
          console.log("res", res.data.data);
          this.setState({ ...this.state, data: res.data.data });
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  onSubmitSearch() {
    this.$f7router.navigate("//" + "near_by/" + this.state.search + "/", {
      animate: false,
    });
  }

  render() {
    return (
      <Page pageContent name="search-page">
        <Navbar backLink backLinkForce backLinkUrl="/home/" />
        {
          this.state.data.length !== 0 && (
            <PromoList data={this.state.data} />
          )
        }
      </Page>
    );
  }
}
