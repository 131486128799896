import React, { useState, useEffect, useRef } from "react";
import Address from "./components/address";
import { ImgBackground } from "../../../assets/auth";
import { IcoProfile } from "../../../assets/profile";

import {
  Button,
  List,
  Input,
  Page,
  Icon,
  Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  f7,
} from "framework7-react";
import styles from "./styles.module.less";
import { API_URL, GET_TOKEN, GOOGLE_API_KEY } from "../../../js/global";
// import Map from "./components/maps";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      receiver_name: "",
      receiver_phone: "",
      address: "",
      props_address: "",
      props_lat: "",
      props_lng: "",
      zip_code: "",
      country: "",
      province: "",
      city: "",
      subdistrict: "",
      latitude: "",
      longitude: "",
      data: {
        addresses: {
          details: "",
          country: "",
          province: "",
          city: "",
          subdistrict: "",
          latitude: "",
          longitude: "",
        },
      },
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      //Load Products API
      f7.request
        .promise({
          url: API_URL + "/v2/profile/address?limit=1",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            Authorization: GET_TOKEN(),
          },
        })
        .then(async (res) => {
          let item = res.data.data;


          this.setState({
            ...this.state,
            data: item,
          });
          if (this.props.address == "address:") {
            this.setState({
              ...this.state,
              props_address: "",
              props_lat: "",
              props_lng: "",
            });
          }else{
            this.setState({
              ...this.state,
              props_address: this.props.address,
              props_lat: this.props.lat,
              props_lng: this.props.lng,
            });
          }
          f7.preloader.hide();
        })
        .catch((err) => {
          console.log("err", err);
          f7.preloader.hide();
        });
    });
  }

  onClickAddNew() {
    const data = this.state;
    const app = this;
    this.onUpdateAddress();
    this.setState({
      ...this.state,
      data: {
        ...this.state,
        receiver_name: this.state.receiver_name,
        receiver_phone: this.state.receiver_phone,
      },
    });
    app.$f7router.app.preloader.show();
    app.$request
      .promise({
        url: API_URL + "/v2/profile/address",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          Authorization: GET_TOKEN(),
        },
        data: {
          address: this.state.address,
          latitude: this.state.data.latitude,
          longitude: this.state.data.longitude,
          receiver_name: this.state.receiver_name,
          receiver_phone: this.state.receiver_phone,
        },
      })
      .then((res) => {
        app.$f7router.app.preloader.hide();
        app.$f7.dialog.alert("Succes!", () =>
          app.$f7router.navigate("/address/")
        );
      })
      .catch((err) => {
        console.log("err", err);
        app.$f7router.app.preloader.hide();
      });
  }

  onUpdateAddress() {
    if (this.state.status == true) {
      this.setState({
        ...this.state,
        latitude: this.state.data.addresses.latitude,
        longitude: this.state.data.addresses.longitude,
        country: this.state.data.addresses.country,
        city: this.state.data.addresses.city,
        province: this.state.data.addresses.province,
        subdistrict: this.state.data.addresses.subdistrict,
      });
    }else{
      this.setState({
        ...this.state,
        address: this.props.address,
        latitude: this.props.lat,
        longitude: this.props.lng,
        country: null,
        city: null,
        province: null,
        subdistrict: null,
      });
    }

   
  }

  onUpdatesAddress(value) {
    this.setState({
      ...this.state,
      address: value,
    });
  }

  onSaveAddress() {
    this.setState({
      ...this.state,
      address: this.state.data.addresses.details,
    });
  }
  onclickmaps() {
    this.$f7ready((f7) => {
      this.$f7router.navigate("/maps/");
    });
  }
  onclickBack() {
    this.$f7ready((f7) => {
      this.$f7router.navigate("/Address/");
    });
  }
  onChangeAddress(val) {
    this.setState({
      ...this.state,
      status:true,
      data: {
        ...this.state.data,
        addresses: { ...val },
      },
    });

    this.onSaveAddress();
  }
  render() {
    return (
      <Page name="login">
        <div className={styles["container"]}>
        <img className={styles["img-background"]} src={ImgBackground} />
        <Button
          onClick={() => this.onclickBack()}
          iconMd="material:arrow_back_ios"
          className={styles["btn-back"]}
        />
        <div style={{ flex: 1 }}>
          <div className={styles["oval"]}>
            <Icon size="70px" md="material:people" className={styles["ico"]} />
          </div>

          <div className={styles["title"]}>New Address</div>

          <div className={styles["input-form"]}>
            <div className={styles["address"]}>
              <Address
                onChangeAddress={(val) => this.onChangeAddress(val)}
                data={this.state ? this.state : []}
                props={this.props}
              />
              <div
                className={styles["ico-div"]}
                onClick={() => this.onclickmaps()}
              >
                <Icon md="material:location_on" color="black" />
              </div>
            </div>
            <List>
              <div className={styles["txt-label"]}>Address</div>
              <Input
                type="text"
                placeholder="Input your Address"
                defaultValue={this.state.status == true?this.state.address:this.state.props_address}
                onChange={(value) => this.onUpdatesAddress(value.target.value)}
              ></Input>
              <div
                className={styles["txt-label"]}
                style={{ marginTop: "25px" }}
              >
                Name
              </div>
              <Input
                type="text"
                placeholder="Input your name"
                value={this.state.receiver_name}
                required
                validate
                validateOnBlur
                onChange={(val) =>
                  this.setState({
                    ...this.state,
                    receiver_name: val.target.value,
                  })
                }
              ></Input>
              <div
                className={styles["txt-label"]}
                style={{ marginTop: "25px" }}
              >
                Phone
              </div>
              <Input
                type="text"
                placeholder="Input your phone number"
                value={this.state.receiver_phone}
                required
                validate
                validateOnBlur
                onChange={(val) =>
                  this.setState({
                    ...this.state,
                    receiver_phone: val.target.value,
                  })
                }
              ></Input>
            </List>
            {/* <div style={{ marginTop: "25px" }}>
              <Map
     google={this.props.google}
     center={{lat: 18.5204, lng: 73.8567}}
     height='300px'
     zoom={15}
    />
    </div> */}
          </div>
        </div>
        <div className={styles["mk-footer"]}>
          <Button
            className={styles["btn-next"]}
            onClick={() => this.onClickAddNew()}
          >
            Add Address
          </Button>
        </div>
        </div>
      </Page>
    );
  }
}
