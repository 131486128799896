import React, { useState } from "react";
import styles from "./styles.module.less";
import { Row, Col } from "framework7-react";
import { IcoLove } from "../../../../../assets/home";
import { IncreaseDecrease } from "../../../../../components/customs";
import { IcoStarEmpty, IcoStarFill } from "../../../../../assets/transaction";
// import Rating from "react-rating";
import {FormatImage } from "../../../../../js/global";

export default (props) => {
  let data = [];
  if (props.data) {
    data = props.data;
  }

  return (
    <div>
      <div className={styles["title"]}>Order Summary</div>
      <div style={{ margin: "0px 20px 0px 20px" }}>
        {data.map((item, index) => {
          return (
            <div style={{ marginTop: "20px" }}>
              <div style={{ display: "flex" }}>
                <img
                  src={FormatImage(item.photo,"thumb")}
                  style={{
                    width: "80px",
                    height: "80px",
                    backgroundColor: "gray",
                    borderRadius: "5px",
                    flexShrink: 0,
                    objectFit: "cover",
                  }}
                ></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    flex: 1,
                  }}
                >
                  <div className={styles["txt-food-1"]}>{"("+item.quantity + " x)" + " "+item.name}</div>

                  <div className={styles["txt-menu-detail"]}>
                  {item.description.notes}
                  <div className={styles["txt-menu-detail"]}>
                  {item.description.serve_as}</div>
                </div>
                
                  <div
                    style={{
                        display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div style={{ flex: 1 }} className={styles["txt-food-2"]}>
                      Rp {Intl.NumberFormat().format(item.amount)}
                    </div>
                    {/* {item.is_rated ? (
                      <Rating
                        emptySymbol={<img src={IcoStarEmpty}></img>}
                        fullSymbol={<img src={IcoStarFill}></img>}
                        initialRating={item.rating}
                        readonly
                      />
                    ) : null} */}
                  </div>
                </div>
              </div>
              <div className="mk-line-break" style={{ marginTop: "20px" }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
